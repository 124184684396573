<template>
  <PageHeader
    title="Roles & Permissions"
    breadcrumb
    :items="[
      { label: $t('ui.entities.report.label', 2), to: '/reports' },
      { label: 'Roles & Permissions', to: '/reports/users/roles-permissions' }
    ]"
  >
  </PageHeader>
  <Card>
    <DataTable
      ref="dt"
      v-model:filters="filters"
      export-filename="Roles & Permissions"
      :value="rolesPermissionsData"
      removable-sort
      sort-field="permission"
      :sort-order="-1"
      :rows="20"
      data-key="id"
      column-resize-mode="fit"
      :paginator="true"
      responsive-layout="scroll"
      filter-display="menu"
      :global-filter-fields="['permission']"
      :rows-per-page-options="rowsPerPageOptions"
      state-key="dt-report-roles-permissions"
      state-storage="session"
      current-page-report-template="Showing {startRecord}-{endRecord} out of {totalRecords}"
      :loading="rolesPermissionsRequest.isLoading.value"
    >
      <template #empty> No records found </template>
      <template #header>
        <div class="flex justify-between">
          <span class="flex justify-between gap-4">
            <span>
              <Input v-model="filters['global'].value" :placeholder="$t('ui.common.search', 1)" icon="search" />
            </span>
            <Button
              :label="$t('ui.actions.clear', 1)"
              severity="secondary"
              type="button"
              outlined
              icon="filter-slash"
              @click="clearFilters()"
            />
          </span>
        </div>
        <div class="flex justify-between mt-4">
          <span>
            <Button label="CSV" type="button" severity="secondary" outlined icon="file-excel" @click="exportCSV($event)" />
          </span>
        </div>
      </template>

      <Column field="permission">
        <template #body="{ data }">
          {{ data.permission }}
        </template>
      </Column>

      <Column v-for="role in roles" :field="role.name" :header="role.name">
        <template #body="{ data }">
          <Icon :type="data[role.name] == true ? 'circle-check' : 'circle-xmark'" :severity="data[role.name] ? 'success' : 'error'" />
        </template>
      </Column>
    </DataTable>
  </Card>
</template>

<script setup>
import { ref, computed } from 'vue'

import Card from '@/components/card/Card.vue'
import PageHeader from '@/components/page/PageHeader.vue'
import Icon from '@/components/icon/Icon.vue'
import Input from '@/components/forms/Input.vue'
import Button from '@/components/button/Button.vue'

import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import { FilterMatchMode } from 'primevue/api'

import useApiRequest from '@/composables/useApiRequest'

const dt = ref()

const rolesPermissionsRequest = useApiRequest()
const roles = ref(null)
const rolesPermissionsData = ref(null)

rolesPermissionsRequest.send({ method: 'GET', endpoint: '/v1/casl' }).then(response => {
  if (response.success) {
    roles.value = response.data.roles

    rolesPermissionsData.value = response.data.permissions.map(permission => {
      return {
        permission: permission.name,
        ...Object.fromEntries(roles.value.map(role => [role.name, role.permissions.includes(permission.id)]))
      }
    })
  }
})

const rowsPerPageOptions = computed(() => {
  return rolesPermissionsData.value?.length ? [10, 20, 50, rolesPermissionsData.value?.length] : [10, 20, 50]
})

const filters = ref([])

function initFilters() {
  filters.value = {
    global: { value: null, matchMode: FilterMatchMode.CONTAINS }
  }
}
initFilters()

const exportCSV = () => {
  dt.value.exportCSV()
}
</script>

<style></style>
