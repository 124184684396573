// update-ability.js
import axios from 'axios'
import ability from '@/config/ability.js'
import socket from '@/services/socket.service.js'
import { alertToast } from '@/utilities/notification'
import { useUserStore } from '@/stores/UserStore'

export default async function updateAbility(completedRoute, routerInstance) {
  const userStore = useUserStore()

  const getPermissions = async () => {
    try {
      const response = await axios.get(`${import.meta.env.VITE_API_BASE_URL}/v1/users/${userStore.details.id}/permissions`, {
        withCredentials: true,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      })

      if (response.data.response.status !== 'success') {
        throw new Error('updateAbility() - Error fetching data')
      }

      const roles = response.data.data.roles
      const abilities = response.data.data.permissions
      const accessAllowed = response.data.data.access
      const callCenters = response.data.data.callCenters

      ability.update(abilities)

      if (accessAllowed === undefined || accessAllowed.regions.length === 0) {
        routerInstance.push({ path: '/no-access', replace: true })
        return Promise.reject('No access allowed')
      } else {
        userStore.updateRoles(roles)
        userStore.updateAccessAllowed(accessAllowed)

        let accessSelected = JSON.parse(localStorage.getItem('accessSelected')) ?? {
          regions: [],
          manufacturers: [],
          groups: [],
          dealerships: []
        }

        if (!localStorage.getItem('accessSelected')) {
          if (accessAllowed.regions.length === 1) {
            accessAllowed.regions[0].isSelected = true
            accessSelected.regions.push(accessAllowed.regions[0].id)
          }
          if (accessAllowed.manufacturers.length === 1) {
            accessAllowed.manufacturers[0].isSelected = true
            accessSelected.manufacturers.push(accessAllowed.manufacturers[0].id)
          }
          if (accessAllowed.groups.length === 1) {
            accessAllowed.groups[0].isSelected = true
            accessSelected.groups.push(accessAllowed.groups[0].id)
          }
          if (accessAllowed.dealerships.length === 1) {
            accessAllowed.dealerships[0].isSelected = true
            accessSelected.dealerships.push(accessAllowed.dealerships[0].id)
          }
        }

        accessSelected.regions = accessSelected.regions.filter(region => accessAllowed.regions.some(r => r.id === region))
        accessSelected.manufacturers = accessSelected.manufacturers.filter(manufacturer =>
          accessAllowed.manufacturers.some(m => m.id === manufacturer)
        )
        accessSelected.groups = accessSelected.groups.filter(group => accessAllowed.groups.some(g => g.id === group))
        accessSelected.dealerships = accessSelected.dealerships.filter(dealership =>
          accessAllowed.dealerships.some(d => d.id === dealership)
        )

        localStorage.setItem('accessSelected', JSON.stringify(accessSelected))

        if (accessSelected.dealerships.length === 0 && userStore.details.type !== 1) {
          routerInstance.push({ path: '/access', replace: true })
        } else {
          if (completedRoute) routerInstance.push({ path: completedRoute, replace: true })
        }
      }

      let dealershipIds = accessAllowed.dealerships.map(dealership => dealership.id)

      // Update user's call center permissions
      userStore.updateCallCenters(callCenters)

      socket.emit('user-update', {
        uid: userStore.details.id,
        ut: userStore.details.type,
        dealershipIds,
        canMakeCalls: ability.can('make_calls'),
        canReceiveCalls: ability.can('receive_calls')
      })

      return Promise.resolve()
    } catch (err) {
      console.error('ERROR:', err)
      alertToast('Permissions error', err.message, 'error')
      return Promise.reject(err)
    }
  }

  return getPermissions()
}
