<!-- eslint-disable vue/no-v-html -->
<template>
  <teleport to="#modals-container">
    <div v-if="isOpen" to="#modals-container" class="search-background" @mousedown.self="closeSearch">
      <div class="search-wrapper">
        <div class="search-card search-card--main">
          <div class="flex items-center p-4">
            <Icon type="search" class="mr-2" />
            <input
              ref="searchInput"
              v-model="searchQuery"
              placeholder="Search for customers, users and dealerships"
              class="w-full bg-transparent"
            />
          </div>
          <div>
            <Tabs v-model="currentTab" :tabs="tabs" type="pills-rail" size="xs" class="mx-4 mb-4" />
          </div>
          <div v-if="hasAnyResults" class="flex">
            <div v-if="searchResultsGrouped" ref="searchResultsContainer" class="search-results">
              <div v-for="(category, categoryIndex) in displayedResults" :key="category.indexUid">
                <div class="search-result-category-title gap">
                  <div>{{ tabs.find(tab => tab.id === category.indexUid)?.label }}</div>
                  <div class="text-quaternary text-xs mt-1">({{ searchResultsCount(category.hits.length) }})</div>
                </div>
                <div
                  v-if="category.hits.length > 0"
                  v-for="(hit, itemIndex) in category.hits"
                  :key="itemIndex"
                  class="search-result-item"
                  :class="{ 'focused-result': focusedResult.categoryIndex === categoryIndex && focusedResult.itemIndex === itemIndex }"
                  @mouseenter="onResultHover(categoryIndex, itemIndex, category.indexUid)"
                  @click="onResultClick(categoryIndex, itemIndex, category.indexUid)"
                >
                  <div v-if="hit.type === 'agreements'" class="flex justify-between w-full items-center">
                    <div class="flex flex-col w-full">
                      <div class="flex gap-1">
                        <div class="font-medium" v-html="hit._formatted.name"></div>
                        <Chip v-if="$dayjs(hit.endDate).isBefore($dayjs(), 'day')" label="Ended" severity="danger" size="xs" />
                      </div>
                      <div class="text-sm flex flex-col text-quaternary whitespace-nowrap">
                        <div v-if="hit.mobile && hit.mobile != '' && hit.mobile != 0" class="mr-3">
                          <Icon type="mobile" /> <span v-html="hit._formatted.mobile"></span>
                        </div>
                        <div v-if="hit.email && hit.email != ''" class="mr-3">
                          <Icon type="at" /> <span v-html="hit._formatted.email"></span>
                        </div>
                        <div v-if="hit.addressPostcode && hit.addressPostcode != ''">
                          <Icon type="location-dot" /> <span v-html="hit._formatted.addressPostcode"></span>
                        </div>
                      </div>
                    </div>
                    <div class="w-full">
                      <div class="flex flex-col items-end text-sm gap-2">
                        <div class="flex items-center">
                          <img :src="hit.vehicleManufacturerLogoUrl" class="h-5 mr-2" />
                          <span class="text-tertiary" v-html="hit.vehicleModel"></span>
                        </div>
                        <div class="search-number-plate font-number-plate-uk" v-html="hit.vrm"></div>
                      </div>
                    </div>
                  </div>
                  <div v-if="hit.type === 'users'">
                    <AvatarLabelGroup
                      :avatar-url="hit.profilePicUrl"
                      :avatar-text="hit.forename.charAt(0) + hit.surname.charAt(0)"
                      :label="hit.forename + ' ' + hit.surname"
                      :description="hit.email"
                      size="sm"
                    />
                  </div>
                  <div v-if="hit.type === 'dealerships'">
                    <AvatarLabelGroup
                      :avatar-url="hit.manufacturerLogoUrl"
                      avatar-is-logo
                      avatar-icon="building"
                      :label="hit.name"
                      :description="hit.email"
                      size="sm"
                    />
                  </div>
                </div>
                <div class="p-4">
                  <div v-if="searchLimits[category.indexUid] <= 6" class="link" @click="showMoreResults(category)">
                    <Icon type="chevron-down" />
                    Show more
                  </div>
                </div>
              </div>
            </div>
            <div class="search-preview-pane">
              <div v-if="previewPaneItem?.type === 'agreements'">
                <div class="search-preview-pane__body">
                  <div class="flex justify-between mb-4">
                    <div>
                      <div class="text-xl">{{ previewPaneItem.name }}</div>
                    </div>
                    <Chip :label="previewPaneItem.dealershipName" icon="map-marker" :rounded="false" size="sm" />
                  </div>
                  <AvatarLabelGroup
                    :avatar-url="previewPaneItem.vehicleManufacturerLogoUrl"
                    avatar-is-logo
                    :label="previewPaneItem.vehicleModel"
                    :description="previewPaneItem.vehicleDerivative"
                  />
                  <img :src="previewPaneItem.vehicleImageUrl" />
                  <TermTracker :start-date="previewPaneItem.startDate" :end-date="previewPaneItem.endDate" />
                </div>
              </div>
              <div v-if="previewPaneItem?.type === 'users'">
                <div class="height-[180px] relative">
                  <div class="banner-background h-[70px]"></div>
                </div>
                <div class="search-preview-pane__body">
                  <div class="flex justify-between">
                    <Avatar
                      :url="previewPaneItem.profilePicUrl"
                      :text="previewPaneItem.forename.charAt(0) + previewPaneItem.surname.charAt(0)"
                      class="-mt-[45px] !border-4 shadow-lg"
                      style="border-color: var(--fg-white) !important"
                      size="2xl"
                    />
                    <div class="flex gap-2">
                      <Button
                        v-if="$can('manage_users')"
                        icon="gear"
                        outlined
                        severity="secondary"
                        size="sm"
                        :router-link="'/settings/users/' + previewPaneItem.userId"
                        @click="closeSearch"
                      />
                    </div>
                  </div>
                  <div class="mt-4">
                    <div class="text-xl font-medium">{{ previewPaneItem.forename + ' ' + previewPaneItem.surname }}</div>
                    <div class="text-quaternary text-sm">{{ previewPaneItem.jobTitle }}</div>
                  </div>
                </div>
              </div>
              <div v-if="previewPaneItem?.type === 'dealerships'">
                <div class="height-[180px] relative">
                  <div class="banner-background h-[70px]"></div>
                </div>
                <div class="search-preview-pane__body">
                  <div class="flex justify-between">
                    <Avatar
                      :url="previewPaneItem.manufacturerLogoUrl"
                      :text="previewPaneItem.name"
                      is-logo
                      class="-mt-[45px] !border-4 shadow-lg"
                      style="border-color: var(--fg-white) !important"
                      size="2xl"
                    />
                    <div class="flex gap-2">
                      <Button
                        v-if="$can('manage_dealerships')"
                        icon="gear"
                        outlined
                        severity="secondary"
                        size="sm"
                        :router-link="'/settings/dealerships/' + previewPaneItem.dealershipId"
                        @click="closeSearch"
                      />
                    </div>
                  </div>
                  <div class="mt-4">
                    <div class="text-xl font-medium">{{ previewPaneItem.name }}</div>
                    <div class="flex gap-1 mt-1">
                      <Chip :label="getCountryByCode(previewPaneItem.countryCode)?.name" size="sm">
                        <img :src="getCountryByCode(previewPaneItem.countryCode)?.image" width="16" class="mr-1" />
                      </Chip>
                      <Chip :label="formatTimezone(previewPaneItem.timeZone)" icon="globe" size="sm" />
                    </div>
                  </div>
                  <div class="flex flex-col gap-1 mt-4 text-sm">
                    <div class="flex gap-2">
                      <Icon type="map-marker" />
                      <div>{{ previewPaneItem.address || '-' }}</div>
                    </div>
                    <div class="flex gap-2">
                      <Icon type="globe-pointer" />
                      <div>{{ previewPaneItem.website || '-' }}</div>
                    </div>
                    <div class="flex gap-2">
                      <Icon type="phone" />
                      <div>{{ previewPaneItem.telephone || '-' }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-else class="flex items-center justify-center py-5">
            <Empty
              v-if="!$can('view_customers') && !$can('view_users') && !$can('view_dealerships')"
              title="Your role doesn’t have the right permissions"
              description="If this is unexpected, contact us to discuss your permissions"
              icon="shield-halved"
            />
            <Empty v-else-if="searchQuery" title="No results found" />
            <Empty v-else title="Start typing to search" icon="search" />
          </div>
        </div>
        <div class="search-card mt-5 !p-3 text-sm flex items-center gap-4">
          <div class="flex items-center">
            <div class="border border-primary rounded-lg mr-1 p-1">
              <Icon type="arrow-up" fa-style="fas" />
            </div>
            <div class="border border-primary rounded-lg mr-1 p-1">
              <Icon type="arrow-down" fa-style="fas" />
            </div>
            <div class="ml-1 font-medium text-quaternary">to navigate</div>
          </div>
          <div class="flex items-center">
            <div class="border border-primary rounded-lg mr-1 p-1">
              <Icon type="turn-down-left" fa-style="fas" />
            </div>
            <div class="ml-1 font-medium text-quaternary">to select</div>
          </div>
          <div class="flex items-center">
            <div class="border border-primary rounded-lg mr-1 p-1 px-2">esc</div>
            <div class="ml-1 font-medium text-quaternary">to close</div>
          </div>
        </div>
      </div>
    </div>
  </teleport>
</template>

<script setup>
import { ref, onMounted, watch, nextTick, computed } from 'vue'
import { useRouter } from 'vue-router'
import { MeiliSearch } from 'meilisearch'
import { onKeyStroke } from '@vueuse/core'
import { useUserStore } from '@/stores/UserStore'
import { getCountryByCode } from '@/utilities/countries.js'
import { formatTimezone } from '@/utilities/timezoneFormatter.js'
import { useAbility } from '@casl/vue'

import Button from '@/components/button/Button.vue'
import Icon from '@/components/icon/Icon.vue'
import Tabs from '@/components/tabs/Tabs.vue'
import Avatar from '@/components/avatar/Avatar.vue'
import AvatarLabelGroup from '@/components/avatar/AvatarLabelGroup.vue'
import TermTracker from '@/components/unique/trackers/TermTracker.vue'
import Chip from '@/components/chip/Chip.vue'
import Empty from '../empty/Empty.vue'

const props = defineProps({
  isOpen: {
    type: Boolean,
    default: false
  }
})

const { can } = useAbility()
const emit = defineEmits(['closeSearch'])
const router = useRouter()
const userStore = useUserStore()
const currentTab = ref('all')
const tabs = ref([
  { id: 'all', label: 'All', isHidden: !can('view_customers') && !can('view_users') && !can('view_dealerships') },
  { id: 'agreements', label: 'Customers', isHidden: !can('view_customers') },
  { id: 'users', label: 'Users', isHidden: !can('view_users') },
  { id: 'dealerships', label: 'Dealerships', isHidden: !can('view_dealerships') }
])
const searchInput = ref(null)
const searchQuery = ref('')
const searchResults = ref([])
const client = ref()
const focusedResult = ref({ categoryIndex: 0, itemIndex: 0, type: '' })
const searchResultsContainer = ref(null)
const dealershipIdsString = ref(null)

onMounted(() => {
  client.value = new MeiliSearch({
    host: import.meta.env.VITE_MEILISEARCH_URL,
    apiKey: import.meta.env.VITE_MEILISEARCH_API_KEY
  })
  setInitialFocus()
})

const searchLimitsDefault = {
  agreements: 6,
  users: 6,
  dealerships: 6
}

const searchLimits = ref({ ...searchLimitsDefault })

const search = async query => {
  if (query) {
    let dealershipIds = userStore.accessAllowed.dealerships.map(dealership => dealership.id)
    dealershipIdsString.value = dealershipIds.join(',')

    // Create custom user filtering string - which lists all users that share access to the user's dealerships
    let usersFilterString = `dealerships IN [${dealershipIdsString.value}]`
    if (userStore.details.type == 2) {
      usersFilterString = usersFilterString + ` AND userType = 2`
    }

    const queries = [
      {
        indexUid: 'agreements',
        limit: searchLimits.value.agreements,
        q: query,
        matchingStrategy: 'all',
        filter: `dealershipId IN [${dealershipIdsString.value}]`,
        attributesToHighlight: ['*'],
        highlightPreTag: '<span class="search-results-highlight">',
        highlightPostTag: '</span>'
      },
      {
        indexUid: 'users',
        limit: searchLimits.value.users,
        q: query,
        matchingStrategy: 'all',
        filter: usersFilterString,
        attributesToHighlight: ['*'],
        highlightPreTag: '<span class="search-results-highlight">',
        highlightPostTag: '</span>'
      },
      {
        indexUid: 'dealerships',
        limit: searchLimits.value.dealerships,
        q: query,
        matchingStrategy: 'all',
        filter: `dealershipId IN [${dealershipIdsString.value}]`,
        attributesToHighlight: ['*'],
        highlightPreTag: '<span class="search-results-highlight">',
        highlightPostTag: '</span>'
      }
    ]
    try {
      const results = await client.value.multiSearch({ queries })
      let typeUrls = {
        agreements: {
          baseUrl: '/agreement/',
          key: 'agreementId'
        },
        users: {
          baseUrl: null,
          key: null
        },
        dealerships: {
          baseUrl: null,
          key: null
        }
      }
      searchResults.value = results.results.map(category => ({
        ...category,
        hits: category.hits.map(hit => ({
          ...hit,
          type: category.indexUid,
          url: typeUrls[category.indexUid].baseUrl ? typeUrls[category.indexUid].baseUrl + hit[typeUrls[category.indexUid].key] : null
        }))
      }))
      setInitialFocus()
    } catch (error) {
      console.error('Error performing multi-search:', error)
    }
  } else {
    searchResults.value = []
    focusedResult.value = { categoryIndex: 0, itemIndex: 0, type: '' }
  }
}

function showMoreResults(category) {
  searchLimits.value[category.indexUid] = 40
  search(searchQuery.value)
}

const setInitialFocus = () => {
  if (searchResults.value.length > 0) {
    for (let i = 0; i < searchResults.value.length; i++) {
      if (searchResults.value[i].hits.length > 0) {
        focusedResult.value = {
          categoryIndex: i,
          itemIndex: 0,
          type: searchResults.value[i].indexUid
        }
        return
      }
    }
  }
  focusedResult.value = { categoryIndex: 0, itemIndex: 0, type: '' }
}

watch(
  () => searchResults.value,
  () => {
    setInitialFocus()
  }
)

function closeSearch() {
  searchLimits.value = { ...searchLimitsDefault }
  searchQuery.value = ''
  focusedResult.value = { categoryIndex: 0, itemIndex: 0 }
  emit('closeSearch')
}

watch(() => searchQuery.value, search)

watch(
  () => props.isOpen,
  newValue => {
    if (newValue) {
      nextTick(() => {
        searchInput.value.focus()
      })
    }
  }
)

watch(
  focusedResult,
  () => {
    if (searchResultsContainer.value) {
      nextTick(() => {
        if (!props.isOpen || !searchResultsContainer.value) return
        const focusedElement = searchResultsContainer.value?.querySelector('.focused-result')
        if (focusedElement) {
          focusedElement.scrollIntoView({ block: 'nearest', behavior: 'smooth' })
        }
      })
    }
  },
  { deep: true }
)

watch(currentTab, () => {
  focusedResult.value = { categoryIndex: 0, itemIndex: 0, type: '' }
  setInitialFocus()
})

onKeyStroke('ArrowDown', e => {
  if (!props.isOpen || !displayedResults.value.length) return
  e.preventDefault()
  const { categoryIndex, itemIndex } = focusedResult.value
  if (itemIndex < displayedResults.value[categoryIndex].hits.length - 1) {
    focusedResult.value.itemIndex++
  } else {
    for (let i = categoryIndex + 1; i < displayedResults.value.length; i++) {
      if (displayedResults.value[i].hits.length > 0) {
        focusedResult.value = {
          categoryIndex: i,
          itemIndex: 0,
          type: displayedResults.value[i].indexUid
        }
        return
      }
    }
  }
})

onKeyStroke('ArrowUp', e => {
  if (!props.isOpen || !displayedResults.value.length) return
  e.preventDefault()
  const { categoryIndex, itemIndex } = focusedResult.value
  if (itemIndex > 0) {
    focusedResult.value.itemIndex--
  } else {
    for (let i = categoryIndex - 1; i >= 0; i--) {
      if (displayedResults.value[i].hits.length > 0) {
        focusedResult.value = {
          categoryIndex: i,
          itemIndex: displayedResults.value[i].hits.length - 1,
          type: displayedResults.value[i].indexUid
        }
        return
      }
    }
  }
})

onKeyStroke('Enter', e => {
  // TODO: address this for accessibility - we should be using the standart HTML focus logic
  // e.preventDefault()
  if (document.activeElement.classList.contains('tab')) return
  onResultClick(focusedResult.value.categoryIndex, focusedResult.value.itemIndex)
})

onKeyStroke('Escape', e => {
  if (!props.isOpen) return
  e.preventDefault()
  closeSearch()
})

function onResultHover(categoryIndex, itemIndex, type) {
  const category = displayedResults.value[categoryIndex]
  if (!category) return
  focusedResult.value = { categoryIndex, itemIndex, type }
}

function onResultClick(categoryIndex, itemIndex) {
  const category = displayedResults.value[categoryIndex]
  if (!category) return
  const item = category.hits[itemIndex]
  if (item?.type === 'users' || item?.type === 'dealerships') return
  if (item?.url) router.push(item.url)
  closeSearch()
}

const previewPaneItem = computed(() => {
  if (!displayedResults.value.length) return null
  const { categoryIndex, itemIndex } = focusedResult.value
  const currentCategory = displayedResults.value[categoryIndex]
  if (!currentCategory || !currentCategory.hits[itemIndex]) return null
  return currentCategory.hits[itemIndex]
})

const searchResultsGrouped = computed(() => {
  if (!searchResults.value.length) {
    return { results: [] }
  }
  const hits = searchResults.value.map(category => ({
    indexUid: category.indexUid,
    hits: category.hits.map(hit => ({
      indexUid: category.indexUid,
      type: category.indexUid,
      ...hit
    }))
  }))
  return { results: hits }
})

const displayedResults = computed(() => {
  if (!searchResultsGrouped.value.results.length) return []
  let searchResultsFiltered = searchResultsGrouped.value.results
  if (!can('view_dealerships')) {
    searchResultsFiltered = searchResultsFiltered.filter(category => category.indexUid != 'dealerships')
  }
  if (!can('view_users')) {
    searchResultsFiltered = searchResultsFiltered.filter(category => category.indexUid != 'users')
  }
  if (!can('view_customers')) {
    searchResultsFiltered = searchResultsFiltered.filter(category => category.indexUid != 'agreements')
  }
  if (currentTab.value === 'all') return searchResultsFiltered
  return searchResultsFiltered.filter(category => category.indexUid === currentTab.value)
})

const hasAnyResults = computed(() => {
  // check if any of the categories have any results
  return searchResultsGrouped.value.results.some(category => category.hits.length > 0)
})

const searchResultsCount = count => (count > 5 ? '5+' : count)
</script>

<style>
.search-background {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(4px);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
.search-wrapper {
  display: flex;
  flex-direction: column;
  max-width: 900px;
  width: 100%;
  margin: var(--s-10) auto;
  box-sizing: border-box;
  max-height: calc(100% - var(--s-20));
  /* background-color: #f8acff; */
}
.search-card.search-card--main {
  flex: 1;
  display: flex;
  flex-direction: column;
  /* overflow: hidden; */
}
.search-card {
  background-color: var(--bg-primary_alt);
  border-radius: var(--rounded-xl);
}
.search-results {
  width: 55%;
  max-height: 72vh;
  overflow-y: auto;
  overflow-x: hidden;
  padding: var(--s-4) 0;
  border-top: 1px solid var(--border-secondary);
}
.search-preview-pane {
  flex: 1;
  max-height: 80vh;
  overflow-y: auto;
  border-top: 1px solid var(--border-secondary);
  border-left: 1px solid var(--border-secondary);
}
.search-preview-pane__body {
  padding: var(--s-6);
}
.search-card.mt-5 {
  margin-top: var(--s-5);
}
.search-result-category-title {
  color: var(--text-secondary);
  padding: var(--s-2) var(--s-4) 0 var(--s-4);
  display: flex;
  gap: var(--s-1);
  align-items: center;
}
.search-result-item {
  border-radius: var(--rounded-md);
  padding: var(--s-3) var(--s-3);
  margin: var(--s-05) var(--s-2);
  cursor: pointer;
}
.search-results-highlight {
  color: var(--fg-brand);
  font-weight: 500;
  font-family: inherit;
}
.focused-result {
  background-color: var(--bg-secondary_alt);
}
.banner-background {
  background: linear-gradient(135deg, #f8acff 0%, #aab2ff 100%);
}
.search-number-plate {
  background-color: var(--bg-tertiary);
  border-left: var(--s-2) solid var(--border-secondary);
  padding: 0 var(--s-2);
  white-space: nowrap;
  border-radius: 0.2rem;
  font-size: var(--text-lg);
  color: black;
  font-weight: 500;
}
.search-item-agreement {
  display: flex;
  align-items: center;
  border-radius: var(--rounded-md);
  padding: var(--s-1) var(--s-2);
}
.focused-result .search-number-plate {
  border-color: #406290;
  background-color: #fff700d3;
}
</style>
