<template>
  <PageHeader title="Missed Conversations" breadcrumb :items="[{ label: 'Conversations' }, { label: 'Missed' }]"
    ><template #right>
      <div class="flex items-center gap-3">
        <Select
          v-model="callCenterSelected"
          :options="callCentersAllowed"
          value-key="id"
          label-key="name"
          :placeholder="`Select a ${$t('ui.entities.departments.call-centre')}`"
          class="max-w-[350px]"
          icon="headset"
          :readonly="callCentersAllowed.length == 1"
          @select="updateCallCenterSelected"
        >
        </Select>
      </div>
    </template>
  </PageHeader>
  <Card>
    <DataTable
      ref="dt"
      v-model:filters="filters"
      :value="conversations"
      removable-sort
      sort-field="createdAt"
      :sort-order="-1"
      :rows="20"
      data-key="id"
      column-resize-mode="fit"
      :paginator="true"
      responsive-layout="scroll"
      filter-display="menu"
      :rows-per-page-options="[10, 20, 50]"
      state-key="dt-conversations-missed"
      state-storage="session"
      :loading="isLoading"
      current-page-report-template="Showing {startRecord}-{endRecord} out of {totalRecords}"
      :global-filter-fields="['id', 'recipient.name', 'sender.name']"
    >
      <template #empty>
        <Empty v-if="!isLoading" :title="callCenterSelected ? 'No data' : `Select a ${$t('ui.entities.departments.call-centre')}`" />
      </template>
      <template #header>
        <div class="flex justify-between">
          <span class="flex justify-between gap-4">
            <span>
              <Input v-model="filters['global'].value" :placeholder="$t('ui.common.search', 1)" icon="search" />
            </span>
            <Button
              :label="$t('ui.actions.clear', 1)"
              severity="secondary"
              type="button"
              outlined
              icon="filter-slash"
              @click="clearFilters()"
            />
          </span>
        </div>
        <div class="flex justify-between mt-4">
          <span>
            <Button label="CSV" type="button" severity="secondary" outlined icon="file-excel" @click="exportCSV($event)" />
          </span>
        </div>
      </template>

      <Column field="uniqueId" header="#" hidden>
        <template #body="{ data }">{{ data.uniqueId }} </template>
      </Column>

      <Column field="recipient.name" header="Recipient" :sortable="true" :show-filter-match-modes="false" :show-filter-operator="false">
        <template #body="{ data }">
          <div class="flex items-center gap-3 relative">
            <div v-if="!data.isSeen" class="absolute w-2 h-2 bg-brand-500 rounded-full top-[50% - 1rem] -left-4"></div>
            <Avatar v-if="data.recipient.name" :url="$cdnBaseUrl + '/img/logos/manufacturers/' + data.recipient.manufacturerLogo" is-logo />
            <Avatar v-else icon="dash" />
            <div>
              <div class="font-semibold">
                {{ data.recipient.name || '-' }}
              </div>
              <div class="text-sm text-quaternary">{{ data.commTo }}</div>
            </div>
          </div>
        </template>
        <template #filter="{ filterModel }">
          <MultiSelect
            v-model="filterModel.value"
            :options="recipientsList"
            filter
            display="chip"
            option-value="value"
            option-label="label"
            placeholder="Any"
            class="max-w-[300px]"
          >
            <template #option="slotProps">
              {{ slotProps.option.label }}
            </template>
          </MultiSelect>
        </template>
      </Column>

      <Column field="commFrom" header="From" :sortable="true">
        <template #body="{ data }">
          <div class="flex gap-4 items-center">
            <Avatar :icon="data.agreementId ? 'user' : 'dash'" />
            <div>
              <div class="flex items-center gap-3">
                <router-link
                  v-if="data.agreementId"
                  class="link"
                  :class="{ 'cursor-default': !data.agreementId }"
                  :to="data.agreementId ? '/agreement/' + data.agreementId : ''"
                >
                  <div v-if="data.agreementId" class="font-semibold whitespace-nowrap">
                    {{ data.customerName }}
                  </div>
                </router-link>
                <Dropdown v-else>
                  <template #triggerContent>
                    <Button label="Link to customer" size="xs" outlined />
                  </template>
                  <Select
                    v-model="data.selectedAgreementId"
                    placeholder="Search for customer"
                    :options="agreementsList"
                    value-key="agreementId"
                    label-key="name"
                    searchable
                    :handle-search="false"
                    v-model:search-term="linkAgreementSearchTerm"
                    on-select-return-as-object
                    class="px-2 min-w-64"
                    @select="linkToCustomer(data, $event)"
                  >
                    <template #option="{ option }">
                      <div class="flex flex-col w-full text-wrap">
                        <div>{{ option.name }}</div>
                        <div class="text-sm text-tertiary">[{{ option.vrm }}] {{ option.vehicleMake }} {{ option.vehicleModel }}</div>
                      </div>
                    </template>
                  </Select>
                </Dropdown>
              </div>
              <div class="text-sm text-quaternary">{{ data.commFrom }}</div>
            </div>
          </div>
        </template>
      </Column>

      <Column field="type" header="Type" :sortable="true" :show-filter-match-modes="false" :show-filter-operator="false">
        <template #body="{ data }">
          <Icon v-tippy="data.type" :type="getTypeIcon(data.type)" />
        </template>
        <template #filter="{ filterModel }">
          <MultiSelect
            v-model="filterModel.value"
            :options="[
              { value: 'sms', label: 'Sms' },
              { value: 'email', label: 'Email' },
              { value: 'call', label: 'Call' }
            ]"
            filter
            display="chip"
            option-value="value"
            option-label="label"
            placeholder="Any"
            class="max-w-[300px]"
          >
            <template #option="slotProps">
              {{ slotProps.option.label }}
            </template>
          </MultiSelect>
        </template>
      </Column>

      <Column field="recordingUrl" hidden />

      <Column field="body" header="Content" :sortable="false">
        <template #body="{ data }">
          <div v-if="data.type == 'sms'" class="text-secondary text-sm">{{ data.smsMessage }}</div>
          <div v-if="data.type == 'email'" class="text-secondary text-sm">
            <div class="text-quaternary font-medium text-sm">{{ data.emailSubject }}</div>
            <div v-tippy="{ content: data.emailBody, allowHTML: true }" class="text-secondary text-sm">
              {{ _truncate(data.emailBody, { length: 100 }) }}
            </div>
          </div>
          <div>
            <AudioPlayer v-if="data.recordingUrl" :audio-src="data.recordingUrl" :can-download="false" style="min-width: 350px" />
          </div>
        </template>
      </Column>

      <Column field="userId" header="User" hidden :sortable="false" :show-filter-match-modes="false" :show-filter-operator="false">
        <template #body="{ data }">
          <div v-if="data.userId" class="flex gap-2">
            <Avatar
              v-if="data.userId"
              v-tippy="{ content: data.user.name ? data.user.name : 'Voicemail' }"
              :icon="data.voicemail ? 'voicemail' : ''"
              :url="$cdnBaseUrl + '/img/profile-pics/' + data.user.profilePic"
              :text="data.user.initials"
            />
          </div>
        </template>
        <template #filter="{ filterModel }">
          <MultiSelect
            v-model="filterModel.value"
            :options="usersList"
            filter
            display="chip"
            option-value="value"
            option-label="label"
            placeholder="Any"
            class="max-w-[300px]"
          >
            <template #option="slotProps">
              {{ slotProps.option.label }}
            </template>
          </MultiSelect>
        </template>
      </Column>

      <Column field="createdAt" header="Occurred" :sortable="true">
        <template #body="{ data }">
          <div v-tippy="dayjs(data.createdAt).format('h:mma Do MMM YYYY')" class="whitespace-nowrap">
            {{ dayjs().to(dayjs(data.createdAt)) }}
          </div>
        </template>
      </Column>

      <Column v-if="callCenterSelected == 1" field="callCenter" header="Contact Center" :sortable="true">
        <template #body="{ data }">
          <Chip v-if="data.callCenter" :label="data.callCenter.name" />
        </template>
      </Column>

      <Column field="isSeen" header="" :sortable="false">
        <template #body="{ data }">
          <Button v-if="!data.isSeen" v-tippy="'Mark as seen'" icon="circle-check" plain severity="secondary" @click="markAsSeen(data)" />
        </template>
      </Column>
    </DataTable>
  </Card>
</template>

<script setup>
import { computed, ref, watch, onMounted } from 'vue'

import PageHeader from '@/components/page/PageHeader.vue'
import Card from '@/components/card/Card.vue'
import Input from '@/components/forms/Input.vue'
import Button from '@/components/button/Button.vue'
import Icon from '@/components/icon/Icon.vue'
import AudioPlayer from '@/components/audio-player/AudioPlayer.vue'
import Avatar from '@/components/avatar/Avatar.vue'
import Dropdown from '@/components/dropdown/Dropdown.vue'
import Select from '@/components/forms/Select.vue'
import Empty from '@/components/empty/Empty.vue'
import Chip from '@/components/chip/Chip.vue'

import { useUserStore } from '@/stores/UserStore'
import useApiRequest from '@/composables/useApiRequest'

import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import { FilterMatchMode } from 'primevue/api'
import MultiSelect from 'primevue/multiselect'
import { MeiliSearch } from 'meilisearch'
import _truncate from 'lodash/truncate'
import { alertToast } from '@/utilities/notification'

const dt = ref()
const users = ref([])
const filters = ref(null)

const userStore = useUserStore()
const callCentersAllowed = userStore.callCenters
const callCenterSelected = ref(null)

const dealershipsAllowed = ref(userStore.accessAllowed.dealerships.map(dealership => dealership.id))
const dealershipsAllowedString = dealershipsAllowed.value.join(',')

const linkAgreementApiRequest = useApiRequest()

const linkAgreementSearchTerm = ref('')
const meilisearchResults = ref([])
const meilisearchClient = ref()

onMounted(() => {
  meilisearchClient.value = new MeiliSearch({
    host: import.meta.env.VITE_MEILISEARCH_URL,
    apiKey: import.meta.env.VITE_MEILISEARCH_API_KEY
  })
})

const agreementsList = ref([])
const search = async query => {
  if (query) {
    meilisearchResults.value = await meilisearchClient.value.index('agreements').search(query, {
      filter: 'dealershipId IN [' + dealershipsAllowedString + ']'
    })
    if (meiliSearchResults.value.hits != undefined) {
      agreementsList.value = meiliSearchResults.value.hits
    }
  } else {
    meilisearchResults.value = []
  }
}

watch(linkAgreementSearchTerm, newSearchTerm => {
  console.log(newSearchTerm)
  //Carry out meilisearch
  search(newSearchTerm)
})

const conversations = ref(null)
const conversationsApiRequest = useApiRequest()
const isLoading = conversationsApiRequest.isLoading

function fetchConversations() {
  conversationsApiRequest
    .send({ endpoint: '/v1/conversations/missed', method: 'GET', params: { callCenterId: callCenterSelected.value } })
    .then(response => {
      if (response.success) {
        conversations.value = response.data
      }
    })
}

function initFilters() {
  filters.value = {
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    rolesNamed: { value: null, matchMode: FilterMatchMode.IN },
    userId: { value: null, matchMode: FilterMatchMode.IN },
    'recipient.name': { value: null, matchMode: FilterMatchMode.IN },
    type: { value: null, matchMode: FilterMatchMode.IN }
  }
}
initFilters()

if (!localStorage.getItem('report-filter-call-center-selected')) {
  localStorage.setItem('report-filter-call-center-selected', callCentersAllowed[0].id)
  callCenterSelected.value = callCentersAllowed[0].id
} else {
  callCenterSelected.value = parseInt(localStorage.getItem('report-filter-call-center-selected'))
}

fetchConversations()

function updateCallCenterSelected() {
  localStorage.setItem('report-filter-call-center-selected', callCenterSelected.value)
  fetchConversations()
}

const clearFilters = () => {
  initFilters()
}

const getTypeIcon = type => {
  switch (type) {
    case 'sms':
      return 'sms'
    case 'email':
      return 'envelope'
    case 'call':
      return 'phone'
    default:
      return 'question'
  }
}

// Users List
const usersApiRequest = useApiRequest()
usersApiRequest.send({ endpoint: '/v1/users?userType=retain' }).then(response => {
  if (response.http.status == 200) {
    users.value = response.data
    console.log(users.value)
  }
})

const usersList = computed(() => {
  // if no users, return empty array
  if (!users.value) return []

  return users.value.map(u => {
    return {
      value: u.id,
      label: u.fullName,
      profilePicUrl: u.profilePicURL,
      initials: u.initials
    }
  })
})

const recipientsList = computed(() => {
  // return a list of unique recipients
  const recipients = new Set()
  conversations.value.forEach(c => {
    recipients.add(c.recipient.name || 'Unknown')
  })
  const sortedRecipients = Array.from(recipients).sort((a, b) => a.localeCompare(b))
  const recipientsList = sortedRecipients.map(recipient => {
    return {
      value: recipient === 'Unknown' ? null : recipient,
      label: recipient === 'Unknown' ? 'Unknown' : recipient
    }
  })
  // Move 'Unknown' to the top of the array
  const unknownIndex = recipientsList.findIndex(recipient => recipient.value === null)
  if (unknownIndex !== -1) {
    const unknownRecipient = recipientsList.splice(unknownIndex, 1)[0]
    recipientsList.unshift(unknownRecipient)
  }
  return recipientsList
})

function linkToCustomer(comm, selectedItem, $event) {
  let endpoint = null
  if (comm.type == 'call') {
    endpoint = '/v1/conversations/calls/' + comm.id
  } else if (comm.type == 'sms') {
    endpoint = '/v1/conversations/sms/' + comm.id
  } else if (comm.type == 'email') {
    endpoint = '/v1/conversations/emails/' + comm.id
  }

  linkAgreementApiRequest
    .send({
      endpoint: endpoint,
      data: {
        agreementId: selectedItem
      },
      method: 'PATCH'
    })
    .then(() => {
      comm.agreementId = selectedItem
      comm.customerName = $event.name

      alertToast('Customer linked', null, 'success')
    })
}

function markAsSeen(comm) {
  let endpoint = null
  if (comm.type == 'call') {
    endpoint = '/v1/conversations/calls/' + comm.id
  } else if (comm.type == 'sms') {
    endpoint = '/v1/conversations/sms/' + comm.id
  } else if (comm.type == 'email') {
    endpoint = '/v1/conversations/emails/' + comm.id
  }
  useApiRequest()
    .send({ endpoint: endpoint, method: 'PATCH', data: { isSeen: true } })
    .then(() => {
      comm.isSeen = true
    })
}

const exportCSV = () => {
  dt.value.exportCSV()
}
</script>

<style lang="scss" scoped></style>
