<template>
  <PageHeader
    :title="$t('ui.entities.comms.phone-number', 2)"
    breadcrumb
    :items="[
      { label: $t('ui.entities.setting', 2), to: '/settings' },
      { label: 'Communications' },
      { label: $t('ui.entities.comms.phone-number', 2), to: '/settings/phone-numbers' }
    ]"
  >
    <template #right>
      <Button
        v-if="$can('manage_communication_settings')"
        :label="$t('ui.crud.new', { item: $t('ui.entities.comms.phone-number') })"
        severity="primary"
        icon="plus"
        @click="newNumberModalIsOpen = true"
      />
    </template>
  </PageHeader>
  <Card>
    <DataTable
      ref="dt"
      v-model:filters="filters"
      :value="phoneNumberData"
      removable-sort
      sort-field="name"
      :rows="20"
      data-key="id"
      column-resize-mode="fit"
      :paginator="true"
      responsive-layout="scroll"
      filter-display="menu"
      :rows-per-page-options="[10, 20, 50]"
      state-key="dt-settings-dealership-phone-numbers"
      state-storage="session"
      current-page-report-template="Showing {startRecord}-{endRecord} out of {totalRecords}"
      :global-filter-fields="['id', 'name', 'location', 'phoneNumber']"
    >
      <template #empty> No records found </template>
      <template #header>
        <div class="flex justify-between">
          <span class="flex justify-between gap-4">
            <span>
              <Input v-model="filters['global'].value" :placeholder="$t('ui.common.search', 1)" icon="search" />
            </span>
            <Button
              :label="$t('ui.actions.clear', 1)"
              severity="secondary"
              type="button"
              outlined
              icon="filter-slash"
              @click="clearFilters()"
            />
          </span>
        </div>
        <div class="flex justify-between mt-4">
          <span>
            <Button label="CSV" type="button" severity="secondary" outlined icon="file-excel" @click="exportCSV($event)" />
          </span>
        </div>
      </template>

      <Column field="id" hidden />

      <Column field="phoneNumber" header="Number" sortable>
        <template #body="{ data }">
          <div class="whitespace-nowrap">+{{ data.dialingCode }} {{ data.number }}</div>
        </template>
      </Column>

      <Column field="canMakeCalls" header="Voice" sortable>
        <template #body="{ data }">
          <div>
            <Icon :type="data.canMakeCalls ? 'circle-check' : 'circle-xmark'" :severity="data.canMakeCalls ? 'success' : 'error'" />
          </div>
        </template>
      </Column>

      <Column field="canSendSms" header="SMS" sortable>
        <template #body="{ data }">
          <div>
            <Icon :type="data.canSendSms ? 'circle-check' : 'circle-xmark'" :severity="data.canSendSms ? 'success' : 'error'" />
          </div>
        </template>
      </Column>

      <Column field="country" header="Country" sortable>
        <template #body="{ data }">
          <Chip>
            <img :src="countriesList.find(country => data.dialingCode == country.dialCode)?.image" width="20" class="mr-1" />
            <div>
              {{ countriesList.find(country => data.dialingCode == country.dialCode)?.code }}
            </div>
          </Chip>
        </template>
      </Column>

      <Column field="location" header="Location" sortable>
        <template #body="{ data }">
          <div>
            {{ data.location }}
          </div>
        </template>
      </Column>

      <Column field="region" hidden />

      <Column field="name" header="Name" sortable>
        <template #body="{ data }">
          <div>
            {{ data.name }}
          </div>
        </template>
      </Column>

      <!-- <Column field="dealerships" header="Dealerships" :sortable="false" :show-filter-match-modes="false" :show-filter-operator="false">
        <template #body="{ data }">
          <div v-if="dealershipsList" class="flex flex-col gap-1 max-w-64">
            <div v-for="(num, i) in 2" :key="i">
              <Chip v-if="data.dealerships[i]" size="sm">
                <div
                  v-tippy="dealershipsList.find(d => d.value == data.dealerships[i])?.isActive ? null : 'Inactive'"
                  :class="dealershipsList.find(d => d.value == data.dealerships[i])?.isActive ? '' : 'text-quaternary'"
                >
                  {{ dealershipsList.find(d => d.value == data.dealerships[i])?.label }}
                </div>
              </Chip>
            </div>
            <div>
              <Chip v-if="data.dealerships[2]" size="sm">+ {{ data.dealerships.length - 2 }}</Chip>
            </div>
          </div>
        </template>
        <template #filter="{ filterModel }">
          <MultiSelect
            v-model="filterModel.value"
            :options="dealershipsList"
            filter
            display="chip"
            option-value="value"
            option-label="label"
            placeholder="All"
          >
            <template #option="slotProps">
              {{ slotProps.option.label }}
            </template>
          </MultiSelect>
        </template>
      </Column> -->

      <Column field="callCenter" header="Call Center" :sortable="true">
        <template #body="{ data }">
          <Chip class="link-hover" @click="$router.push('/settings/call-centers/' + data.callCenter.id)">
            {{ data.callCenter.name }}
          </Chip>
        </template>
      </Column>

      <Column field="sid" hidden />
      <Column field="location" hidden />
      <Column field="isActive" hidden />
      <Column
        field="isActive"
        header="Status"
        :sortable="true"
        :show-filter-match-modes="false"
        :show-filter-operator="false"
        :filter-menu-style="{ 'max-width': '24rem' }"
      >
        <template #body="{ data }">
          <Chip v-if="data.isDeactivating" :rounded="false" outlined size="sm" dot severity="warning">Deactivating</Chip>
          <Chip v-else-if="data.isActive" :rounded="false" outlined size="sm" dot severity="success">Active</Chip>
          <Chip v-else :rounded="false" outlined size="sm" dot severity="danger">Deactivated</Chip>
        </template>
      </Column>
      <Column v-if="$can('manage_communication_settings')">
        <template #body="{ data }">
          <Button v-tippy="$t('ui.actions.edit')" icon="pencil" plain severity="secondary" @click="editNumber(data.id)" />
        </template>
      </Column>
    </DataTable>
  </Card>

  <teleport to="#modals-container">
    <Modal
      :is-open="editNumberModalIsOpen"
      title="Edit Number Details"
      :has-unsaved-changes="editNumberHasUnsavedChanges"
      min-width="400"
      icon="phone"
      buttons="null"
      @close-modal="((editNumberModalIsOpen = false), (editNumberHasUnsavedChanges = false))"
      @ok-modal="updateNumberDetails()"
    >
      <div class="mb-4">
        <Chip v-if="phoneNumberEditable.isDeactivating" severity="warning">Deactivating</Chip>
        <Chip v-else-if="phoneNumberEditable.isActive" severity="success">Active</Chip>
        <Chip v-else severity="danger">Deactivated</Chip>

        <Alert
          v-if="phoneNumberEditable.isDeactivating"
          severity="warning"
          :title="
            'This number will remain active until ' + dayjs(phoneNumberEditable.deactivationStartedAt).add(30, 'day').format('Do MMMM YYYY')
          "
          message="
          Customers will be informed that the number is no longer in use."
          :show-close-button="false"
          :show-dismiss-button="false"
        />
      </div>

      <FormGroup label="Display Name" :is-required="v$.name.required?.$invalid">
        <Input
          v-model="phoneNumberEditable.name"
          :has-error="v$.name.$error"
          :readonly="!phoneNumberEditable.isActive || phoneNumberEditable.isDeactivating"
        />
        <InputError :has-error="v$.name.$error">{{ v$.name.$errors[0]?.$message }}</InputError>
      </FormGroup>
      <FormGroup label="Text-to-Speech" :is-required="v$.textToSpeechName.required?.$invalid">
        <Input
          v-model="phoneNumberEditable.textToSpeechName"
          icon="comment"
          :has-error="v$.textToSpeechName.$error"
          :readonly="!phoneNumberEditable.isActive || phoneNumberEditable.isDeactivating"
        />
        <InputError :has-error="v$.textToSpeechName.$error">{{ v$.textToSpeechName.$errors[0]?.$message }}</InputError>
      </FormGroup>
      <FormGroup :label="$t('ui.entities.comms.phone-number')" :is-required="v$.number.required?.$invalid">
        <Input v-model="phoneNumberEditable.number" readonly :has-error="v$.number.$error"></Input>
        <InputError :has-error="v$.number.$error">{{ v$.number.$errors[0]?.$message }}</InputError>
      </FormGroup>
      <FormGroup label="SID" :is-required="v$.sid.required?.$invalid">
        <Input
          v-model="phoneNumberEditable.sid"
          :has-error="v$.sid.$error"
          :readonly="!userStore.roles.includes(1) || !phoneNumberEditable.isActive || phoneNumberEditable.isDeactivating"
        />
        <InputError :has-error="v$.sid.$error">{{ v$.sid.$errors[0]?.$message }}</InputError>
      </FormGroup>
      <FormGroup label="Dealerships" :is-required="v$.sid.required?.$invalid">
        <MultiSelect
          v-model="phoneNumberEditable.dealerships"
          :options="dealershipsList"
          display="chip"
          filter
          option-value="value"
          option-label="label"
          class="w-full"
          :class="{ 'has-error': v$.dealerships.$error }"
          :disabled="!phoneNumberEditable.isActive || phoneNumberEditable.isDeactivating"
        >
          <template #option="{ option }">
            <img :src="option.manufacturerLogo" width="20" class="mr-3" />
            {{ option.label }}
          </template>
        </MultiSelect>
        <InputError :has-error="v$.dealerships.$error">{{ v$.dealerships.$errors[0]?.$message }}</InputError>
      </FormGroup>
      <div class="flex justify-end mt-8">
        <div class="w-full">
          <Button
            v-if="phoneNumberEditable.isActive"
            severity="danger"
            label="Deactivate"
            plain
            :is-loading="updateNumberStatusIsLoading"
            @click="updateNumberStatus('deactivate')"
          />
          <Button
            v-else-if="phoneNumberEditable.isDeactivating"
            severity="secondary"
            label="Activate"
            plain
            :is-loading="updateNumberStatusIsLoading"
            @click="updateNumberStatus('activate')"
          />
        </div>
        <div v-if="phoneNumberEditable.isActive || phoneNumberEditable.isDeactivating" class="flex justify-end gap-2">
          <Button
            :label="$t('ui.actions.cancel')"
            severity="secondary"
            outlined
            type="button"
            :disabled="submitIsLoading"
            @click="((editNumberModalIsOpen = false), (editNumberHasUnsavedChanges = false))"
          />
          <Button :label="$t('ui.actions.save')" :is-loading="updateNumberIsLoading" @click="updateNumberDetails()" />
        </div>
      </div>
    </Modal>
  </teleport>

  <teleport to="#modals-container">
    <Modal
      :is-open="newNumberModalIsOpen"
      title="New Phone Number"
      :has-unsaved-changes="newNumberHasUnsavedChanges"
      buttons="[]"
      min-width="400"
      icon="phone-office"
      overflow-behaviour="auto"
      @close-modal="((newNumberModalIsOpen = false), (newNumberHasUnsavedChanges = false))"
      @ok-modal="addNewNumber()"
    >
      <NewPhoneNumber @form-updated="newNumberHasUnsavedChanges = true" @completed="newNumberModalIsOpen = false" />
    </Modal>
  </teleport>
</template>

<script setup>
import { ref, watch } from 'vue'

import PageHeader from '@/components/page/PageHeader.vue'
import Card from '@/components/card/Card.vue'
import Icon from '@/components/icon/Icon.vue'
import Input from '@/components/forms/Input.vue'
import Button from '@/components/button/Button.vue'
import Chip from '@/components/chip/Chip.vue'
import Modal from '@/components/modal/Modal.vue'
import FormGroup from '@/components/forms/FormGroup.vue'
import InputError from '@/components/forms/InputError.vue'
import NewPhoneNumber from '@/components/unique/forms/NewPhoneNumber.vue'
import Alert from '@/components/alert/Alert.vue'

import { useUserStore } from '@/stores/UserStore'
import useApiRequest from '@/composables/useApiRequest'
import { alertToast } from '@/utilities/notification'

import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import { useI18n } from 'vue-i18n'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import { FilterMatchMode } from 'primevue/api'
import MultiSelect from 'primevue/multiselect'
import dayjs from 'dayjs'
import Swal from 'sweetalert2'

const dt = ref()
const userStore = useUserStore()

const phoneNumberData = ref(null)
const editNumberModalIsOpen = ref(false)
const newNumberModalIsOpen = ref(false)
const newNumberHasUnsavedChanges = ref(false)

const dealershipsApiRequest = useApiRequest()
const updateNumberApiRequest = useApiRequest()
const updateNumberIsLoading = updateNumberApiRequest.isLoading
const updateNumberStatusApiRequest = useApiRequest()
const updateNumberStatusIsLoading = updateNumberStatusApiRequest.isLoading

const phoneNumbersApiRequest = useApiRequest()

function fetchNumbers() {
  phoneNumbersApiRequest.send({ endpoint: '/v1/comms/phone-numbers', method: 'GET' }).then(response => {
    if (response.success) {
      phoneNumberData.value = response.data
    } else {
      alertToast('Failed to fetch numbers', response.message, 'error')
    }
  })
}
fetchNumbers()

const dealershipsList = ref(null)

const dealershipQueryparams = ref({
  showAll: true,
  showDisabled: true
})

dealershipsApiRequest.send({ method: 'GET', endpoint: '/v1/dealerships', params: dealershipQueryparams.value }).then(response => {
  dealershipsList.value = response.data.map(dealership => {
    return { value: dealership.id, label: dealership.displayName, isActive: dealership.isActive }
  })
})

const filters = ref(null)

function initFilters() {
  filters.value = {
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    dealerships: { value: null, matchMode: FilterMatchMode.EQUALS },
    location: { value: null, matchMode: FilterMatchMode.IN },
    id: { value: null, matchMode: FilterMatchMode.IN },
    number: { value: null, matchMode: FilterMatchMode.IN },
    name: { value: null, matchMode: FilterMatchMode.IN },
    isActive: { value: null, matchMode: FilterMatchMode.IN }
  }
}
initFilters()

const clearFilters = () => {
  initFilters()
}

const countriesList = [
  {
    name: useI18n().t('meta.countries.gb.label'),
    country: 'UK',
    code: 'GB',
    emoji: '🇬🇧',
    dialCode: 44,
    unicode: 'U+1F1EC U+1F1E7',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GB.svg'
  },
  {
    name: useI18n().t('meta.countries.us.label'),
    country: 'US',
    code: 'US',
    emoji: '🇺🇸',
    dialCode: 1,
    unicode: 'U+1F1FA U+1F1F8',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/US.svg'
  }
]

const phoneNumberEditable = ref(null)
const phoneNumberEditableInitState = ref(null)
const editNumberHasUnsavedChanges = ref(false)
const isFormCorrect = ref(null)

function editNumber(id) {
  phoneNumberEditable.value = { ...phoneNumberData.value.find(number => number.id == id) }
  phoneNumberEditableInitState.value = { ...phoneNumberEditable.value }

  editNumberModalIsOpen.value = true
}

const inputRules = {
  name: { required },
  textToSpeechName: { required },
  number: { required },
  sid: { required },
  dealerships: { required }
}
const v$ = useVuelidate(inputRules, phoneNumberEditable)

async function updateNumberDetails() {
  isFormCorrect.value = await v$.value.$validate()
  if (!isFormCorrect.value) {
    return
  }

  try {
    updateNumberApiRequest
      .send({
        endpoint: `/v1/comms/phone-numbers/${phoneNumberEditable.value.id}`,
        method: 'PATCH',
        data: JSON.stringify(phoneNumberEditable.value)
      })
      .then(response => {
        if (response.success) {
          alertToast('Updated', null, 'success')
          editNumberModalIsOpen.value = false
          editNumberHasUnsavedChanges.value = false
          fetchNumbers()
        } else {
          alertToast('Failed to update', response.message, 'error')
        }
      })
  } catch (err) {
    alertToast('Failed to update', err.message, 'warning')
  }
}

async function updateNumberStatus(action) {
  let dataToPost = {
    action: action
  }

  Swal.fire({
    title: 'Are you sure?',
    icon: 'warning',
    reverseButtons: true,
    showCloseButton: true,
    showCancelButton: true,
    confirmButtonText: `Yes, ${action} this number`,
    confirmButtonColor: 'var(--bg-error-solid)',
    cancelButtonText: 'Cancel',
    showLoaderOnConfirm: true
  }).then(function (response) {
    if (response.isConfirmed) {
      try {
        updateNumberStatusApiRequest
          .send({
            endpoint: `/v1/comms/phone-numbers/${phoneNumberEditable.value.id}/deactivate`,
            method: 'POST',
            data: JSON.stringify(dataToPost)
          })
          .then(response => {
            console.log(response)

            if (response.success) {
              if (action == 'activate') {
                alertToast('Activated', null, 'success')
              } else if (action == 'deactivate') {
                alertToast('Deactivated', null, 'success')
              }
              fetchNumbers()
              editNumberModalIsOpen.value = false
              editNumberHasUnsavedChanges.value = false
            } else {
              alertToast(`Failed to ${action} number`, response.message, 'error')
            }
          })
      } catch (err) {
        alertToast(`Failed to ${action} number`, err.message, 'error')
      }
    }
  })
}

const exportCSV = () => {
  dt.value.exportCSV()
}

watch(
  () => phoneNumberEditable.value,
  () => {
    if (JSON.stringify(phoneNumberEditable.value) != JSON.stringify(phoneNumberEditableInitState.value)) {
      editNumberHasUnsavedChanges.value = true
    } else {
      editNumberHasUnsavedChanges.value = false
    }
  },
  { deep: true }
)
</script>

<style></style>
