// src/utils/countries.js
import countries from '@/data/countries.json'

export function getAllCountries() {
  return countries
}

export function getCountryByCode(isoCode) {
  return countries.find(country => country.code === isoCode) || null
}

