<template>
  <PageHeader
    title="Source Conversions"
    breadcrumb
    :items="[{ label: $t('ui.entities.report.label', 2), to: '/reports' }, { label: 'Appointments' }, { label: 'Source Conversions' }]"
  >
    <template #right>
      <div class="flex items-center gap-2">
        <DatePicker
          v-model="dateRange"
          selection-mode="range"
          max-range="31"
          position="bottom-right"
          :max-date="new Date()"
          :number-of-months="useBreakPoints({ xs: 1, sm: 2 })"
          @change-applied="onDateRangeChange"
        />
      </div>
    </template>
  </PageHeader>
  <Card>
    <DataTable
      ref="dt"
      v-model:filters="filters"
      export-filename="Source Conversions"
      :value="conversionsData"
      removable-sort
      sort-field="timestamp"
      :sort-order="-1"
      data-key="id"
      column-resize-mode="fit"
      :paginator="true"
      responsive-layout="scroll"
      filter-display="menu"
      :global-filter-fields="['dealershipName']"
      :rows="20"
      :rows-per-page-options="[10, 20, 50]"
      state-key="dt-report-source-conversions"
      state-storage="session"
      current-page-report-template="Showing {startRecord}-{endRecord} out of {totalRecords}"
      :loading="conversionsApiRequest.isLoading.value"
    >
      <template #empty> No records found </template>

      <template #header>
        <div class="flex justify-between">
          <span class="flex justify-between gap-4">
            <span>
              <Input v-model="filters['global'].value" :placeholder="$t('ui.common.search', 1)" icon="search" />
            </span>
            <Button
              :label="$t('ui.actions.clear', 1)"
              severity="secondary"
              type="button"
              outlined
              icon="filter-slash"
              @click="clearFilters()"
            />
          </span>
        </div>
        <div class="flex justify-between mt-4">
          <span>
            <Button label="CSV" type="button" severity="secondary" outlined icon="file-excel" @click="exportCSV($event)" />
          </span>
        </div>
      </template>

      <ColumnGroup type="header">
        <Row>
          <Column />
          <Column header="Booked" :colspan="3" />
          <Column header="Dealt" :colspan="3" />
        </Row>

        <Row>
          <Column header="Dealership" sortable field="dealershipId" :show-filter-match-modes="false" :show-filter-operator="false">
            <template #filter="{ filterModel }">
              <MultiSelect
                v-model="filterModel.value"
                :options="dealershipsList"
                filter
                display="chip"
                option-value="value"
                option-label="label"
                placeholder="Any"
                class="max-w-[300px]"
              >
                <template #option="slotProps">
                  {{ slotProps.option.label }}
                </template>
              </MultiSelect>
            </template>
          </Column>
          <Column field="totals.callCenter" sortable>
            <template #header> Call Center </template>
          </Column>
          <Column field="totals.dealer" sortable>
            <template #header> Dealer </template>
          </Column>
          <Column field="totals.customer" sortable>
            <template #header> Customer </template>
          </Column>

          <Column field="totalsDealt.callCenter" sortable>
            <template #header> Call Center </template>
          </Column>
          <Column field="totalsDealt.dealer" sortable>
            <template #header> Dealer </template>
          </Column>
          <Column field="totalsDealt.customer" sortable>
            <template #header> Customer </template>
          </Column>
        </Row>
      </ColumnGroup>

      <Column field="dealershipName" header="Dealership Name" hidden />

      <Column field="dealershipId" header="Dealership" :sortable="true" :show-filter-match-modes="false" :show-filter-operator="false">
        <template #body="{ data }">
          {{ data.dealershipName }}
        </template>
        <template #filter="{ filterModel }">
          <MultiSelect
            v-model="filterModel.value"
            :options="dealershipsList"
            filter
            display="chip"
            option-value="value"
            option-label="label"
            placeholder="Any"
            class="max-w-[300px]"
          >
            <template #option="slotProps">
              {{ slotProps.option.label }}
            </template>
          </MultiSelect>
        </template>
      </Column>

      <Column field="totals.callCenter" header="Call Center" :sortable="true">
        <template #body="{ data }">
          {{ data.totals.callCenter }}
        </template>
      </Column>
      <Column field="totals.dealer" header="Dealer" :sortable="true">
        <template #body="{ data }">
          {{ data.totals.dealer }}
        </template>
      </Column>
      <Column field="totals.customer" header="Customer" :sortable="true">
        <template #body="{ data }">
          {{ data.totals.customer }}
        </template>
      </Column>

      <Column field="totalsDealt.callCenter" header="Call Center" :sortable="true">
        <template #body="{ data }">
          {{ data.totalsDealt.callCenter }}
        </template>
      </Column>
      <Column field="totalsDealt.dealer" header="Dealer" :sortable="true">
        <template #body="{ data }">
          {{ data.totalsDealt.dealer }}
        </template>
      </Column>
      <Column field="totalsDealt.customer" header="Customer" :sortable="true">
        <template #body="{ data }">
          {{ data.totalsDealt.customer }}
        </template>
      </Column>
    </DataTable>
  </Card>
</template>

<script setup>
import { ref, computed } from 'vue'

import Card from '@/components/card/Card.vue'
import PageHeader from '@/components/page/PageHeader.vue'
import DatePicker from '@/components/date-picker/DatePicker.vue'
import Input from '@/components/forms/Input.vue'
import Button from '@/components/button/Button.vue'

import useApiRequest from '@/composables/useApiRequest'
import { useBreakPoints } from '@/composables/useWindowSize'
import { useUserStore } from '@/stores/UserStore'
import { alertToast } from '@/utilities/notification'

import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import ColumnGroup from 'primevue/columngroup'
import Row from 'primevue/row'
import { FilterMatchMode } from 'primevue/api'
import MultiSelect from 'primevue/multiselect'
import dayjs from 'dayjs'

const dt = ref()
const userStore = useUserStore()
const dealershipsAllowed = ref(userStore.accessAllowed.dealerships)
const dateRange = ref([dayjs().startOf('month').toDate(), dayjs().toDate()])
const conversionsApiRequest = useApiRequest()
const conversionsData = ref([])
const filters = ref([])

function initFilters() {
  filters.value = {
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    dealershipId: { value: null, matchMode: FilterMatchMode.IN }
  }
}
initFilters()

function clearFilters() {
  initFilters()
}

function fetchConversionsData() {
  try {
    conversionsApiRequest
      .send({
        endpoint: 'v1/reports/appointments/source-conversions',
        params: {
          start: dayjs(dateRange.value[0]).format('YYYY-MM-DD'),
          end: dayjs(dateRange.value[1]).format('YYYY-MM-DD'),
          dealerships: dealershipsAllowed.value.map(d => d.id).join(',')
        }
      })
      .then(response => {
        if (response.success) {
          conversionsData.value = response.data
        } else {
          alertToast('Failed to fetch data', response.message, 'error')
        }
      })
  } catch (err) {
    alertToast('Failed to fetch data', err.message, 'error')
  }
}
fetchConversionsData()

function onDateRangeChange() {
  if (dateRange.value[0] && dateRange.value[1]) {
    fetchConversionsData()
  }
}

const dealershipsList = computed(() => {
  return dealershipsAllowed.value.map(d => {
    return {
      value: d.id,
      label: d.name
    }
  })
})

const exportCSV = () => {
  dt.value.exportCSV()
}
</script>

<style></style>
