<template>
  <PageHeader
    title="Method Conversions"
    breadcrumb
    :items="[
      { label: $t('ui.entities.report.label', 2), to: '/reports' },
      { label: $t('ui.entities.appointment.label', 2) },
      { label: 'Method Conversions', to: '/reports/appointments/conversions' }
    ]"
  >
    <template #right>
      <div class="flex items-center gap-2">
        <MultiSelect
          v-model="dealershipsSelected"
          :options="dealershipsList"
          display="chip"
          filter
          option-value="value"
          option-label="label"
          placeholder="Select a Dealership"
          class="max-w-[350px]"
        >
        </MultiSelect>
        <DatePicker
          v-model="dateRange"
          selection-mode="range"
          position="bottom-right"
          :max-date="new Date()"
          :number-of-months="useBreakPoints({ xs: 1, sm: 2 })"
        />
        <Button label="Update" :is-loading="dataIsLoading" @click="onUpdateData" />
      </div>
    </template>
  </PageHeader>

  <Card>
    <DataTable
      ref="dt"
      v-model:filters="filters"
      export-filename="Method Conversions"
      :value="conversions"
      removable-sort
      sort-field="start"
      :sort-order="-1"
      :rows="20"
      data-key="id"
      column-resize-mode="fit"
      :paginator="true"
      responsive-layout="scroll"
      filter-display="menu"
      :global-filter-fields="['dealershipName']"
      :rows-per-page-options="[10, 20, 50]"
      state-key="dt-report-method-conversions"
      state-storage="session"
      current-page-report-template="Showing {startRecord}-{endRecord} out of {totalRecords}"
      :loading="conversionsApiRequest.isLoading.value"
      :export-function="formatExportData"
    >
      <template #empty> {{ conversions.length ? 'Select a dealership' : 'No records found' }}</template>
      <template #header>
        <div class="flex justify-between">
          <span class="flex justify-between gap-4">
            <span>
              <Input v-model="filters['global'].value" :placeholder="$t('ui.common.search', 1)" icon="search" />
            </span>
            <Button
              :label="$t('ui.actions.clear', 1)"
              severity="secondary"
              type="button"
              outlined
              icon="filter-slash"
              @click="clearFilters()"
            />
          </span>
        </div>
        <div class="flex justify-between mt-4">
          <span>
            <Button label="CSV" type="button" severity="secondary" outlined icon="file-excel" @click="exportCSV($event)" />
          </span>
        </div>
      </template>

      <ColumnGroup type="header">
        <Row>
          <Column />

          <Column header="Occurred" :colspan="4" />
          <Column header="Dealt" :colspan="4" />
        </Row>

        <Row>
          <Column header="Dealership" sortable field="dealershipId" :show-filter-match-modes="false" :show-filter-operator="false">
            <template #filter="{ filterModel }">
              <MultiSelect
                v-model="filterModel.value"
                :options="dealershipsList"
                filter
                display="chip"
                option-value="value"
                option-label="label"
                placeholder="Any"
                class="max-w-[300px]"
              >
                <template #option="slotProps">
                  {{ slotProps.option.label }}
                </template>
              </MultiSelect>
            </template>
          </Column>
          <Column field="totals.physical" sortable>
            <template #header>
              <Icon
                v-tippy="{ content: $t(getProperties('appointmentMethod', 'physical')?.localeKey) }"
                type="location-dot"
                class="text-lg mr-2"
              />
            </template>
          </Column>
          <Column field="totals.phone" sortable>
            <template #header>
              <Icon v-tippy="{ content: $t(getProperties('appointmentMethod', 'phone')?.localeKey) }" type="phone" class="text-lg mr-2" />
            </template>
          </Column>
          <Column field="totals.video" sortable>
            <template #header>
              <Icon v-tippy="{ content: $t(getProperties('appointmentMethod', 'video')?.localeKey) }" type="video" class="text-lg mr-2" />
            </template>
          </Column>
          <Column field="totals.total" header="Total" sortable />
          <Column field="totalsDealt.physical" sortable>
            <template #header>
              <Icon
                v-tippy="{ content: $t(getProperties('appointmentMethod', 'physical')?.localeKey) }"
                type="location-dot"
                class="text-lg mr-2"
              />
            </template>
          </Column>
          <Column field="totalsDealt.phone" sortable>
            <template #header>
              <Icon v-tippy="{ content: $t(getProperties('appointmentMethod', 'phone')?.localeKey) }" type="phone" class="text-lg mr-2" />
            </template>
          </Column>
          <Column field="totalsDealt.video" sortable>
            <template #header>
              <Icon v-tippy="{ content: $t(getProperties('appointmentMethod', 'video')?.localeKey) }" type="video" class="text-lg mr-2" />
            </template>
          </Column>
          <Column field="totalsDealt.total" header="Total" sortable />
        </Row>
      </ColumnGroup>

      <Column field="dealershipId" header="Dealership" :sortable="true" :show-filter-match-modes="false" :show-filter-operator="false">
        <template #body="{ data }">
          <div>{{ data.dealershipName }}</div>
        </template>
      </Column>

      <Column hidden field="dealershipName" header="Dealership" />

      <Column field="totals.physical" header="Physical (Total)">
        <template #body="{ data }">
          <div>{{ data.totals.physical }}</div>
        </template>
      </Column>
      <Column field="totals.phone" header="Phone (Total)">
        <template #body="{ data }">
          <div>{{ data.totals.phone }}</div>
        </template>
      </Column>
      <Column field="totals.video" header="Video (Total)">
        <template #body="{ data }">
          <div>{{ data.totals.video }}</div>
        </template>
      </Column>
      <Column field="totals.total" header="Total (Total)">
        <template #body="{ data }">
          <div>{{ data.totals.total }}</div>
        </template>
      </Column>
      <Column field="totalsDealt.physical" header="Physical (Dealt)">
        <template #body="{ data }">
          <div>
            {{ data.totalsDealt.physical }}
            <Chip size="xs">{{ calculateConversion(data.totals.physical, data.totalsDealt.physical) }}</Chip>
          </div>
        </template>
      </Column>
      <Column field="totalsDealt.phone" header="Phone (Dealt)">
        <template #body="{ data }">
          <div>
            {{ data.totalsDealt.phone }}
            <Chip size="xs">{{ calculateConversion(data.totals.phone, data.totalsDealt.phone) }}</Chip>
          </div>
        </template>
      </Column>
      <Column field="totalsDealt.video" header="Video (Dealt)">
        <template #body="{ data }">
          <div>
            {{ data.totalsDealt.video }}
            <Chip size="xs">{{ calculateConversion(data.totals.video, data.totalsDealt.video) }}</Chip>
          </div>
        </template>
      </Column>
      <Column field="totalsDealt.total" header="Total (Dealt)">
        <template #body="{ data }">
          <div>
            {{ data.totalsDealt.total }}
            <Chip size="xs">{{ calculateConversion(data.totals.total, data.totalsDealt.total) }}</Chip>
          </div>
        </template>
      </Column>

      <ColumnGroup type="footer">
        <Row>
          <Column :colspan="1" footer-style="text-align:left; padding: 14px 21px;">
            <template #footer>
              {{ $t('ui.common.total', 1) }}
            </template>
          </Column>
          <Column footer-style="padding: 14px 21px;">
            <template #footer>
              {{ tableData.map(c => c.totals.physical || 0).reduce((a, b) => a + b, 0) }}
            </template>
          </Column>
          <Column footer-style="padding: 14px 21px;">
            <template #footer>
              {{ tableData.map(c => c.totals.phone || 0).reduce((a, b) => a + b, 0) }}
            </template>
          </Column>
          <Column footer-style="padding: 14px 21px;">
            <template #footer>
              {{ tableData.map(c => c.totals.video || 0).reduce((a, b) => a + b, 0) }}
            </template>
          </Column>
          <Column footer-style="padding: 14px 21px;">
            <template #footer>
              {{ tableData.map(c => c.totals.total || 0).reduce((a, b) => a + b, 0) }}
            </template>
          </Column>
          <Column footer-style="padding: 14px 21px;">
            <template #footer>
              {{ tableData.map(c => c.totalsDealt.physical || 0).reduce((a, b) => a + b, 0) }}
            </template>
          </Column>
          <Column footer-style="padding: 14px 21px;">
            <template #footer>
              {{ tableData.map(c => c.totalsDealt.phone || 0).reduce((a, b) => a + b, 0) }}
            </template>
          </Column>
          <Column footer-style="padding: 14px 21px;">
            <template #footer>
              {{ tableData.map(c => c.totalsDealt.video || 0).reduce((a, b) => a + b, 0) }}
            </template>
          </Column>
          <Column footer-style="padding: 14px 21px;">
            <template #footer>
              {{ tableData.map(c => c.totalsDealt.total || 0).reduce((a, b) => a + b, 0) }}
            </template>
          </Column>
        </Row>
      </ColumnGroup>
    </DataTable>
  </Card>
</template>

<script setup>
import Card from '@/components/card/Card.vue'
import PageHeader from '@/components/page/PageHeader.vue'
import DatePicker from '@/components/date-picker/DatePicker.vue'
import Icon from '@/components/icon/Icon.vue'
import Input from '@/components/forms/Input.vue'
import Button from '@/components/button/Button.vue'
import Chip from '@/components/chip/Chip.vue'

import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import ColumnGroup from 'primevue/columngroup'
import Row from 'primevue/row'
import { FilterMatchMode } from 'primevue/api'
import MultiSelect from 'primevue/multiselect'

import dayjs from 'dayjs'

import useApiRequest from '@/composables/useApiRequest'
import { useUserStore } from '@/stores/UserStore'
import { useBreakPoints } from '@/composables/useWindowSize'
import { getProperties } from '@/utilities/dataMapper'

import { ref, computed } from 'vue'

const dt = ref({ processedData: [] })

const userStore = useUserStore()

const dealershipsSelected = ref([])
const dealershipsSelectedLS = JSON.parse(localStorage.getItem('report-appointments-conversions-dealerships-selected'))
if (dealershipsSelectedLS) {
  dealershipsSelected.value = dealershipsSelectedLS
}
const dealershipsAllowed = ref(userStore.accessAllowed.dealerships)

// If dealershipsSelected has values which aren't in dealershipsAllowed, remove them
dealershipsSelected.value = dealershipsSelected.value.filter(d => dealershipsAllowed.value.map(da => da.id).includes(d))
localStorage.setItem('report-appointments-conversions-dealerships-selected', JSON.stringify(dealershipsSelected.value))

const dateRange = ref([dayjs().startOf('month').toDate(), dayjs().toDate()])
const conversionsApiRequest = useApiRequest()

const conversions = ref([])

const filters = ref([])

function initFilters() {
  filters.value = {
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    dealershipId: { value: null, matchMode: FilterMatchMode.IN }
  }
}
initFilters()

function clearFilters() {
  //remove from session storage
  sessionStorage.removeItem('dt-report-appointment-conversions')
  initFilters()
}

function fetchConversions() {
  if (!dealershipsSelected.value.length) {
    //Empty the data
    conversions.value = []
    return
  }
  conversionsApiRequest
    .send({
      endpoint: '/v1/reports/appointments/method-conversions',
      params: {
        start: dayjs(dateRange.value[0]).format('YYYY-MM-DD'),
        end: dayjs(dateRange.value[1]).format('YYYY-MM-DD'),
        dealerships: dealershipsSelected.value.join(',')
      }
    })
    .then(response => {
      if (response.success) {
        conversions.value = response.data
      }
    })
}
if (dealershipsSelected.value.length) {
  fetchConversions()
}

function onUpdateData() {
  if (dateRange.value[0] && dateRange.value[1] && dealershipsList.value.length) {
    localStorage.setItem('report-appointments-conversions-dealerships-selected', JSON.stringify(dealershipsSelected.value))

    fetchConversions()
  }
}

const dealershipsList = computed(() => {
  return dealershipsAllowed.value.map(d => {
    return {
      value: d.id,
      label: d.name
    }
  })
})

const tableData = computed(() => {
  return dt.value.processedData
})

const exportCSV = () => {
  dt.value.exportCSV()
}

function calculateConversion(total, totalDealt) {
  if (total === 0) {
    return '0%'
  }
  return `${((totalDealt / total) * 100).toFixed(0)}%`
}
</script>

<style></style>
