<template>
  <PageHeader :title="$t('ui.entities.setting', 2)"></PageHeader>
  <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8">
    <div v-for="category in settings" :key="category.title">
      <Card no-margin>
        <CardBody>
          <CardTitle :title="category.title"></CardTitle>
          <ListGroup
            v-for="section in category.sections"
            :key="section.title"
            :class="{ 'mb-5 last-of-type:mb-0': category.sections.length > 1 }"
          >
            <ListGroupItem v-for="item in section.items" :key="item.title" class="flex justify-between items-center" :route="item.route">
              <div class="flex items-center">
                <Icon :type="item.icon" />
                <span class="ml-2">{{ item.localeKey != null ? $t('terms.' + item.localeKey) : item.title }}</span>
              </div>
              <div class="flex items-center">
                <Chip v-if="item.badge" class="mr-2" :severity="item.badge.variant" :rounded="false" size="sm">{{ item.badge.title }}</Chip>
                <Icon type="chevron-right" />
              </div>
            </ListGroupItem>
          </ListGroup>
        </CardBody>
      </Card>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { useAbility } from '@casl/vue'

import PageHeader from '@/components/page/PageHeader.vue'
import Card from '@/components/card/Card.vue'
import CardTitle from '@/components/card/CardTitle.vue'
import CardBody from '@/components/card/CardBody.vue'
import Icon from '@/components/icon/Icon.vue'
import Chip from '@/components/chip/Chip.vue'
import ListGroup from '@/components/list/ListGroup.vue'
import ListGroupItem from '@/components/list/ListGroupItem.vue'
export default {
  name: 'Settings',
  components: {
    PageHeader,
    Card,
    CardTitle,
    CardBody,
    Icon,
    Chip,
    ListGroup,
    ListGroupItem
  },
  setup() {
    const { t } = useI18n()
    const { can } = useAbility()

    const allSettings = [
      {
        title: t('ui.common.user', 2),
        visible: true,
        sections: [
          {
            title: null,
            visible: can('manage_users'),
            items: [
              {
                title: t('ui.common.user', 2),
                visible: true,
                route: '/settings/users',
                icon: 'users'
                // badge: {
                //   title: 'Updated',
                //   variant: 'info'
                // }
              }
            ]
          },
          {
            title: t('ui.common.access'),
            visible: can('manage_roles_and_permissions'),
            items: [
              {
                title: t('ui.common.role', 2),
                visible: true,
                route: '/settings/roles',
                icon: 'user-tag',
                badge: {
                  title: 'Coming Soon',
                  variant: 'default'
                }
              },
              {
                title: t('ui.common.permission', 2),
                visible: true,
                route: '/settings/permissions',
                icon: 'user-shield',
                badge: {
                  title: 'Coming Soon',
                  variant: 'default'
                }
              }
            ]
          },
          {
            title: 'Shifts',
            visible: can('manage_user_schedule'),
            items: [
              {
                title: t('ui.entities.schedule.template.label', 1),
                visible: true,
                localeTerm: 'schedule template',
                route: '/settings/schedule/template',
                icon: 'calendar-pen'
              },
              {
                title: t('ui.entities.schedule.label', 1),
                visible: true,
                route: '/settings/schedule',
                icon: 'calendar-clock'
              }
            ]
          }
        ]
      },
      {
        title: t('ui.entities.dealership', 2),
        visible: can('manage_dealerships'),
        sections: [
          {
            title: 'Entities',
            visible: true,
            items: [
              {
                title: t('ui.entities.manufacturer', 2),
                visible: true,
                route: '/settings/manufacturers',
                icon: 'industry'
              },
              {
                title: t('ui.entities.group', 2),
                visible: true,
                route: '/settings/groups',
                icon: 'briefcase-blank'
              },
              {
                title: t('ui.entities.dealership', 2),
                visible: true,
                route: '/settings/dealerships',
                icon: 'car-building'
                // badge: {
                //   title: 'New',
                //   variant: 'success'
                // }
              }
            ]
          },
          {
            title: t('ui.entities.report.target', 2),
            visible: true,
            items: [
              {
                title: t('ui.entities.report.target', 2),
                visible: true,
                route: '/settings/dealership/targets',
                icon: 'bullseye'
              }
            ]
          }
        ]
      },
      {
        title: t('ui.common.communication', 2),
        visible: can('manage_communication_settings'),
        sections: [
          {
            title: t('ui.entities.departments.call-center', 2),
            visible: can('manage_call_centers'),
            items: [
              {
                title: t('ui.entities.departments.call-center', 2),
                visible: true,
                route: '/settings/call-centers',
                icon: 'phone-office',
                badge: {
                  title: 'New',
                  variant: 'success'
                }
              }
            ]
          },
          {
            title: t('ui.entities.comms.phone-number', 2),
            visible: true,
            items: [
              {
                title: t('ui.entities.comms.phone-number', 2),
                visible: true,
                route: '/settings/phone-numbers',
                icon: 'phone',
                badge: {
                  title: 'Updated',
                  variant: 'info'
                }
              }
            ]
          }
        ]
      },
      {
        title: 'Security',
        visible: can('view_external_authenticator_codes'),
        sections: [
          {
            title: 'External MFA',
            visible: can('view_external_authenticator_codes'),
            items: [
              {
                title: 'External Authenticator Codes',
                visible: true,
                route: '/settings/security/authenticator-codes/external',
                icon: 'key',
                badge: {
                  title: 'New',
                  variant: 'success'
                }
              }
            ]
          }
        ]
      },
      {
        title: t('ui.entities.finance.label'),
        visible: can('manage_finances'),
        sections: [
          {
            title: t('ui.entities.finance.invoicing'),
            visible: true,
            items: [
              {
                title: t('ui.entities.finance.billing-account', 2),
                visible: true,
                route: '/settings/finance/billing-accounts',
                icon: 'money-check-pen'
              },
              {
                title: 'Repeating Invoices',
                visible: true,
                route: '/settings/finance/repeating-invoices',
                icon: 'file-invoice'
              }
            ]
          }
        ]
      },
      {
        title: 'Admin',
        visible: can('administer_system'),
        sections: [
          {
            title: t('ui.entities.user.role.admin'),
            visible: true,
            items: [
              {
                title: 'Cron Jobs',
                visible: true,
                route: '/settings/admin/cron-jobs',
                icon: 'repeat'
              }
            ]
          }
        ]
      }
    ]

    const settings = computed(() => {
      // Filter out all settings, categories, and items that are not visible
      const visibleSettings = allSettings.filter(category => {
        category.sections = category.sections.filter(section => {
          section.items = section.items.filter(item => {
            return item.visible
          })
          return section.visible
        })
        return category.visible
      })

      // If category has no visible sections, remove it
      visibleSettings.forEach(category => {
        if (category.sections.length === 0) {
          visibleSettings.splice(visibleSettings.indexOf(category), 1)
        }
      })

      return visibleSettings
    })

    return { settings }
  }
}
</script>

<style></style>
