import { ref, onMounted } from 'vue'
import axios from 'axios'

export function timeZones() {
  // create a reactive variable to store the fetched data
  const timezones = ref([])

  onMounted(() => {
    axios
      .get('https://api.timezonedb.com/v2.1/list-time-zone?key=PJ3WFAM872HM&format=json', { withCredentials: false })
      .then(res => {
        if (res.data.status === 'OK') {
          timezones.value = res.data.zones
        } else {
          throw new Error(res.data.message)
        }
      })
      .catch(err => {
        console.error('Failed to fetch timezones:', err)
      })
  })

  // return the reactive ref
  return timezones
}
