<template>
  <div class="phone-wrapper">
    <!-- <div v-tippy="{ content: '🪲 FOR DEBUGGING' }" class="ml-6 phone-card p-3">
      <Icon v-if="phoneData.onPhone" type="check" class="text-success text-3xl" />
      <Icon v-else type="xmark" class="text-danger text-3xl" />
      {{ userStore.usersPresence[0].status }}
    </div> -->
    <!-- <div v-tippy="{ content: '🪲 FOR DEBUGGING' }" class="ml-6 phone-card p-3">
      <div>socketCalls: {{ socketCalls.length }}</div>
      <div>callList: {{ callList.length }}</div>
    </div> -->
    <div class="ml-[1.5rem]">
      <div
        v-if="phoneData.isInitialized"
        class="phone-card"
        :class="{
          'phone-card-active-call': phoneData.onPhone,
          'has-unhandled-calls': unhandledInboundCallCount > 0 && isMinimized && !phoneData.onPhone,
          'has-urgent-unhandled-calls':
            unhandledInboundCallCount > 0 && isMinimized && !phoneData.onPhone && callsWaitingOverTwoMinutes.length > 0
        }"
      >
        <div class="phone-window-buttons">
          <!-- <div v-if="!isMinimized" class="phone-window-button">
            {{ unhandledInboundCallCount }}
          </div>
          <div v-if="!isMinimized" class="phone-window-button" @click="phoneData.onPhone = !phoneData.onPhone">
            {{ phoneData.onPhone }}
          </div> -->
          <!-- <div
            v-if="unhandledInboundCallCount > 0 && phoneData.onPhone"
            class="phone-window-button"
            :style="[inboundRingtoneIsMuted ? 'background-color: var(--bg-error-solid)!important; color: white!important;' : '']"
            @click="audioInboundCall.volume = audioInboundCall.volume === 0 ? 1 : 0"
          >
            <Icon :type="inboundRingtoneIsMuted ? 'volume-slash' : 'volume'" />
          </div> -->
          <div v-if="!isMinimized" class="phone-window-button" @click="openInfoModal">
            <Icon type="info" />
          </div>
          <div v-if="!isMinimized" class="phone-window-button" @click="minimizeToggle">
            <Icon type="minus" />
          </div>
        </div>
        <div v-if="!isMinimized" class="phone-maximized-card flex">
          <div class="phone-section phone-dialler p-7">
            <div class="w-[200px] text-lg">
              <div class="flex items-center">
                <Icon type="building" class="text-sm mr-2" />
                <!-- <div class="text-secondary whitespace-nowrap">{{ phoneData.numberFromCountryCode }}</div> -->
                <input v-model="phoneData.numberFrom" class="phone-dialler-input w-full text-md" placeholder="from" />
                <Icon v-if="valNumberFrom.validatedData.value.valid" type="circle-check" class="text-success" />
                <Icon
                  v-else-if="phoneData.numberFrom"
                  v-tippy="{ content: valNumberFrom.validatedData.value.possibility }"
                  type="circle-exclamation"
                  class="text-danger"
                />
              </div>
              <div class="flex items-center">
                <Icon type="user" class="text-sm mr-2" />
                <!-- <div class="text-secondary whitespace-nowrap">{{ phoneData.numberToCountryCode }}</div> -->
                <input v-model="phoneData.numberTo" class="phone-dialler-input w-full text-md" placeholder="to" />
                <Icon v-if="valNumberTo.validatedData.value.valid" type="circle-check" class="text-success" />
                <Icon
                  v-else-if="phoneData.numberTo"
                  v-tippy="{ content: valNumberTo.validatedData.value.possibility }"
                  type="circle-exclamation"
                  class="text-danger"
                />
              </div>
            </div>
            <!-- <div v-for="warning in phoneData.warnings" :key="warning.warningName" class="text-warning">
              <Icon type="triangle-exclamation" /> {{ warning.warningName }}
            </div> -->
            <!-- <div class="text-center">{{ phoneData.connectionStatusText }}</div> -->
            <div v-if="phoneData.showKeypad" class="keypad select-none">
              <div class="keypad-button" @click="keypadPress('1')">1</div>
              <div class="keypad-button" @click="keypadPress('2')">2</div>
              <div class="keypad-button" @click="keypadPress('3')">3</div>
              <div class="keypad-button" @click="keypadPress('4')">4</div>
              <div class="keypad-button" @click="keypadPress('5')">5</div>
              <div class="keypad-button" @click="keypadPress('6')">6</div>
              <div class="keypad-button" @click="keypadPress('7')">7</div>
              <div class="keypad-button" @click="keypadPress('8')">8</div>
              <div class="keypad-button" @click="keypadPress('9')">9</div>
              <div class="keypad-button" @click="keypadPress('*')">*</div>
              <div class="keypad-button" @click="keypadPress('0')">0</div>
              <div class="keypad-button" @click="keypadPress('#')">#</div>
            </div>
            <!-- <div v-else>Show form</div> -->
            <div v-if="phoneData.onPhone" class="mt-3">
              <div class="phone-controls">
                <div class="in-call-controls-button" :class="{ 'in-call-controls-button-active': phoneData.isMuted }" @click="toggleMute()">
                  <Icon type="microphone-slash" />
                </div>
                <div
                  class="in-call-controls-button"
                  :class="{ 'in-call-controls-button-active': phoneData.showKeypad }"
                  @click="phoneData.showKeypad = !phoneData.showKeypad"
                >
                  <Icon type="braille" />
                </div>
                <!-- <div
                                class="in-call-controls-button"
                                :class="{ 'in-call-controls-button-active': phoneData.onHold }"
                                @click="placeOnHold"
                            >
                                <Icon type="pause" />
                            </div> -->
              </div>
              <button
                class="main-control-button mt-3"
                style="background-color: var(--bg-error-solid); color: var(--fg-white)"
                @click="hangup"
              >
                <Icon type="phone-hangup" />
              </button>
            </div>
            <div v-else class="phone-controls">
              <button
                v-if="phoneController.allowOutboundCalls.can"
                class="main-control-button"
                style="background-color: var(--bg-success-solid); color: var(--fg-white)"
                @click="startCall"
              >
                <Icon type="phone" />
              </button>
              <button
                v-else
                v-tippy="{ content: phoneController.allowOutboundCalls.reason }"
                class="main-control-button !cursor-not-allowed"
                style="background-color: var(--bg-success); color: var(--text-success)"
              >
                <Icon type="phone" />
              </button>
            </div>
          </div>
          <div class="phone-section phone-queue py-7 w-[300px]">
            <h5 v-if="callList.length === 0" class="h-full flex justify-center text-light text-sm">No active calls</h5>
            <ul class="overflow-y-auto h-full">
              <li
                v-for="call in callList"
                :key="call.parentCallSid"
                class="call-list-item flex items-center py-3 px-5 cursor-pointer"
                :class="{ 'cursor-pointer hover:text-primary': call.agentID === phoneData.agentID }"
                :style="[call.parentCallSid === selectedCall?.parentCallSid ? 'color: var(--fg-brand);' : '']"
                @click="selectCallToManage(call.parentCallSid)"
              >
                <div class="mr-5 w-full">
                  <div class="font-medium">
                    <div v-if="call.selectedAgreement">
                      {{ call.selectedAgreement.name }}
                    </div>
                    <div v-else-if="call.matchedCustomers && call.matchedCustomers.length > 0">{{ call.matchedCustomers[0].name }} ?</div>
                    <div v-else>Unknown</div>
                  </div>

                  <div class="text-xs text-secondary">{{ call.numberCalledDetails?.name }}</div>
                </div>
                <div class="flex items-center whitespace-nowrap text-right">
                  <div class="text-xs mr-2 text-secondary">
                    <Chip
                      v-if="call.status && !getStatus(call.status).isHidden"
                      v-tippy="getStatus(call.status).text"
                      :severity="getStatus(call.status).color"
                      size="sm"
                    >
                      <Icon :type="getStatus(call.status).icon" />
                      <!-- {{ getStatus(call.status).text }} -->
                    </Chip>

                    <!-- Timestamps -->
                    <div v-if="call.direction == 'inbound'">
                      <!-- <div>{{ call.status }}</div> -->
                      <div v-if="call.status == 'queued'" v-tippy="{ content: 'Time in Queue' }">
                        {{ elapsedTimes[call.parentCallSid]?.queued }}
                      </div>
                      <div v-else-if="call.status == 'in-progress'" v-tippy="{ content: 'Call Duration' }">
                        {{ elapsedTimes[call.parentCallSid]?.connected }}
                      </div>
                      <!-- <div>Intiated: {{ toReadableTime(call.timestampInitiated, 'HH:mm') }}</div> -->
                      <!-- <Icon v-tippy="{ content: 'test' }" type="info-circle" /> -->
                    </div>

                    <div v-if="getStatus(call.status).consideredComplete">
                      <div v-tippy="{ content: $dayjs(call.timestampHangup).format('DD-MM-YYYY HH:mm') }">
                        {{
                          formatSecondsToParts(
                            elapsedTimes[call.parentCallSid]?.hangup ?? elapsedTimes[call.parentCallSid]?.initiated,
                            1,
                            true
                          )
                        }}
                        <!-- {{ elapsedTimes[call.parentCallSid].hangup }} -->
                      </div>
                    </div>
                  </div>

                  <Button
                    v-if="call.agentID === null && call.wrappedUp === false"
                    v-tippy="{ content: 'Accept Inbound Call' }"
                    severity="success"
                    outlined
                    icon="check"
                    size="sm"
                    @click="dialQueue(call.queueID, call.parentCallSid)"
                  />
                  <!-- <Button
                  v-else
                  v-tippy="{ content: 'Wrap Up' }"
                  variant="outline-info"
                  :is-icon="true"
                  size="sm"
                  @click="selectCallToManage(call.parentCallSid)"
                >
                  <Icon type="arrow-turn-up" />
                </Button> -->
                </div>
              </li>
            </ul>
          </div>
          <div v-if="selectedCall" class="phone-section phone-sidebar w-[400px]">
            <div class="p-7">
              <Tabs
                v-model="sidebarSelectedTab"
                :tabs="sidebarTabs"
                type="pills-rail-block"
                :show-label="false"
                :show-tooltip="true"
                @tab-change="tabChange"
              ></Tabs>
              <div v-if="sidebarSelectedTab === 'customer'" class="mt-4">
                <div class="flex justify-between items-end mb-6">
                  <div>
                    <div class="text-secondary text-sm">From</div>
                    <div>{{ selectedCall.from }}</div>
                  </div>
                  <Icon type="arrow-right" />
                  <div class="text-right">
                    <div class="text-secondary text-sm">To</div>
                    <div>{{ selectedCall.to }}</div>
                  </div>
                </div>
                <FormGroup label="Possible Matches" info="Based on the phone number and previous communications">
                  <ul v-if="selectedCall?.matchedCustomers?.length">
                    <li v-for="(match, index) in selectedCall?.matchedCustomers" :key="index" class="flex">
                      <RadioButton
                        v-model="selectedCall.selectedAgreement"
                        :input-id="match.agreementID"
                        :value="match"
                        @change="updateSocketCall"
                      />
                      <label :for="match.agreementID" class="ml-2">{{ match.name }} [{{ match.vrm }}]</label>
                    </li>
                  </ul>
                </FormGroup>
                <FormGroup label="Selected Agreement" info="Manually find an agreement">
                  <Select
                    v-model="selectedCall.selectedAgreementId"
                    :options="agreementsList"
                    value-key="agreementId"
                    label-key="name"
                    searchable
                    :handle-search="false"
                    v-model:search-term="searchTerm"
                    class="px-2 min-w-64"
                    @select="linkAgreement"
                  >
                    <template #option="{ option }">{{ option.name }} [{{ option.vrm }}]</template>
                  </Select>
                </FormGroup>
                <router-link
                  v-if="selectedCall?.selectedAgreement"
                  :to="'/agreement/' + selectedCall?.selectedAgreement?.agreementId"
                  @click="minimizeToggle"
                >
                  <Button label="View Agreement" severity="secondary" outlined />
                </router-link>
              </div>
              <div v-if="sidebarSelectedTab === 'compliance'" class="mt-4">
                <div>
                  <Multiselect
                    v-model="selectedCall.wrapUp.compliance.applicability"
                    mode="single"
                    label="applicability"
                    :close-on-select="true"
                    :searchable="false"
                    :hide-selected="false"
                    :options="['Applicable', 'Not Applicable', 'Interrupted']"
                    :can-clear="false"
                    :can-deselect="false"
                    @select="updateSocketCall"
                    @deselect="updateSocketCall"
                  >
                  </Multiselect>
                </div>
                <div v-if="selectedCall.wrapUp.compliance.applicability != 'Not Applicable'">
                  <ul class="mt-4 flex flex-col gap-2">
                    <li>
                      <Checkbox
                        v-model="selectedCall.wrapUp.compliance.items.fullName"
                        :label="'Name: ' + getSelectedAgreementData('name')"
                        @change="calculateCompliance()"
                      ></Checkbox>
                    </li>
                    <li>
                      <Checkbox
                        v-model="selectedCall.wrapUp.compliance.items.postcode"
                        :label="'Postcode: ' + getSelectedAgreementData('addressPostCode')"
                        @change="calculateCompliance()"
                      ></Checkbox>
                    </li>
                    <li class="pl-3">
                      <Checkbox
                        v-model="selectedCall.wrapUp.compliance.items.term"
                        :label="'Term: ' + getSelectedAgreementData('term')"
                        @change="calculateCompliance()"
                      ></Checkbox>
                    </li>
                    <li class="pl-3">
                      <Checkbox
                        v-model="selectedCall.wrapUp.compliance.items.dob"
                        :label="'DOB: ' + getSelectedAgreementData('dateOfBirth')"
                        @change="calculateCompliance()"
                      ></Checkbox>
                    </li>
                    <li>
                      <Checkbox
                        v-model="selectedCall.wrapUp.compliance.items.startDate"
                        :label="'Start Date: ' + getSelectedAgreementData('startDate')"
                        @change="calculateCompliance()"
                      ></Checkbox>
                    </li>
                    <li>
                      <Checkbox
                        v-model="selectedCall.wrapUp.compliance.items.vrm"
                        :label="'Reg: ' + getSelectedAgreementData('vrm')"
                        @change="calculateCompliance()"
                      ></Checkbox>
                    </li>
                    <li>
                      <Checkbox
                        v-model="selectedCall.wrapUp.compliance.items.callingFrom"
                        label="Call on Behalf of"
                        @change="calculateCompliance()"
                      ></Checkbox>
                    </li>
                    <li>
                      <Checkbox
                        v-model="selectedCall.wrapUp.compliance.items.callRecorded"
                        label="Call Recorded"
                        @change="calculateCompliance()"
                      ></Checkbox>
                    </li>
                  </ul>
                  <div class="mt-4">
                    <span v-if="selectedCall.wrapUp.compliance.isCompliant">
                      <Icon :type="'circle-check'" :icon-style="'success'" /> Compliant
                    </span>
                    <span v-else><Icon :type="'circle-xmark'" :icon-style="'danger'" /> Not Compliant</span>
                    ({{ selectedCall.wrapUp.compliance.checkedItemsCount }}/6)
                  </div>
                </div>
              </div>
              <div v-if="sidebarSelectedTab === 'outcome'" class="mt-3">
                <FormGroup label="Reason">
                  <Multiselect
                    v-model="selectedCall.wrapUp.outcome.reason"
                    mode="tags"
                    label="agreement"
                    :close-on-select="false"
                    :searchable="true"
                    :hide-selected="true"
                    :options="['Finance Review', 'Lead', 'Appointment - Existing', 'Appointment - Follow-up', 'Other']"
                    :can-clear="true"
                    :can-deselect="true"
                    required
                    @select="updateSocketCall"
                    @deselect="updateSocketCall"
                  >
                  </Multiselect>
                </FormGroup>
                <FormGroup label="Answered By">
                  <Multiselect
                    v-model="selectedCall.amdOutcome"
                    mode="single"
                    label="label"
                    track-by="value"
                    :close-on-select="true"
                    :searchable="false"
                    :hide-selected="false"
                    :options="[
                      {
                        label: 'Customer',
                        value: 'human_manual'
                      },
                      {
                        label: 'Voicemail',
                        value: 'machine_start_manual'
                      },
                      {
                        label: 'N/A',
                        value: null
                      }
                    ]"
                    :can-clear="true"
                    :can-deselect="true"
                    @select="updateSocketCall"
                    @deselect="updateSocketCall"
                  >
                  </Multiselect>
                </FormGroup>
                <div v-if="selectedCall.amdOutcome">
                  <FormGroup label="Tags">
                    <Multiselect
                      v-model="selectedCall.wrapUp.outcome.tags"
                      mode="tags"
                      :close-on-select="false"
                      :searchable="true"
                      :hide-selected="true"
                      :options="[
                        'Left Message',
                        'Decided',
                        'Unaware of Options',
                        'Undecided',
                        'Confused',
                        'Dubious',
                        'Going Elsewhere',
                        'Disappointed',
                        'Complaint',
                        'Refused Data Protection',
                        'Booked Appointment',
                        'Appointment Query',
                        'Appointment Complaint',
                        'Inconvenient Time',
                        'Connection Issues',
                        'Incorrect Number',
                        'Call Failed',
                        'Phone System Error',
                        'Unable to Complete DPQ'
                      ]"
                      :create-option="true"
                      :can-clear="true"
                      :can-deselect="true"
                      @select="updateSocketCall"
                      @deselect="updateSocketCall"
                    >
                      <template #tag="{ option, handleTagRemove, disabled }">
                        <div
                          class="multiselect-tag"
                          :class="{
                            'is-disabled': disabled
                          }"
                        >
                          {{ option.value }}
                          <span v-if="!disabled" class="multiselect-tag-remove" @click="handleTagRemove(option, $event)">
                            <!-- <span class="multiselect-tag-remove-icon"></span> -->
                            <Icon type="xmark" />
                          </span>
                        </div>
                      </template>
                    </Multiselect>
                  </FormGroup>
                  <FormGroup v-if="selectedCall?.amdOutcome.includes('human')" label="Customer Emotion">
                    <Rating v-model="selectedCall.wrapUp.outcome.emotion" @update:model-value="updateSocketCall" />
                  </FormGroup>
                </div>
                <div>
                  <Button label="Complete Wrap-Up" class="w-full" @click="completeWrapUp()" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="phone-minimized-tab cursor-pointer px-7 py-4" @click="minimizeToggle">
          <div v-if="phoneData.onPhone" class="flex items-center">
            <!-- <Icon type="phone" class="text-success mr-3 text-lg" /> -->
            <div>
              <div class="font-bold">Call in progress</div>
              <!-- <div>{{ phoneData.connectionStatusText }}</div> -->
            </div>
          </div>
          <div v-else>
            <Icon type="phone" />
          </div>
        </div>
      </div>
      <div v-else class="phone-card w-fit p-4">
        <Button label="Initialize" icon="phone" class="w-full" />
      </div>
    </div>
  </div>
  <teleport to="#modals-container">
    <Modal
      :is-open="overrideLinkingModalIsOpen"
      title="No agreement linked!"
      :buttons="['ok', 'cancel']"
      close-text="Cancel"
      ok-text="Save"
      @close-modal=";(overrideLinkingModalIsOpen = false), (selectedCall.linkingOverrideReason = null)"
      @ok-modal="completeWrapUp"
    >
      <div>
        <Alert
          severity="warning"
          title="Not linking the call to a customer should only be done if you could not identify them."
          :show-close-button="false"
          :show-dismiss-button="false"
        />

        <FormGroup class="mt-5">
          <Label>Reason for Override</Label>
          <Multiselect
            v-model="selectedCall.linkingOverrideReason"
            mode="single"
            :close-on-select="true"
            :searchable="false"
            :hide-selected="false"
            :options="['Audio Issues', 'Spam Call', 'Refused to identify', 'Call failed', 'Other']"
            :can-clear="true"
            :can-deselect="true"
            @select="updateSocketCall"
            @deselect="updateSocketCall"
          >
          </Multiselect>
        </FormGroup>
      </div>
    </Modal>

    <Modal :is-open="infoModalIsOpen" title="Phone Info" :buttons="['cancel']" close-text="Close" @close-modal="infoModalIsOpen = false">
      <h2>You will receive inbound calls for these dealerships:</h2>
      <ul>
        <li v-for="dealership in dealershipsAllowed" :key="dealership.id">{{ dealership.name }}</li>
      </ul>
    </Modal>
  </teleport>
</template>

<script>
import { ref, reactive, inject, computed, watch, onMounted, onUnmounted } from 'vue'
import { useAbility } from '@casl/vue'

import Icon from '@/components/icon/Icon.vue'
import Chip from '@/components/chip/Chip.vue'
import Button from '../button/Button.vue'
import Tabs from '@/components/tabs/Tabs.vue'
import Multiselect from '@vueform/multiselect'
import FormGroup from '@/components/forms/FormGroup.vue'
import RadioButton from 'primevue/radiobutton'
import Rating from '@/components/forms/Rating.vue'
import Checkbox from '@/components/forms/Checkbox.vue'
import Select from '@/components/forms/Select.vue'
import Modal from '@/components/modal/Modal.vue'
import Alert from '@/components/alert/Alert.vue'

import { useUserStore } from '@/stores/UserStore'
import getUserStatus from '@/utilities/getUserStatus.js'

//TODO - Add validation to call outcome
// import useVuelidate from '@vuelidate/core'
// import { required } from '@vuelidate/validators'

import { MeiliSearch } from 'meilisearch'

import axios from 'axios'
import { Device } from '@twilio/voice-sdk'

import { calculateElapsedTime } from '@/utilities/timerUtil'
import { toReadableTime, formatSecondsToParts } from '@/utilities/timeFormatterUtil'

import { alertToast } from '@/utilities/notification'
import usePhoneValidator from '@/composables/usePhoneValidator'
import socket from '@/services/socket.service.js'

export default {
  components: { Icon, Chip, Button, Tabs, FormGroup, Checkbox, Multiselect, Select, Rating, RadioButton, Modal, Alert },
  setup() {
    const { can } = useAbility()

    const userStore = useUserStore() //PINIA

    let phoneData = ref({
      twilioToken: null,
      canInitialize: false,
      isInitialized: false,
      numberTo: '',
      numberFrom: '',
      onPhone: false,
      isMuted: false,
      showKeypad: true,
      onHold: false,
      connectionStatusText: '',
      connection: null,
      initiatedCallSid: null,
      agentID: userStore.details.id,
      warnings: []
    })

    const valNumberFrom = usePhoneValidator()
    const valNumberTo = usePhoneValidator()

    const inboundRingtoneIsMuted = ref(false)

    valNumberTo.validatePhoneNumber(phoneData.value.numberTo)
    valNumberFrom.validatePhoneNumber(phoneData.value.numberFrom)

    watch(
      () => phoneData.value.numberFrom,
      newNumberFrom => {
        // console.log('change in from!')
        valNumberFrom.validatePhoneNumber(newNumberFrom)
      }
    )
    watch(
      () => phoneData.value.numberTo,
      newNumberTo => {
        valNumberTo.validatePhoneNumber(newNumberTo)
      }
    )

    let device
    let call

    const sidebarTabs = ref([
      {
        id: 'customer',
        label: 'Customer',
        icon: 'far fa-user',
        isActive: true
      },
      {
        id: 'compliance',
        label: 'Compliance',
        icon: 'far fa-shield'
      },
      {
        id: 'outcome',
        label: 'Outcome',
        icon: 'far fa-circle-check'
      }
    ])
    const sidebarSelectedTab = ref('customer')
    function tabChange(tab) {
      // console.log('Tab: ' + tab.id)
      sidebarSelectedTab.value = tab.id
    }

    let userID = userStore.details.id
    const emitter = inject('emitter')

    let socketCalls = ref([])
    const unhandledInboundCallCount = ref(0)

    let selectedCall = ref(null)

    const audioInboundCall = new Audio(import.meta.env.VITE_CDN_BASE_URL + '/audio/calls-in-queue.mp3')

    // Method to find a call in socketCalls by callSid
    const findCall = callSid => {
      return socketCalls.value.find(call => call.parentCallSid === callSid)
    }

    // Method to partially update a call, preserving local changes
    const updateCall = (existingCall, newCallData) => {
      existingCall.agentID = newCallData.agentID
      existingCall.status = newCallData.status
      existingCall.amdOutcome = newCallData.amdOutcome
      existingCall.wrappedUp = newCallData.wrappedUp
      existingCall.timestampInitiated = newCallData.timestampInitiated
      existingCall.timestampQueued = newCallData.timestampQueued
      existingCall.timestampConnected = newCallData.timestampConnected
      existingCall.timestampHangup = newCallData.timestampHangup
      existingCall.selectedAgreement = newCallData.selectedAgreement
      existingCall.queueResult = newCallData.queueResult
      existingCall.queueTime = newCallData.queueTime
      existingCall.duration = newCallData.duration
      existingCall.recordingUrl = newCallData.recordingUrl
    }

    const viewAllowed = userStore.accessAllowed
    const dealershipIDs = viewAllowed.dealerships.map(dealership => dealership.id)
    const dealershipIDsString = dealershipIDs.join(',')

    const unhandledInboundCalls = ref([])

    const userPresence = computed(() => {
      let user = getUserStatus(userStore.details.id)
      return user
    })

    const allowInboundRingtone = ref(false)

    // watch for changes in userPresence
    watch(
      () => userPresence.value,
      newUserPresence => {
        allowInboundRingtone.value = newUserPresence.status === 'online' || newUserPresence.status === 'idle'
      }
    )

    // instead of playing the audio on phone-calls-all, create a watch for changes in unhandledInboundCalls
    watch(
      () => unhandledInboundCalls.value,
      newCalls => {
        unhandledInboundCallCount.value = newCalls.length

        if (phoneData.value.isInitialized && newCalls.length > 0 && allowInboundRingtone.value) {
          // console.log('audioInboundCall.play()')
          audioInboundCall.loop = true
          audioInboundCall.play()
        } else {
          // console.log('audioInboundCall.pause()')
          audioInboundCall.pause()
          audioInboundCall.currentTime = 0
        }
      }
    )

    socket.on('phone-calls-all', activeCalls => {
      // console.log('Active Calls: ' + JSON.stringify(activeCalls))

      // Remove any calls from dealerships which this user doesn't have access to
      activeCalls = activeCalls.filter(c => c.numberCalledDetails.dealerships.some(dealership => dealershipIDs.includes(dealership)))

      if (can('receive_calls')) {
        unhandledInboundCalls.value = activeCalls.filter(
          c =>
            c.agentID === null &&
            c.direction === 'inbound' &&
            c.status === 'queued' &&
            c.numberCalledDetails.dealerships.some(dealership => dealershipIDs.includes(dealership))
        )
      }

      socketCalls.value = activeCalls

      //Find the initiated call sid in the array of calls.
      //Only if present -> make it the active call

      if (phoneData.value.initiatedCallSid != null) {
        console.log('searching for initiated call sid: ' + phoneData.value.initiatedCallSid)
        let callFound = activeCalls.filter(c => c.parentCallSid === phoneData.value.initiatedCallSid)
        console.log('found: ' + callFound)
        if (callFound != '') {
          console.log('selecting call to manage')
          selectCallToManage(phoneData.value.initiatedCallSid)
        }
      }

      // New logic to update or add calls
      activeCalls.forEach(newCall => {
        let existingCall = findCall(newCall.parentCallSid)
        if (existingCall) {
          updateCall(existingCall, newCall)
        } else {
          socketCalls.value.push(newCall)
        }
      })
    })

    socket.on('phone-call-remove', callSid => {
      // console.log('Removing call: ' + callSid)
      socketCalls.value = socketCalls.value.filter(call => call.parentCallSid !== callSid)
    })

    //This is the array of calls which is displayed to the user.
    const callList = computed(() => {
      let calls = socketCalls.value.filter(
        c => (c.agentID === null && c.status === 'queued') || (c.agentID == userID && c.wrappedUp === false)
      )

      // Sort the calls by timestampInitiated Desc
      calls.sort((a, b) => {
        return new Date(b.timestampInitiated) - new Date(a.timestampInitiated)
      })

      // Are there multiple matched customers, or is this number unique to 1 customer?
      // console.log('CALLS ', calls.length)
      if (calls.length > 0) {
        //Loop through each call in the list and add extra data
        calls.forEach(call => {
          if (call.wrapUp === undefined) {
            call.wrapUp = {
              compliance: {
                items: {
                  fullName: false,
                  postcode: false,
                  term: false,
                  dob: false,
                  startDate: false,
                  vrm: false,
                  callRecorded: call.direction === 'inbound' ? true : false,
                  callingFrom: false
                },
                isCompliant: false,
                checkedItemsCount: call.direction === 'inbound' ? 1 : 0,
                applicability: 'Applicable'
              },
              outcome: {
                tags: null,
                emotion: null,
                reason: null,
                outcome: null
              }
            }
          }
        })
      }

      // console.log(JSON.stringify(calls))

      return calls
    })

    const elapsedTimes = reactive({})

    const updateElapsedTime = () => {
      for (const call of callList.value) {
        if (!elapsedTimes[call.parentCallSid]) {
          elapsedTimes[call.parentCallSid] = {}
        }
        elapsedTimes[call.parentCallSid].initiated = calculateElapsedTime(call.timestampInitiated, 'seconds')

        if (call.timestampQueued) {
          elapsedTimes[call.parentCallSid].queued = calculateElapsedTime(call.timestampQueued, 'formatted')
        }

        if (call.timestampConnected) {
          elapsedTimes[call.parentCallSid].connected = calculateElapsedTime(call.timestampConnected, 'formatted')
        }

        if (call.timestampHangup != null) {
          elapsedTimes[call.parentCallSid].hangup = calculateElapsedTime(call.timestampHangup, 'seconds')
        }
      }
    }

    let intervalId = null
    onMounted(() => {
      intervalId = setInterval(updateElapsedTime, 1000)
    })

    onUnmounted(() => {
      if (intervalId) {
        clearInterval(intervalId)
      }
    })

    function dialQueue(queueID, callSidBeingDequeued) {
      phoneData.value.numberFrom = ''
      phoneData.value.numberTo = queueID

      startCall()
      selectCallToManage(callSidBeingDequeued)
    }

    let isMinimized = ref(true)

    function minimizeToggle() {
      isMinimized.value = !isMinimized.value
    }

    let mouseOver = 0
    window.addEventListener('click', () => {
      if (mouseOver == 0 && phoneData.value.isInitialized == false) {
        initPhone()
        mouseOver = 1
      }
    })

    // Fetches a token from the API
    async function fetchToken() {
      // console.log('Fetching Twilio Token')

      let tokenUrl = import.meta.env.VITE_SOCKET_IO_SERVER_URL + '/twilio/voice/token?user_id=' + userID
      // console.log(tokenUrl)
      const response = await axios.get(tokenUrl, { withCredentials: false })

      // Checking if there's a token in the response
      if (!response.data || !response.data.token || response.data.token.length === 0) {
        throw new Error('No valid token found in the response.')
      }
      // console.log(response.data.token)

      return response.data.token
    }

    // Refresh token function
    async function refreshToken() {
      try {
        const token = await fetchToken()
        phoneData.value.twilioToken = token

        // Update the token on the device if it's initialized
        if (device && phoneData.value.isInitialized) {
          device.updateToken(token)
        }
      } catch (err) {
        console.error('Error in refreshing the token: ', err.message)
        phoneData.value.isInitialized = false // setting back to false if any error occurs
      }
    }

    // Device initialization function
    function initDevice(token) {
      // console.log('Initializing Twilio Device')
      const deviceOptions = {
        appName: 'Core V3',
        appVersion: import.meta.env.VITE_VERSION,
        // closeProtection: true,
        loglevel: 1
        // sounds: {
        //   incoming: 'https://assets.mixkit.co/sfx/preview/mixkit-uplifting-flute-notification-2317.mp3',
        //   outgoing: 'https://assets.mixkit.co/sfx/preview/mixkit-software-interface-start-2574.mp3',
        //   disconnect: 'https://assets.mixkit.co/sfx/preview/mixkit-software-interface-back-2575.mp3'
        // }
      }

      device = new Device(token, deviceOptions)

      // console.log('Device: ' + device)

      device.on('registered', function () {
        // console.log('Device Registered!')
        phoneData.value.isInitialized = true
      })

      device.register()

      device.on('error', (twilioError, call) => {
        console.log('TWILIO DEVICE ERROR', twilioError)

        if (twilioError.code === 20104) {
          // Error: Access Token has expired or expiration date is invalid
          // we should fetch a new token here
          refreshToken()
          return
        }

        //If any unhandled error, show notification to user
        alertToast('Twilio Error', twilioError.description, 'error')
      })

      device.on('tokenWillExpire', async () => {
        // console.warn('Twilio token is expiring')
        // await refreshToken()
      })

      device.on('incoming', call => {
        console.log('INCOMING CALL!', call)
      })
    }

    // Initial phone setup function
    async function initPhone() {
      try {
        const token = await fetchToken()
        phoneData.value.twilioToken = token
        initDevice(token)
      } catch (err) {
        console.error('Error in initializing the phone: ', err.message)
        phoneData.value.isInitialized = false // setting back to false if any error occurs
      }
    }

    function keypadPress(digit) {
      // console.log('Keypad Pressed: ' + digit)
      if (!phoneData.value.onPhone) {
        //If not on phone
        //Add digits to the number input
        phoneData.value.numberTo = phoneData.value.numberTo + digit
      } else {
        sendDTMF(digit)
      }
    }

    async function startCall() {
      // console.log('startCall()')
      //Check if user is online or not. If not, show alertToast() and return.
      if (!userStore.socketConnected) {
        alertToast('You are not online', 'You must be online to make a call', 'error')
        return
      }

      // console.log(valNumberTo.validatedData.value.number.e164)
      let number
      if (phoneData.value.numberTo.includes('Queue')) {
        //Dialling a queue
        number = phoneData.value.numberTo
      } else {
        // number = phoneData.value.numberTo
        number = valNumberTo.validatedData.value.number.e164
      }

      // Make an outgoing call
      call = await device.connect({
        params: {
          To: number,
          callCustomerFrom: phoneData.value.numberFrom,
          callCenterId: localStorage.getItem('callCenterSelected')
        }
      })
      // console.log(call)

      //Call Error event
      call.on('error', error => {
        console.log('TWILIO CALL ERROR')
        console.log(error)
        alertToast('Twilio Error: ' + error.code, error.description, 'error')
      })

      //Call Warning event
      call.on('warning', (warningName, warningData) => {
        //TODO create an array of errors. This should add to that array. The array should show on the phone window for the user.
        console.log('TWILIO CALL WARNING')
        console.log(warningName, warningData)
        phoneData.value.warnings?.push({ warningName: warningName, warningData: warningData })
        // alertToast('Twilio Error: ' + warningName, warningData.name, 'warning')
      })

      call.on('warning-cleared', function (warningName) {
        console.log('TWILIO CALL WARNING - CLEARED')
        phoneData.value.warnings = phoneData.value.warnings.filter(w => w.warningName !== warningName)
      })

      // For outgoing calls, the "accept" event is emitted when the Call's media session has finished setting up.
      call.on('accept', () => {
        // console.log('Calling: ' + number)
        // console.log(call.parameters)
        phoneData.value.initiatedCallSid = call.parameters.CallSid

        phoneData.value.onPhone = true
        phoneData.value.isMuted = false
        phoneData.value.showKeypad = false
        phoneData.value.connectionStatusText = 'connecting...'

        //Emit to socket.io to change status
        socket.emit('user-status', 'inCall')
      })
      // call.on('volume', (inputVolume, outputVolume) => {
      //   console.log(inputVolume, outputVolume)
      // })

      call.on('disconnect', () => {
        // console.log('Call Disconnected')
        resetPhone()
        phoneData.value.onPhone = false
        phoneData.value.initiatedCallSid = null
        socket.emit('user-status', 'wrapUp')
      })
    }

    function sendDTMF(digit) {
      call.sendDigits(digit)
    }

    function toggleMute() {
      call.mute(!call.isMuted())
      phoneData.value.isMuted = call.isMuted()
    }

    function resetPhone() {
      // console.log('resetPhone()')
      phoneData.value.onPhone = false
      phoneData.value.showKeypad = true
      phoneData.value.connectionStatusText = ''
      phoneData.value.numberFrom = ''
      phoneData.value.numberTo = ''

      selectedCall.value = null
    }

    function hangup() {
      // console.log('hangUp()')
      resetPhone()
      //Disconnect main call to customer via the API
      let jsonData = JSON.stringify(call.parameters)
      console.log(jsonData)
      try {
        axios
          .post(import.meta.env.VITE_API_BASE_URL + '/twilio/phone/outbound/premature-hangup.php', jsonData, { withCredentials: false })
          .then(response => {
            console.log(response.data)

            call.disconnect()
          })
      } catch (err) {
        if (err.response) {
          console.log(err.response.status)
          console.log(err.response.data)
        }
      }
    }

    const overrideLinkingModalIsOpen = ref(false)
    function openOverrideLinkingModal() {
      // console.log('Override Agreement Linking')
      overrideLinkingModalIsOpen.value = true
    }

    const infoModalIsOpen = ref(false)
    function openInfoModal() {
      // console.log('Info Modal')
      infoModalIsOpen.value = true
    }

    function linkAgreement() {
      // console.log('linkAgreement()')
      // console.log(selectedCall.value.selectedAgreementId)

      let selectedAgreement = selectedCall.value.selectedAgreementId
      let selectedAgreementObj = agreementsList.value.find(agreement => agreement.agreementId === selectedAgreement)

      selectedCall.value.selectedAgreement = selectedAgreementObj

      // console.log(selectedCall.value)

      updateSocketCall()
    }

    function updateSocketCall() {
      // console.log('updateSocketCall()')
      // console.log(JSON.stringify(selectedCall.value))
      socket.emit('phone-call-update-wrapup', selectedCall.value)
    }

    function selectCallToManage(callSID) {
      // console.log('Selected call to manage: ' + callSID)

      selectedCall.value = findCall(callSID)

      sidebarSelectedTab.value = 'customer'
    }

    function getSelectedAgreementData(key) {
      if (selectedCall.value.selectedAgreement == null) {
        return ''
      }
      let selectedAgreementObj = selectedCall.value.selectedAgreement

      return selectedAgreementObj[key] ?? ''
    }

    function calculateCompliance() {
      const managingCallCompliance = selectedCall.value?.wrapUp.compliance

      //Do the calculation for if the call is compliant or not
      let checkedItems = Object.values(managingCallCompliance.items).filter(item => item === true)
      managingCallCompliance.checkedItemsCount = checkedItems.length
      if (checkedItems.length >= 6) {
        managingCallCompliance.isCompliant = true
      } else {
        managingCallCompliance.isCompliant = false
      }

      // console.log(checkedItems, managingCallCompliance.isCompliant)

      //Update the server
      updateSocketCall()
    }

    async function completeWrapUp() {
      // console.log('completeWrapUp()')
      // console.log(selectedCall.value)

      if (selectedCall.value.selectedAgreement === null && selectedCall.value.linkingOverrideReason === null) {
        overrideLinkingModalIsOpen.value = true
        return
      }
      overrideLinkingModalIsOpen.value = false

      let jsonData = JSON.stringify(selectedCall.value)

      // TODO: change to use the useApiData composable (example below), instead of axios?
      //Update call via API
      // const { isLoading: agreementIsLoading, hasError: agreementHasError, data: agreementData, fetchData: agreementFetchData } = useApiData()
      // agreementFetchData({ endpoint: '/v1/agreements' }).then(data => {})

      try {
        axios
          .post(import.meta.env.VITE_API_BASE_URL + '/twilio/phone/complete-outcome.php', jsonData, { withCredentials: false })
          .then(response => {
            // console.log(response.data)

            if (response.data.response.status == 'error') {
              alertToast('Failed to wrap up', null, 'error')
              // console.log(response.data.response.status)
              // console.log(response.data.response.data)
            } else {
              alertToast('Wrapped Up', null, 'success')
              //Reset and emit to socket
              socket.emit('phone-call-complete-wrapup', selectedCall.value.parentCallSid)
              socket.emit('user-status', null)
              resetPhone()
            }
          })
      } catch (err) {
        if (err.response) {
          alertToast('Failed to wrap up', null, 'error')
          console.log(err.response.status)
          console.log(err.response.data)
        }
      }
    }

    window.addEventListener('beforeunload', function (e) {
      if (phoneData.value.onPhone) {
        e.preventDefault()
        e.returnValue = ''
      }
    })

    emitter.on('phone-set-numbers', data => {
      // *Listen* for event
      // console.log('Open phone and add the following data', `data: ${JSON.stringify(data)}`)

      isMinimized.value = false
      selectedCall.value = null
      phoneData.value.numberFrom = data.from.number
      phoneData.value.numberTo = data.to.number
    })

    emitter.on('phone-clear-numbers', () => {
      phoneData.value.numberFrom = ''
      phoneData.value.numberTo = ''
    })

    const searchTerm = ref('')
    const meiliSearchResults = ref([])
    const meilisearchClient = ref()

    onMounted(() => {
      meilisearchClient.value = new MeiliSearch({
        host: import.meta.env.VITE_MEILISEARCH_URL,
        apiKey: import.meta.env.VITE_MEILISEARCH_API_KEY
      })
    })

    const agreementsList = ref([])

    const search = async query => {
      if (query) {
        meiliSearchResults.value = await meilisearchClient.value.index('agreements').search(query, {
          filter: 'dealershipId IN [' + dealershipIDsString + ']'
          // attributesToHighlight: ['*'],
          // highlightPreTag: '<span class="search-results-highlight">',
          // highlightPostTag: '</span>'
        })
        agreementsList.value = meiliSearchResults.value.hits || []
        console.log(meiliSearchResults.value)
      } else {
        meiliSearchResults.value = []
      }
    }

    watch(searchTerm, newSearchTerm => {
      // console.log(newSearchTerm)
      //Carry out meilisearch
      search(newSearchTerm)
    })

    const callStatuses = [
      {
        status: 'queued',
        text: 'Queued',
        color: 'info',
        icon: 'users',
        consideredComplete: false,
        isHidden: true
      },
      {
        status: 'ringing',
        text: 'Ringing',
        color: 'info',
        icon: 'phone-volume',
        consideredComplete: false,
        isHidden: false
      },
      {
        status: 'in-progress',
        text: 'In Progress',
        color: 'success',
        icon: 'user',
        consideredComplete: false,
        isHidden: false
      },
      {
        status: 'completed',
        text: 'Completed',
        color: 'default',
        icon: 'check',
        consideredComplete: true,
        isHidden: true
      },
      {
        status: 'failed',
        text: 'Failed',
        color: 'danger',
        icon: 'xmark',
        consideredComplete: true,
        isHidden: false
      },
      {
        status: 'busy',
        text: 'Busy',
        color: 'danger',
        icon: 'phone-missed',
        consideredComplete: true,
        isHidden: false
      },
      {
        status: 'canceled',
        text: 'Cancelled',
        color: 'danger',
        icon: 'phone-slash',
        consideredComplete: true,
        isHidden: false
      },
      {
        status: 'no-answer',
        text: 'No Answer',
        color: 'danger',
        icon: 'phone-xmark',
        consideredComplete: true,
        isHidden: false
      }
    ]
    function getStatus(status) {
      const matchedStatus = callStatuses.find(obj => obj.status === status)
      return matchedStatus || { color: 'default', icon: 'question', text: 'Unknown' }
    }

    // TODO - move phone controllers to the below computed property (eg. canInitialize)
    const phoneController = computed(() => {
      let response = {
        allowOutboundCalls: {
          can: true,
          reason: null
        },
        allowInboundCalls: {
          can: true,
          reason: null
        }
      }

      // If numberto is invalid update response
      if (!valNumberTo.validatedData.value.valid) {
        response.allowOutboundCalls.can = false
        response.allowOutboundCalls.reason = 'Invalid To number'
        return response
      }
      // If numberFrom is invalid update response
      if (!valNumberFrom.validatedData.value.valid) {
        response.allowOutboundCalls.can = false
        response.allowOutboundCalls.reason = 'Invalid From number'
        return response
      }
      // if callList.length <= 2 update response
      if (callList.value.length >= 2) {
        response.allowOutboundCalls.can = false
        response.allowOutboundCalls.reason = 'Wrap up your calls first'
        return response
      }

      return response
    })

    const callsWaitingOverTwoMinutes = computed(() => {
      return callList.value.filter(call => call.queueTime > 120)
    })

    return {
      can,
      isMinimized,
      initPhone,
      inboundRingtoneIsMuted,
      minimizeToggle,
      infoModalIsOpen,
      openInfoModal,
      dealershipsAllowed: viewAllowed.dealerships,
      callList,
      socketCalls,
      dialQueue,
      phoneData,
      phoneController,
      valNumberFrom,
      valNumberTo,
      keypadPress,
      startCall,
      toggleMute,
      hangup,
      sidebarTabs,
      sidebarSelectedTab,
      tabChange,
      selectCallToManage,
      selectedCall,
      getSelectedAgreementData,
      calculateCompliance,
      completeWrapUp,
      updateSocketCall,
      overrideLinkingModalIsOpen,
      openOverrideLinkingModal,
      searchTerm,
      meiliSearchResults,
      agreementsList,
      unhandledInboundCallCount,
      toReadableTime,
      formatSecondsToParts,
      elapsedTimes,
      getStatus,
      userStore,
      callsWaitingOverTwoMinutes,
      audioInboundCall,
      unhandledInboundCalls,
      userPresence,
      allowInboundRingtone,
      linkAgreement
    }
  }
}
</script>

<style>
.phone-wrapper {
  position: fixed;
  bottom: 0;
  display: flex;
  z-index: 115;
}
.phone-card {
  background-color: var(--bg-primary_alt);
  box-shadow: var(--shadow-mdPhone);
  border-radius: var(--rounded-md) var(--rounded-md) 0 0;
  z-index: 9;
  border: 1px solid var(--border-color);
}
.phone-window-buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -15px;
  right: -15px;
  gap: var(--s-4);
}
.phone-window-button {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--bg-primary_alt);
  box-shadow: 0 0px 8px 2px rgb(34 41 47 / 10%);
  border-radius: var(--rounded-md);
  cursor: pointer;
  border: 1px solid var(--border-color);
}

.phone-window-button:hover {
  background-color: var(--fg-brand);
  color: white;
}

.phone-section {
  height: 470px;
  border-left: 1px solid var(--border-secondary);
}

.call-list-item {
  position: relative;
  list-style-type: none;
  border-bottom: 1px solid var(--border-secondary);
}
.call-list-item:last-child {
  border-bottom: none;
}

.phone-card-active-call {
  border-bottom: 5px solid var(--bg-success-solid);
}

.phone-dialler-input {
  background-color: transparent;
}

.phone-dialler .keypad {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(4, 1fr);
  grid-column-gap: 4px;
  grid-row-gap: 6px;
  margin: 20px auto;
}

.phone-dialler .keypad-button {
  background-color: var(--bg-tertiary);
  border-radius: 100px;
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}

.phone-dialler .keypad-button:hover {
  background: var(--bg-quaternary);
  cursor: pointer;
}

.phone-controls {
  display: flex;
  margin-bottom: 10px;
}

.in-call-controls-button {
  border: 1px solid grey;
  border-radius: 100px;
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}

.in-call-controls-button:hover,
.in-call-controls-button-active {
  border-color: var(--fg-brand);
  color: var(--fg-brand);
  cursor: pointer;
}

.main-control-button {
  border-radius: 100px;
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  cursor: pointer;
}

.multiselect-tags > .div {
  background-color: var(--fg-brand);
  padding: 1px 2px;
}

@keyframes phoneCardPulsePurple {
  0% {
    background-color: rgba(128, 0, 128, 0);
  }
  50% {
    background-color: #7b5fd2;
    color: white;
  }
  100% {
    background-color: rgba(128, 0, 128, 0);
  }
}
.phone-card.has-unhandled-calls .phone-minimized-tab {
  border-radius: var(--rounded-md) var(--rounded-md) 0 0;
  animation: phoneCardPulsePurple 1.5s infinite;
}

@keyframes phoneCardPulseRed {
  0% {
    background-color: rgba(128, 0, 128, 0);
  }
  50% {
    background-color: var(--danger);
    color: white;
  }
  100% {
    background-color: rgba(128, 0, 128, 0);
  }
}

@keyframes rippleEffect {
  0% {
    transform: scale(1.2);
    opacity: 0;
  }
  50% {
    transform: scale(0.8);
    opacity: 1;
  }
  100% {
    transform: scale(1.2);
    opacity: 0;
  }
}

.phone-card.has-urgent-unhandled-calls .phone-minimized-tab {
  border-radius: var(--rounded-md) var(--rounded-md) 0 0;
  position: relative;
  overflow: hidden;
  animation: phoneCardPulseRed 1s infinite;
}

.phone-card.has-urgent-unhandled-calls::before {
  content: '';
  position: absolute;
  top: -100%;
  left: -50%;
  width: 200px;
  height: 200px;
  background-color: rgba(var(--danger-rgb), 0.2);
  border-radius: 50%;
  transform: translate(-50%, -50%);
  animation: rippleEffect 1s infinite;
  z-index: -1;
}
</style>
