import { reactive, toRefs } from 'vue'
import axios from 'axios'
import { alertToast } from '@/utilities/notification'
import * as Sentry from '@sentry/vue'

/**
 * @typedef {Object} ApiRequestConfig
 * @property {string} [baseURL=import.meta.env.VITE_API_BASE_URL]
 * @property {string} endpoint
 * @property {string} [method='GET']
 * @property {Object} [params={}]
 * @property {Object|null} [data=null]
 * @property {Object} [headers={}]
 */

/**
 * @typedef {Object} ApiResponse
 * @property {any} data
 * @property {boolean} success
 * @property {Array<string>} [errors]
 * @property {string} [message]
 * @property {number} [status]
 * @property {Object} http
 */

/**
 * @typedef {Object} ApiError
 * @property {boolean} hasError
 * @property {string} message
 * @property {Object|null} http
 */

export default function useApiRequest() {
  const state = reactive({
    response: null,
    data: null,
    isLoading: false,
    error: null
  })

  const send = async ({
    baseURL = import.meta.env.VITE_API_BASE_URL,
    endpoint,
    method = 'GET',
    params = {},
    data = null,
    headers = {}
  }) => {
    if (!baseURL) {
      console.error(`Error: baseURL is undefined. Endpoint: ${endpoint}`)
      alertToast('Error', `baseURL is undefined for endpoint: ${endpoint}`, 'error')
      state.error = `baseURL is undefined for endpoint: ${endpoint}`
      return Promise.reject({ hasError: true, message: state.error })
    }

    state.isLoading = true

    try {
      const response = await axios({
        baseURL,
        url: endpoint,
        method,
        params,
        data,
        withCredentials: true,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          ...headers
        }
      })

      if (!response || !response.status) {
        state.error = 'No response received from the API'
        throw { code: 'NO_RESPONSE', message: `No response from ${endpoint}`, response }
      }

      if (response.status >= 400) {
        // For 4xx or 5xx errors, provide more detail
        state.error = response.statusText || `HTTP Error ${response.status}`
        throw {
          code: response.status,
          message: state.error,
          response
        }
      }

      if (response.data?.response?.status === 'error') {
        state.error = response.data.response.message || 'Unknown error response'
        alertToast('Error', state.error, 'error')
        throw {
          code: response.status,
          message: state.error,
          response
        }
      }

      if (response.data.errors && response.data.errors.length > 0) {
        // Not necessarily a throw, but we record errors
        state.error = response.data.errors.join(', ')
      }

      state.response = response
      state.data = response.data.data || response.data

      let success = false
      if (response.data?.response?.status === 'success') {
        success = true
      } else {
        success = typeof state.response.success !== 'undefined' ? state.response.success : true
      }

      return {
        data: state.data,
        success,
        errors: state.response.errors,
        message: state.response.message,
        status: state.response.status,
        http: state.response
      }
    } catch (err) {
      // Display user-facing notification
      alertToast('Error (API Request)', err.message || 'Unknown error', 'error')

      // Capture full details in Sentry for debugging
      Sentry.captureException(err, {
        contexts: {
          requestDetails: {
            method,
            endpoint,
            params,
            headers,
            data: data ? tryGetDataTypes(data) : null
          },
          responseDetails: {
            status: err?.response?.status,
            statusText: err?.response?.statusText,
            responseData: err?.response?.data
          }
        }
      })

      state.error = err.message

      return Promise.reject({
        hasError: true,
        message: err.message,
        http: err.response || null
      })
    } finally {
      state.isLoading = false
    }
  }

  function tryGetDataTypes(data) {
    try {
      const parsed = typeof data === 'string' ? JSON.parse(data) : data
      return getDataTypes(parsed)
    } catch (e) {
      // If data isn't valid JSON or can't be parsed, just return null or a safe fallback
      return null
    }
  }

  function getDataTypes(obj) {
    let formattedObj = Object.keys(obj).reduce((acc, key) => {
      const value = obj[key]
      const type = typeof value

      if (type === 'object' && value !== null) {
        acc[key] = getDataTypes(value)
      } else {
        acc[key] = type
      }

      return acc
    }, {})
    return JSON.stringify(formattedObj)
  }

  const hasErrors = () => !!state.error

  return {
    ...toRefs(state),
    send,
    hasErrors
  }
}
