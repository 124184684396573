import { io } from 'socket.io-client'
import { ref } from 'vue'

import * as Sentry from '@sentry/vue'

import { useUserStore } from '@/stores/UserStore'

import emitter from '@/utilities/eventBus'
import dayjs from 'dayjs'

import { notificationToast, alertToast } from '@/utilities/notification'

import ability from '@/config/ability.js'

// console.log('Running Socket Service File')
// console.log('Socket Server: ' + import.meta.env.VITE_SOCKET_IO_SERVER_URL)

// const socket = io("http://localhost:30000", { transports: ["websocket"] }) //INFO: use this for dev server testing
const socket = io(import.meta.env.VITE_SOCKET_IO_SERVER_URL, {
  transports: ['websocket'],
  reconnection: true,
  reconnectionDelay: 1000,
  reconnectionDelayMax: 5000,
  reconnectionAttempts: 50
})

let usersPresence = ref([])
let socketIsConnected = ref(false)

socket.on('connect', () => {
  // console.log(`Socket IO Connected! SocketID: ${socket.id}`)

  const userStore = useUserStore()

  //Update socket status in user store
  userStore.updateSocketStatus(socket.connected)

  // If is reconnecting
  if (!socketIsConnected.value) {
    userStore.registerUserSocket()

    // emit user-update
    let dealershipIds = userStore.accessAllowed.dealerships.map(dealership => dealership.id)

    socket.emit('user-update', {
      uid: userStore.details.id,
      ut: userStore.details.type,
      dealershipIds,
      canMakeCalls: ability.can('make_calls'),
      canReceiveCalls: ability.can('receive_calls')
    })
  }

  socketIsConnected.value = socket.connected

  //Listen to the socket.io heartbeat for debugging
  // socket.io.engine.on('heartbeat', function () {
  //   console.log('heartbeat')
  // })

  socket.on('users-all', users => {
    // console.log('User Presence EMITTED from server')
    // console.log(users)

    usersPresence.value = users
    // console.log('SERVICE: ' + usersPresence.value)
    userStore.updateAllUsers(usersPresence.value)
  })

  socket.on('user-updated', user => {
    // console.log('User Updated EMITTED from server')
    // console.log(user)
    userStore.updateSingleUser(user)
  })

  socket.on('user-clear-localstorage', () => {
    // console.log('User Clear Local Storage request received')

    localStorage.clear()
    window.location.reload()
  })

  socket.on('user-signout', () => {
    // console.log('User SignOut request received')

    userStore.signOut()
  })

  socket.on('new-notification', notification => {
    console.log('New Notification received')

    // map the notification coming in
    if (notification?.notificationId) {
      console.log('notification', notification.data)

      switch (notification.notificationId) {
        case 1:
          Object.assign(notification, {
            design: 'info',
            title: 'New Appointment',
            text: `Booked for ${notification.data.customerName} at ${dayjs(notification.data.dateTime).format('ll')}`,
            severity: 'info',
            icon: 'calendar-plus',
            showActionButton: true,
            actionButtonLink: `/agreement/${notification.data.agreementId}/appointments/${notification.data.appointmentId}`
          })
          break
        case 2:
          Object.assign(notification, {
            design: 'info',
            title: 'Appointment Updated',
            text: `Appointment for ${notification.data.customerName} has changed`,
            severity: 'info',
            icon: 'calendar-pen',
            showActionButton: true,
            actionButtonLink: `/agreement/${notification.data.agreementId}/appointments/${notification.data.appointmentId}`
          })
          break
        case 3:
          Object.assign(notification, {
            design: 'info',
            title: 'Appointment Cancelled',
            text: `${notification.data.customerName} cancelled their appointment`,
            severity: 'danger',
            icon: 'calendar-times',
            showActionButton: true,
            actionButtonLink: `/agreement/${notification.data.agreementId}/appointments/${notification.data.appointmentId}`
          })
          break
        case 4:
          Object.assign(notification, {
            design: 'info',
            title: 'New Appointment Assigned',
            text: `You will be seeing ${notification.data.customerName} at ${dayjs(notification.data.dateTime).format('LT')} on ${dayjs(notification.data.dateTime).format('ll')}`,
            severity: 'info',
            icon: 'calendar-circle-user',
            showActionButton: true,
            actionButtonLink: `/agreement/${notification.data.agreementId}/appointments/${notification.data.appointmentId}`
          })
          break
        case 14:
          // Assuming the second case should have a different ID, e.g., 15
          Object.assign(notification, {
            design: 'info',
            title: 'New Valuation',
            text: `${notification.data.customerName} requires their vehicle to be valued`,
            severity: 'info',
            icon: 'car',
            showActionButton: true,
            actionButtonLink: `/vehicles/valuations/${notification.data.valuationId}`
          })
          break
        case 15: // Changed from 14 to 15 to avoid duplication
          Object.assign(notification, {
            design: 'info',
            title: 'New ReAuction',
            text: `A new vehicle is up for auction`,
            severity: 'secondary',
            icon: 'car',
            showActionButton: true,
            actionButtonLink: `/vehicles/reauction/${notification.data.valuationId}`
          })
          break
        case 21:
          Object.assign(notification, {
            design: 'info',
            title: 'Appointment Cancelled',
            text: `${notification.data.customerName} cancelled their appointment`,
            severity: 'danger',
            icon: 'calendar-times',
            showActionButton: true,
            actionButtonLink: `/agreement/${notification.data.agreementId}/appointments/${notification.data.appointmentId}`
          })
          break
        default:
          break
      }

      notification.onActionClick = () => {
        emitter.emit('routeTo', notification.actionButtonLink)
      }
    }
    console.log('notification', notification)

    notificationToast(notification, notification.onActionClick)
  })
})

socket.on('disconnect', reason => {
  console.log(`Socket IO disconnected! Reason: ${reason}`)
  // Sentry.captureMessage(`Socket IO disconnected! Reason: ${reason}`, 'debug')

  const userStore = useUserStore()

  //Remove all users from user store
  userStore.removeAllUsers()

  // Update socket status in user store
  userStore.updateSocketStatus(socket.connected)
  socketIsConnected.value = socket.connected

  if (userStore.details.type === 1) {
    alertToast('Disconnected', 'Reconnecting...', 'info')
  } else {
    // alertToast('Disconnected', 'Please refresh the page', 'info')
  }
})

export default socket
