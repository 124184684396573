<template>
  <PageHeader
    :title="$t('ui.entities.dashboards.call-center', 2)"
    breadcrumb
    :items="[{ label: $t('ui.entities.setting', 2), to: '/settings' }, { label: $t('ui.entities.departments.call-center', 2) }]"
  >
  </PageHeader>

  <Card>
    <DataTable
      ref="dt"
      v-model:filters="filters"
      export-filename="CallCenters"
      :value="callCenters"
      :loading="isLoading"
      removable-sort
      sort-field="id"
      :sort-order="-1"
      :rows="20"
      data-key="id"
      column-resize-mode="fit"
      :paginator="true"
      responsive-layout="scroll"
      filter-display="menu"
      :rows-per-page-options="[10, 20, 50]"
      state-key="dt-settings-call-centers"
      state-storage="session"
      current-page-report-template="Showing {startRecord}-{endRecord} out of {totalRecords}"
      :global-filter-fields="['id', 'name']"
    >
      <template #empty> No records found </template>
      <template #header>
        <div class="flex justify-between">
          <span class="flex justify-between gap-4">
            <span>
              <Input v-model="filters['global'].value" :placeholder="$t('ui.common.search', 1)" icon="search" />
            </span>
            <Button
              :label="$t('ui.actions.clear', 1)"
              severity="secondary"
              type="button"
              outlined
              icon="filter-slash"
              @click="clearFilters()"
            />
          </span>
        </div>
      </template>

      <Column field="id" header="ID" :sortable="true">
        <template #body="{ data }">
          <div>{{ data.id }}</div>
        </template>
      </Column>

      <Column field="name" header="Name" :sortable="true">
        <template #body="{ data }">
          <router-link :to="'/settings/call-centers/' + data.id" class="link">{{ data.name }}</router-link>
        </template>
      </Column>
    </DataTable>
  </Card>
</template>

<script setup>
import { ref } from 'vue'

import PageHeader from '@/components/page/PageHeader.vue'
import Card from '@/components/card/Card.vue'
import Button from '@/components/button/Button.vue'
import Input from '@/components/forms/Input.vue'

import useApiRequest from '@/composables/useApiRequest'
import { alertToast } from '@/utilities/notification'

import { FilterMatchMode } from 'primevue/api'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import _toUpper from 'lodash/toUpper'

const callCenters = ref(null)
const callCentersApiRequest = useApiRequest()
const isLoading = callCentersApiRequest.isLoading

const filters = ref([])

function initFilters() {
  filters.value = {
    global: { value: null, matchMode: FilterMatchMode.CONTAINS }
  }
}
initFilters()

function clearFilters() {
  //remove from session storage
  sessionStorage.removeItem('dt-settings-call-centers')
  initFilters()
}

function fetchCallCenters() {
  try {
    callCentersApiRequest
      .send({
        endpoint: '/v1/call-centers'
      })
      .then(response => {
        console.log(response)
        if (response.success) {
          callCenters.value = response.data
        } else {
          alertToast('Error', 'Failed to fetch call centers', response.message, 'error')
        }
      })
  } catch (err) {
    alertToast('Error', 'Failed to fetch call centers', err.message, 'error')
  }
}
fetchCallCenters()
</script>

<style></style>
