<template>
  <PageHeader
    :title="$t('ui.entities.status-timeline')"
    breadcrumb
    :items="[
      { label: $t('ui.entities.report.label', 2), to: '/reports' },
      { label: $t('ui.entities.user.role.call-agent', 2) },
      { label: $t('ui.entities.status-timeline') }
    ]"
  >
    <template #right>
      <div class="flex gap-3 items-center">
        <Select
          v-model="callCenterSelected"
          :options="callCentersAllowed"
          icon="headset"
          value-key="id"
          label-key="name"
          :placeholder="`Select a ${$t('ui.entities.departments.call-centre')}`"
          class="max-w-[250px]"
          :readonly="callCentersAllowed.length == 1"
          @select="updateCallCenterSelected"
        >
        </Select>

        <DatePicker v-model="dateSelected" position="bottom-right" :max-date="new Date()" :number-of-months="1" />
        <div>
          <Button label="Update" :is-loading="isLoading" @click="onFetchData" />
        </div>
      </div>
    </template>
  </PageHeader>

  <Card v-if="data.length">
    <CardBody>
      <div class="flex justify-end">
        <div v-tippy="'Timeline Scale'" class="border border-primary px-6 py-2 rounded flex h-10 items-center">
          <Slider v-model="timelineScale" :min="1" :max="10" class="w-32" />
        </div>
      </div>
    </CardBody>
    <Separator />
    <div class="flex overflow-hidden">
      <div class="w-[250px] border-r border-secondary">
        <div v-for="user in data" :key="user.id" class="h-[60px] border-b border-secondary flex items-center p-4">
          <AvatarLabelGroup :label="user.forename + ' ' + user.surname" :avatar-url="user.profilePicUrl" :avatar-text="user.initials" />
        </div>
      </div>
      <div class="w-full overflow-x-auto">
        <div v-for="user in data" :key="user.id" class="h-[60px] w-full">
          <ActivityTimeline v-model="user.periods" v-model:scale="timelineScale" :show-times="true" start-time="08:00" end-time="21:00" />
        </div>
      </div>
    </div>
  </Card>
  <Card v-else-if="!isLoading" class="h-[70vh] flex items-center justify-center">
    <Empty :title="callCenterSelected ? 'No data' : `Select a ${$t('ui.entities.departments.call-centre')}`" />
  </Card>
  <div v-else class="flex gap-4">
    <Skeleton width="18rem" height="36rem" />
    <Skeleton width="100%" height="36rem" />
  </div>
</template>

<script>
import { ref } from 'vue'
import PageHeader from '@/components/page/PageHeader.vue'
import Card from '@/components/card/Card.vue'
import DatePicker from '@/components/date-picker/DatePicker.vue'
import AvatarLabelGroup from '@/components/avatar/AvatarLabelGroup.vue'
import Label from '@/components/forms/Label.vue'
import Empty from '@/components/empty/Empty.vue'
import Select from '@/components/forms/Select.vue'
import Icon from '@/components/icon/Icon.vue'
import Skeleton from 'primevue/skeleton'
import Button from '@/components/button/Button.vue'
import CardBody from '@/components/card/CardBody.vue'
import Separator from '@/components/separator/Separator.vue'
import { useUserStore } from '@/stores/UserStore'

import Slider from 'primevue/slider'

import useApiRequest from '@/composables/useApiRequest'

import dayjs from 'dayjs'

import ActivityTimeline from '@/components/user-activity/UserActivityTimeline.vue'

export default {
  components: {
    PageHeader,
    Card,
    ActivityTimeline,
    DatePicker,
    AvatarLabelGroup,
    Label,
    Slider,
    Select,
    Empty,
    Icon,
    Skeleton,
    Button,
    CardBody,
    Separator
  },
  setup() {
    const dateSelected = ref(dayjs().format('YYYY-MM-DD'))
    const data = ref([])
    const userStore = useUserStore()

    const timelineScale = ref(3)

    const apiRequest = useApiRequest()
    const isLoading = apiRequest.isLoading

    const callCentersAllowed = userStore.callCenters
    const callCenterSelected = ref(null)

    function getData() {
      apiRequest
        .send({
          endpoint: '/v1/users/activity/timelines',
          params: { callCenterId: callCenterSelected.value, date: dayjs(dateSelected.value).format('YYYY-MM-DD') }
        })
        .then(response => {
          console.log(response)
          console.log(response.status)
          if (response.success) {
            data.value = response.data
          }
        })
    }

    if (!localStorage.getItem('report-filter-call-center-selected')) {
      callCenterSelected.value = callCentersAllowed[0].id
    } else {
      callCenterSelected.value = parseInt(localStorage.getItem('report-filter-call-center-selected'))
    }

    getData()

    function onFetchData() {
      if (dateSelected.value && callCenterSelected.value) {
        getData()
      }
    }

    function updateCallCenterSelected() {
      localStorage.setItem('report-filter-call-center-selected', callCenterSelected.value)
    }

    return { data, timelineScale, dateSelected, isLoading, onFetchData, callCentersAllowed, callCenterSelected, updateCallCenterSelected }
  }
}
</script>

<style></style>
