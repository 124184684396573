<template>
  <div class="p-4">
    <Card class="max-w-[500px]">
      <Button label="click me" @click="sendTestRequest" />
    </Card>
  </div>
</template>

<script setup>
import Card from '@/components/card/Card.vue'
import Button from '@/components/button/Button.vue'
import useApiRequest from '@/composables/useApiRequest'

const testRequest = useApiRequest()

function sendTestRequest() {
  try {
    testRequest.send({ method: 'GET', endpoint: '/http-code-test.php?code=404' }).then(response => {
      console.log(response)
    })
  } catch (err) {
    console.log('err', err)
  }
}
</script>

<style></style>
