<template>
  <DataTable
    ref="dt"
    v-model:selection="selectedDealerships"
    v-model:filters="filters"
    export-filename="Dealerships"
    :value="modelValue"
    :loading="isLoading"
    removable-sort
    sort-field="id"
    :sort-order="-1"
    :rows="20"
    data-key="id"
    column-resize-mode="fit"
    :paginator="true"
    responsive-layout="scroll"
    filter-display="menu"
    :rows-per-page-options="[10, 20, 50]"
    state-key="dt-settings-dealerships"
    state-storage="session"
    current-page-report-template="Showing {startRecord}-{endRecord} out of {totalRecords}"
    :global-filter-fields="['id', 'displayName', 'groupName', 'name', 'assignedPhoneNumber.number']"
  >
    <template #empty> No records found </template>
    <template #header>
      <div class="flex justify-between">
        <span class="flex justify-between gap-4">
          <span>
            <Input v-model="filters['global'].value" :placeholder="$t('ui.common.search', 1)" icon="search" />
          </span>
          <Button
            :label="$t('ui.actions.clear', 1)"
            severity="secondary"
            type="button"
            outlined
            icon="filter-slash"
            @click="clearFilter()"
          />
        </span>
      </div>
    </template>

    <!-- <Column selection-mode="multiple" header-style="width: 3em"></Column> -->

    <Column field="id" header="ID" :sortable="true">
      <template #body="{ data }">
        {{ data.id }}
      </template>
    </Column>

    <Column field="displayName" header="Name" :sortable="true">
      <template #body="{ data }">
        <AvatarLabelGroup
          :label="data.displayName"
          :description="data.groupName"
          :avatar-url="data.manufacturerLogo"
          avatar-is-logo
          :label-link="'/settings/dealerships/' + data.id"
          class="whitespace-nowrap"
        />
      </template>
    </Column>

    <Column field="callCenters" header="Call Centers" :sortable="true">
      <template #body="{ data }">
        <Chip
          v-for="(item, index) in callCenterChips(data)"
          :key="index"
          v-bind="item"
          class="link-hover"
          @click="$router.push('/settings/call-centers/' + item.id)"
        />
      </template>
    </Column>

    <Column field="billingIsActive" header="Billing Status" :sortable="true">
      <template #body="{ data }">
        <Chip v-if="data.billingIsActive" severity="success" :rounded="false" size="sm">Active</Chip>
        <Chip v-else :rounded="false" size="sm">Disabled</Chip>
      </template>
    </Column>

    <Column field="isActive" header="Status" :sortable="true">
      <template #body="{ data }">
        <ToggleSwitch v-if="$can('manage_dealerships')" v-model="data.isActive" @change="val => toggleIsActive(val, data.id)" />
        <Chip v-else-if="data.isActive" severity="success" :rounded="false" size="sm">Active</Chip>
        <Chip v-else :rounded="false" size="sm">Disabled</Chip>
      </template>
    </Column>

    <Column header="" :sortable="false">
      <template #body="slotProps">
        <div class="flex">
          <Button
            v-tippy="$t('ui.entities.setting', 2)"
            icon="cog"
            plain
            severity="secondary"
            @click="$router.push('/settings/dealerships/' + slotProps.data.id)"
          ></Button>
        </div>
      </template>
    </Column>
  </DataTable>
</template>

<script>
import Button from '@/components/button/Button.vue'
import Input from '@/components/forms/Input.vue'
import AvatarLabelGroup from '@/components/avatar/AvatarLabelGroup.vue'
import ToggleSwitch from '@/components/forms/ToggleSwitch.vue'
import Chip from '@/components/chip/Chip.vue'
import ChipList from '@/components/chip/ChipList.vue'

import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import { FilterMatchMode } from 'primevue/api'
import useApiRequest from '@/composables/useApiRequest'
import { alertToast } from '@/utilities/notification'

import { ref, computed } from 'vue'

export default {
  components: {
    Input,
    Button,
    DataTable,
    Column,
    AvatarLabelGroup,
    ToggleSwitch,
    Chip
  },
  props: {
    modelValue: {
      type: Array,
      required: true
    },
    isLoading: {
      type: Boolean,
      required: true
    }
  },
  emits: ['dealership-updated'],
  setup(props) {
    const dt = ref({ processedData: [] })
    const dealershipApiRequest = useApiRequest()
    const accessModalIsOpen = ref(false)

    const selectedDealerships = ref([])
    const onRowSelect = event => {
      console.log('Selected ' + event.data.name)
    }

    const onRowUnselect = event => {
      console.log('Unselected ' + event.data.name)
    }

    const filters = ref({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      rolesNamed: { value: null, matchMode: FilterMatchMode.IN }
    })

    const clearFilter = () => {
      // console.log('Clearing Filter')
      filters.value = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        rolesNamed: { value: null, matchMode: FilterMatchMode.IN }
      }
    }

    const dealershipBeingActioned = ref({
      id: null
    })

    function toggleIsActive(val, id) {
      dealershipApiRequest
        .send({
          endpoint: '/v1/dealerships/' + id,
          method: 'PATCH',
          data: {
            isActive: val
          }
        })
        .then(response => {
          if (!response.success) {
            alertToast('Dealership could not be updated', response.data.message, 'error')
            // Set value to what it was before toggle
            let dealership = props.modelValue.find(d => d.id == id)
            dealership.isActive = !val
          }
        })
    }

    // Call center chips
    const callCenterChips = data => {
      return data.callCenters.map(callCenter => {
        return {
          id: callCenter.id,
          label: callCenter.name,
          severity: 'default',
          icon: null,
          size: 'sm',
          rounded: true,
          tooltip: null
        }
      })
    }

    return {
      dt,
      filters,
      clearFilter,
      selectedDealerships,
      onRowSelect,
      onRowUnselect,
      accessModalIsOpen,
      dealershipBeingActioned,
      toggleIsActive,
      callCenterChips
    }
  }
}
</script>
