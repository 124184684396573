<template>
  <div class="datepicker" :dp-id="randomId">
    <Input v-model="displayValue" ref="inputRef" icon="calendar" :has-error="hasError" @focus="dropdownIsOpen = true" />
    <teleport to="#dropdown-overlay">
      <div
        ref="dropdownRef"
        v-click-away="handleClickAway"
        :style="floatingStyles"
        :class="{ hidden: !dropdownIsOpen }"
        class="datepicker-dropdown"
      >
        <div class="datepicker-dropdown__content">
          <div v-if="showPresets" class="border-r py-2 px-3 min-w-[150px]">
            <ul>
              <li v-for="preset in presets" class="datepicker-preset_item" :key="preset.label" @click="onPresetClick(preset)">
                {{ preset.label }}
              </li>
            </ul>
          </div>
          <div class="flex flex-col justify-center items-center py-4 px-6">
            <!-- Calendars Section - only shown if showCalendar is true -->
            <div v-if="showCalendar" class="flex gap-4">
              <div v-for="(month, index) in calendarMonths" :key="index" class="calendar">
                <!-- Calendar Header -->
                <div v-if="calendarViews[index] === 'date'" class="calendar-header">
                  <div class="month-chevron" @click="prev(index)">
                    <Icon type="chevron-left" fa-style="fas" />
                  </div>
                  <div class="calendar-month-year">
                    <div class="calendar-month-year_button" @click.stop="showMonthPicker(index)">
                      {{ month.format('MMMM') }}
                    </div>
                    <div class="calendar-month-year_button" @click.stop="showYearPicker(index)">
                      {{ month.format('YYYY') }}
                    </div>
                  </div>
                  <div class="month-chevron" @click="next(index)">
                    <Icon type="chevron-right" fa-style="fas" />
                  </div>
                </div>
                <!-- Date View -->
                <div v-if="calendarViews[index] === 'date'">
                  <div>
                    <div class="calendar-grid_header">
                      <div v-for="dow in weekdays" :key="dow" class="calendar-grid-header_dow">
                        {{ dow }}
                      </div>
                    </div>
                    <div class="calendar-grid_rows">
                      <div
                        v-for="(week, weekIndex) in daysInMonth[index]"
                        :key="weekIndex"
                        class="calendar-week"
                        :class="{ 'is-week-hovered': isWeekHovered(week) }"
                        @mouseover="onWeekMouseOver(week)"
                        @mouseleave="onWeekMouseLeave"
                      >
                        <div
                          v-for="(day, index2) in week"
                          :key="index2"
                          v-if="day !== null"
                          class="grid-day_container"
                          :class="[
                            { 'is-disabled': day?.isDisabled },
                            { 'is-selected': isSelected(day?.date) },
                            { 'is-in-range': isInRange(day?.date) },
                            { 'is-today': day?.date && day?.date.isToday() },
                            { 'is-range-start': isRangeStart(day?.date) },
                            { 'is-range-end': isRangeEnd(day?.date) },
                            { 'not-current-month': !day?.isCurrentMonth }
                          ]"
                          @click="onDayClick(day)"
                        >
                          <div class="grid-day">
                            {{ day?.day }}
                          </div>
                        </div>
                        <!-- Placeholder for empty days -->
                        <div v-else class="grid-day_container empty">
                          <div class="grid-day"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Month View -->
                <div v-else-if="calendarViews[index] === 'month'">
                  <div class="calendar-header">
                    <div class="month-chevron" @click="prevYear(index)">
                      <Icon type="chevron-left" fa-style="fas" />
                    </div>
                    <div class="calendar-month-year">
                      <div class="calendar-month-year_button" @click.stop="showYearPicker(index)">
                        {{ calendarMonths[index].format('YYYY') }}
                      </div>
                    </div>
                    <div class="month-chevron" @click="nextYear(index)">
                      <Icon type="chevron-right" fa-style="fas" />
                    </div>
                  </div>
                  <div class="month-grid">
                    <div
                      v-for="(monthName, monthIndex) in monthNames"
                      :key="monthIndex"
                      class="month-item"
                      @click.stop="onMonthSelect(monthIndex, index)"
                    >
                      {{ monthName }}
                    </div>
                  </div>
                </div>
                <!-- Year View -->
                <div v-else-if="calendarViews[index] === 'year'">
                  <div class="calendar-header">
                    <div class="month-chevron" @click="prevYearGroup(index)">
                      <Icon type="chevron-left" fa-style="fas" />
                    </div>
                    <div class="calendar-month-year">
                      <div class="calendar-month-year_button">{{ yearGroupTitle(index) }}</div>
                    </div>
                    <div class="month-chevron" @click="nextYearGroup(index)">
                      <Icon type="chevron-right" fa-style="fas" />
                    </div>
                  </div>
                  <div class="year-grid">
                    <div
                      v-for="year in years[index]"
                      :key="year"
                      class="year-item"
                      :class="{ 'is-current-year': year === calendarMonths[index].year() }"
                      @click.stop="onYearSelect(year, index)"
                    >
                      {{ year }}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Time Picker - only shown if showTime is true -->
            <div v-if="showTime" class="time-picker">
              <div>
                <Button size="sm" rounded plain severity="secondary" icon="chevron-up" @click="incrementHours" />
                <div>{{ displayHours }}</div>
                <Button size="sm" rounded plain severity="secondary" icon="chevron-down" @click="decrementHours" />
              </div>
              <div>:</div>
              <div>
                <Button size="sm" rounded plain severity="secondary" icon="chevron-up" @click="incrementMinutes" />
                <div>{{ displayMinutes }}</div>
                <Button size="sm" rounded plain severity="secondary" icon="chevron-down" @click="decrementMinutes" />
              </div>
              <div v-if="hourFormat === 12">:</div>
              <div v-if="hourFormat === 12">
                <Button size="sm" rounded plain severity="secondary" icon="chevron-up" @click="toggleAmPm" />
                <div>{{ selectedAmPm }}</div>
                <Button size="sm" rounded plain severity="secondary" icon="chevron-down" @click="toggleAmPm" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </teleport>
  </div>
</template>

<script setup>
import { ref, computed, watch } from 'vue'
import { useFloating, autoUpdate, offset, flip, shift } from '@floating-ui/vue'

import Button from '@/components/button/Button.vue'
import Icon from '@/components/icon/Icon.vue'
import Input from '@/components/forms/Input.vue'

import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import weekday from 'dayjs/plugin/weekday'
import isToday from 'dayjs/plugin/isToday'
import isoWeek from 'dayjs/plugin/isoWeek'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'

dayjs.extend(customParseFormat)
dayjs.extend(weekday)
dayjs.extend(isToday)
dayjs.extend(isoWeek)
dayjs.extend(isSameOrAfter)
dayjs.extend(isSameOrBefore)

const props = defineProps({
  modelValue: {
    type: [String, Date, Array],
    default: null
  },
  minDate: {
    type: [String, Date],
    default: null
  },
  maxDate: {
    type: [String, Date],
    default: null
  },
  minRange: {
    type: Number,
    default: null
  },
  maxRange: {
    type: Number,
    default: null
  },
  dateFormat: {
    type: String,
    default: null // Will be computed based on showTime
  },
  displayFormat: {
    type: String,
    default: null // Will be computed based on showTime
  },
  hasError: {
    type: Boolean,
    default: false
  },
  selectionMode: {
    type: String,
    default: 'single', // Options: 'single', 'range', 'week'
    validator: value => ['single', 'range', 'week'].includes(value)
  },
  showCalendar: {
    type: Boolean,
    default: true
  },
  showTime: {
    type: Boolean,
    default: false
  },
  timeStep: {
    type: Number,
    default: 15
  },
  hourFormat: {
    type: Number,
    default: 24,
    validator: value => [12, 24].includes(value)
  },
  closeOnSelect: {
    type: Boolean,
    default: true
  },
  showFooter: {
    type: Boolean,
    default: false
  },
  showButtons: {
    type: Boolean,
    default: false
  },
  monthsVisible: {
    type: Number,
    default: 1,
    validator: value => [1, 2].includes(value)
  },
  showPresets: {
    type: Boolean,
    default: false
  },
  presets: {
    type: Array,
    default: () => [
      {
        label: 'Today',
        value: {
          start: dayjs().startOf('day'),
          end: dayjs().endOf('day')
        }
      },
      {
        label: 'Yesterday',
        value: {
          start: dayjs().subtract(1, 'day').startOf('day'),
          end: dayjs().subtract(1, 'day').endOf('day')
        }
      },
      {
        label: 'This Week',
        value: {
          start: dayjs().startOf('week'),
          end: dayjs().endOf('week')
        }
      },
      {
        label: 'This Month',
        value: {
          start: dayjs().startOf('month'),
          end: dayjs().endOf('month')
        }
      },
      {
        label: 'Last Month',
        value: {
          start: dayjs().subtract(1, 'month').startOf('month'),
          end: dayjs().subtract(1, 'month').endOf('month')
        }
      },
      {
        label: 'This Year',
        value: {
          start: dayjs().startOf('year'),
          end: dayjs().endOf('year')
        }
      },
      {
        label: 'Last Year',
        value: {
          start: dayjs().subtract(1, 'year').startOf('year'),
          end: dayjs().subtract(1, 'year').endOf('year')
        }
      }
    ]
  }
})

const emit = defineEmits(['update:modelValue', 'select'])

const randomId = Math.random().toString(36).substring(7)
const inputRef = ref(null)
const dropdownRef = ref(null)

const dropdownIsOpen = ref(false)

const { floatingStyles } = useFloating(inputRef, dropdownRef, {
  placement: 'bottom-start',
  middleware: [
    offset(10),
    shift(),
    flip({
      fallbackAxisSideDirection: ['bottom', 'right', 'left', 'top']
    })
  ],
  whileElementsMounted: autoUpdate
})

// Initialize selectedDate based on modelValue
const selectedDate = ref(props.selectionMode === 'range' || props.selectionMode === 'week' ? [] : null)

// Computed dateFormat and displayFormat
const dateFormat = computed(() => {
  return props.dateFormat || (props.showTime ? 'YYYY-MM-DD HH:mm:ss' : 'YYYY-MM-DD')
})

const displayFormat = computed(() => {
  return props.displayFormat || (props.showTime ? 'DD/MM/YYYY HH:mm' : 'DD/MM/YYYY')
})

// Time selection variables
const selectedHours = ref(dayjs().hour())
const selectedMinutes = ref(Math.floor(dayjs().minute() / props.timeStep) * props.timeStep)
const selectedAmPm = ref(dayjs().hour() >= 12 ? 'PM' : 'AM')

// Initialize selectedDate and time based on modelValue
function initializeDateAndTime() {
  if (props.selectionMode === 'range' || props.selectionMode === 'week') {
    if (Array.isArray(props.modelValue) && props.modelValue.length > 0) {
      selectedDate.value = props.modelValue.map(date => dayjs(date, dateFormat.value))
    } else {
      selectedDate.value = []
    }
  } else if (props.modelValue) {
    selectedDate.value = dayjs(props.modelValue, dateFormat.value)
  } else {
    selectedDate.value = null
  }

  initializeTime()
}

function initializeTime() {
  if (!props.showTime) return

  if (selectedDate.value && dayjs.isDayjs(selectedDate.value)) {
    let hour = selectedDate.value.hour()
    selectedMinutes.value = selectedDate.value.minute()

    if (props.hourFormat === 12) {
      if (hour === 0) {
        selectedAmPm.value = 'AM'
        selectedHours.value = 12
      } else if (hour < 12) {
        selectedAmPm.value = 'AM'
        selectedHours.value = hour
      } else if (hour === 12) {
        selectedAmPm.value = 'PM'
        selectedHours.value = 12
      } else {
        selectedAmPm.value = 'PM'
        selectedHours.value = hour - 12
      }
    } else {
      selectedHours.value = hour
    }
  } else {
    const now = dayjs()
    selectedHours.value = now.hour()
    selectedMinutes.value = Math.floor(now.minute() / props.timeStep) * props.timeStep

    if (props.hourFormat === 12) {
      if (selectedHours.value >= 12) {
        selectedAmPm.value = 'PM'
        selectedHours.value = selectedHours.value % 12
        if (selectedHours.value === 0) {
          selectedHours.value = 12
        }
      } else {
        selectedAmPm.value = 'AM'
        if (selectedHours.value === 0) {
          selectedHours.value = 12
        }
      }
    }
  }
}

initializeDateAndTime()

watch(
  () => props.modelValue,
  () => {
    initializeDateAndTime()
  },
  { immediate: true }
)

watch(selectedDate, () => {
  initializeTime()
})

watch([selectedHours, selectedMinutes, selectedAmPm], () => {
  if (props.selectionMode === 'range' || props.selectionMode === 'week') {
    if (selectedDate.value.length === 0) {
      selectedDate.value = [dayjs().startOf('day'), dayjs().startOf('day')]
    }
    selectedDate.value = selectedDate.value.map(date => date.hour(hour).minute(selectedMinutes.value))
  } else {
    if (!selectedDate.value) {
      selectedDate.value = dayjs().startOf('day')
    }
  }

  if (selectedDate.value) {
    let hour = selectedHours.value
    if (props.hourFormat === 12) {
      if (selectedAmPm.value === 'PM') {
        if (hour !== 12) hour += 12
      } else {
        if (hour === 12) hour = 0
      }
    }

    selectedDate.value = selectedDate.value.hour(hour).minute(selectedMinutes.value)
    emit('update:modelValue', selectedDate.value.format(dateFormat.value))
  }
})

const calendarMonths = ref([])
const calendarViews = ref([])
const years = ref([])

function initializeCalendarMonths() {
  calendarMonths.value = []
  calendarViews.value = []
  years.value = []
  let startMonth = dayjs()
  if (props.selectionMode === 'range' || props.selectionMode === 'week') {
    if (selectedDate.value.length > 0) startMonth = selectedDate.value[0]
  } else if (selectedDate.value) {
    startMonth = selectedDate.value
  }
  const monthsToShow = props.selectionMode === 'week' ? 1 : props.monthsVisible
  for (let i = 0; i < monthsToShow; i++) {
    const month = startMonth.add(i, 'month')
    calendarMonths.value.push(month)
    calendarViews.value.push('date')
    years.value.push(generateYears(month.year()))
  }
}

initializeCalendarMonths()

watch(
  () => [props.monthsVisible, props.selectionMode],
  () => {
    initializeCalendarMonths()
  }
)

function next(index) {
  if (calendarViews.value[index] === 'date') nextMonth(index)
  else if (calendarViews.value[index] === 'month') nextYear(index)
  else if (calendarViews.value[index] === 'year') nextYearGroup(index)
}

function prev(index) {
  if (calendarViews.value[index] === 'date') prevMonth(index)
  else if (calendarViews.value[index] === 'month') prevYear(index)
  else if (calendarViews.value[index] === 'year') prevYearGroup(index)
}

function nextMonth(index) {
  calendarMonths.value[index] = calendarMonths.value[index].add(1, 'month')
  updateYears(index)
  for (let i = index + 1; i < props.monthsVisible; i++) {
    calendarMonths.value[i] = calendarMonths.value[i - 1].add(1, 'month')
    updateYears(i)
  }
}

function prevMonth(index) {
  calendarMonths.value[index] = calendarMonths.value[index].subtract(1, 'month')
  updateYears(index)
  for (let i = index - 1; i >= 0; i--) {
    calendarMonths.value[i] = calendarMonths.value[i + 1].subtract(1, 'month')
    updateYears(i)
  }
}

function nextYear(index) {
  calendarMonths.value[index] = calendarMonths.value[index].add(1, 'year')
  updateYears(index)
}

function prevYear(index) {
  calendarMonths.value[index] = calendarMonths.value[index].subtract(1, 'year')
  updateYears(index)
}

function updateYears(index) {
  years.value[index] = generateYears(calendarMonths.value[index].year())
}

function showMonthPicker(index) {
  calendarViews.value[index] = 'month'
}

function showYearPicker(index) {
  calendarViews.value[index] = 'year'
}

const weekdays = ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su']
const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

function generateYears(baseYear) {
  const yearsArray = []
  const startYear = Math.floor(baseYear / 12) * 12
  for (let i = 0; i < 12; i++) {
    yearsArray.push(startYear + i)
  }
  return yearsArray
}

function prevYearGroup(index) {
  const firstYear = years.value[index][0]
  years.value[index] = generateYears(firstYear - 12)
}

function nextYearGroup(index) {
  const firstYear = years.value[index][0]
  years.value[index] = generateYears(firstYear + 12)
}

function yearGroupTitle(index) {
  if (!years.value[index] || years.value[index].length === 0) return ''
  const firstYear = years.value[index][0]
  const lastYear = years.value[index][years.value[index].length - 1]
  return `${firstYear} - ${lastYear}`
}

function onYearSelect(year, index) {
  calendarMonths.value[index] = calendarMonths.value[index].year(year)
  years.value[index] = generateYears(year)
  calendarViews.value[index] = 'month'
}

function onMonthSelect(monthIndex, index) {
  calendarMonths.value[index] = calendarMonths.value[index].month(monthIndex)
  calendarViews.value[index] = 'date'
}

function getWeeksInMonth(month) {
  const weeks = []
  // Always start at the beginning of the week that contains the 1st of the month
  const firstDayOfCalendar = month.startOf('month').startOf('isoWeek')
  let currentDate = firstDayOfCalendar.clone()

  // Always generate 6 weeks (6 rows)
  for (let w = 0; w < 6; w++) {
    const week = []
    for (let i = 0; i < 7; i++) {
      const date = currentDate.clone()
      let isDisabled = false

      if (props.minDate && date.isBefore(dayjs(props.minDate, dateFormat.value), 'day')) isDisabled = true
      if (props.maxDate && date.isAfter(dayjs(props.maxDate, dateFormat.value), 'day')) isDisabled = true

      if ((props.selectionMode === 'range' || props.selectionMode === 'week') && selectedDate.value && selectedDate.value.length === 1) {
        const firstDate = selectedDate.value[0]
        const rangeLength = Math.abs(date.diff(firstDate, 'day')) + 1
        if (props.minRange && rangeLength < props.minRange) isDisabled = true
        if (props.maxRange && rangeLength > props.maxRange) isDisabled = true
      }

      const isCurrentMonth = date.month() === month.month()
      const dayData = {
        day: date.date(),
        date: date,
        isCurrentMonth: isCurrentMonth,
        isDisabled: isDisabled
      }

      // If you prefer not to show days from other months, you can conditionally null them:
      // if (!isCurrentMonth) {
      //   week.push(null)
      // } else {
      //   week.push(dayData)
      // }
      // Otherwise:
      week.push(dayData)

      currentDate = currentDate.add(1, 'day')
    }
    weeks.push(week)
  }
  return weeks
}

const daysInMonth = computed(() => {
  return calendarMonths.value.map(month => getWeeksInMonth(month))
})

const hoveredWeek = ref(null)

function onWeekMouseOver(week) {
  if (props.selectionMode === 'week') hoveredWeek.value = week
}

function onWeekMouseLeave() {
  if (props.selectionMode === 'week') hoveredWeek.value = null
}

function isWeekHovered(week) {
  return props.selectionMode === 'week' && hoveredWeek.value === week
}

function onDayClick(day) {
  if (day.isDisabled || !day.date) return

  if (props.selectionMode === 'week') {
    const weekDates = {
      start: day.date.startOf('isoWeek').isoWeekday(1),
      end: day.date.endOf('isoWeek').isoWeekday(7)
    }

    selectedDate.value = [weekDates.start, weekDates.end]
    emit(
      'update:modelValue',
      selectedDate.value.map(date => date.format(dateFormat.value))
    )

    // Auto-close & emit final value if NOT showing time
    if (props.closeOnSelect && !props.showTime) {
      emit(
        'select',
        selectedDate.value.map(d => d.format(dateFormat.value))
      )
      dropdownIsOpen.value = false
    }
    return
  }

  if (props.selectionMode === 'range') {
    if (selectedDate.value.length === 0) {
      selectedDate.value = [day.date]
    } else if (selectedDate.value.length === 1) {
      const firstDate = selectedDate.value[0]
      const secondDate = day.date
      const rangeLength = Math.abs(secondDate.diff(firstDate, 'day')) + 1

      if (props.minRange && rangeLength < props.minRange) return
      if (props.maxRange && rangeLength > props.maxRange) return

      selectedDate.value.push(secondDate)
      if (selectedDate.value[0].isAfter(selectedDate.value[1])) {
        selectedDate.value = [selectedDate.value[1], selectedDate.value[0]]
      }

      emit(
        'update:modelValue',
        selectedDate.value.map(date => date.format(dateFormat.value))
      )

      if (props.closeOnSelect) dropdownIsOpen.value = false
    } else if (selectedDate.value.length === 2) {
      emit(
        'update:modelValue',
        selectedDate.value.map(date => date.format(dateFormat.value))
      )
      // Close & emit on second click if NOT showing time
      if (props.closeOnSelect && !props.showTime) {
        emit(
          'select',
          selectedDate.value.map(d => d.format(dateFormat.value))
        )
        dropdownIsOpen.value = false
      }
    } else {
      selectedDate.value = [day.date]
    }
  } else {
    let hour = selectedHours.value
    if (props.hourFormat === 12) {
      if (selectedAmPm.value === 'PM' && hour !== 12) hour += 12
      else if (selectedAmPm.value === 'AM' && hour === 12) hour = 0
    }
    selectedDate.value = day.date.hour(hour).minute(selectedMinutes.value)

    emit('update:modelValue', selectedDate.value.format(dateFormat.value))
    // Close & emit immediately if NOT showing time
    if (props.closeOnSelect && !props.showTime) {
      emit('select', selectedDate.value.format(dateFormat.value))
      dropdownIsOpen.value = false
    }
  }
}

function isSelected(date) {
  if (!date) return false
  if (props.selectionMode === 'range' || props.selectionMode === 'week') {
    if (selectedDate.value.length === 0) return false
    if (selectedDate.value.length === 1) return date.isSame(selectedDate.value[0], 'day')
    return date.isSame(selectedDate.value[0], 'day') || date.isSame(selectedDate.value[1], 'day')
  } else if (props.selectionMode === 'single') {
    return selectedDate.value && date.isSame(selectedDate.value, 'day')
  }
}

function isInRange(date) {
  if (!date || !selectedDate.value) return false
  if (props.selectionMode === 'range' || props.selectionMode === 'week') {
    if (selectedDate.value.length < 2) return false
    return date.isSameOrAfter(selectedDate.value[0], 'day') && date.isSameOrBefore(selectedDate.value[1], 'day')
  }
  return false
}

function isRangeStart(date) {
  if (!date || selectedDate.value?.length === 0) return false
  if (props.selectionMode === 'range' || props.selectionMode === 'week') {
    return date.isSame(selectedDate.value[0], 'day')
  }
  return false
}

function isRangeEnd(date) {
  if (!date || selectedDate.value?.length < 2) return false
  if (props.selectionMode === 'range' || props.selectionMode === 'week') {
    return date.isSame(selectedDate.value[1], 'day')
  }
  return false
}

// 3) If showTime is true, emit @select on click away
function handleClickAway(event) {
  if (event.target.closest(`.datepicker[dp-id="${randomId}"]`) === null) {
    // Emit final value on click-away if time is shown
    if (props.showTime) {
      if (props.selectionMode === 'range' || props.selectionMode === 'week') {
        if (selectedDate.value.length === 2) {
          emit(
            'select',
            selectedDate.value.map(d => d.format(dateFormat.value))
          )
        }
      } else if (selectedDate.value) {
        emit('select', selectedDate.value.format(dateFormat.value))
      }
    }
    dropdownIsOpen.value = false
  }
}

function onPresetClick(preset) {
  selectedDate.value = [preset.value.start, preset.value.end]
  emit(
    'update:modelValue',
    selectedDate.value.map(date => date.format(dateFormat.value))
  )
  // Close & emit if NOT showing time
  if (props.closeOnSelect && !props.showTime) {
    emit(
      'select',
      selectedDate.value.map(d => d.format(dateFormat.value))
    )
  }
  if (props.closeOnSelect) dropdownIsOpen.value = false
}

watch(selectedDate, () => {
  if ((props.selectionMode === 'range' || props.selectionMode === 'week') && selectedDate.value.length > 0) {
    calendarMonths.value[0] = selectedDate.value[0].startOf('month')
    updateYears(0)
    for (let i = 1; i < props.monthsVisible; i++) {
      calendarMonths.value[i] = calendarMonths.value[i - 1].add(1, 'month')
      updateYears(i)
    }
  } else if (selectedDate.value) {
    calendarMonths.value[0] = selectedDate.value.startOf('month')
    updateYears(0)
    for (let i = 1; i < props.monthsVisible; i++) {
      calendarMonths.value[i] = calendarMonths.value[i - 1].add(1, 'month')
      updateYears(i)
    }
  }
})

const displayValue = computed({
  get() {
    if (props.selectionMode === 'range' || props.selectionMode === 'week') {
      if (selectedDate.value.length === 2) {
        return `${selectedDate.value[0].format(displayFormat.value)} - ${selectedDate.value[1].format(displayFormat.value)}`
      } else if (selectedDate.value.length === 1) {
        return selectedDate.value[0].format(displayFormat.value)
      } else {
        return ''
      }
    } else {
      return selectedDate.value ? selectedDate.value.format(displayFormat.value) : ''
    }
  },
  set() {}
})

function incrementHours() {
  if (props.hourFormat === 24) {
    selectedHours.value = (selectedHours.value + 1) % 24
  } else {
    selectedHours.value = (selectedHours.value % 12) + 1
    if (selectedHours.value === 0) selectedHours.value = 12
  }
}

function decrementHours() {
  if (props.hourFormat === 24) {
    selectedHours.value = (selectedHours.value - 1 + 24) % 24
  } else {
    selectedHours.value = (selectedHours.value - 1 + 12) % 12
    if (selectedHours.value === 0) selectedHours.value = 12
  }
}

function incrementMinutes() {
  selectedMinutes.value = (selectedMinutes.value + props.timeStep) % 60
}

function decrementMinutes() {
  selectedMinutes.value = (selectedMinutes.value - props.timeStep + 60) % 60
}

function toggleAmPm() {
  selectedAmPm.value = selectedAmPm.value === 'AM' ? 'PM' : 'AM'
}

const displayHours = computed(() => selectedHours.value.toString().padStart(2, '0'))
const displayMinutes = computed(() => selectedMinutes.value.toString().padStart(2, '0'))
const hourFormat = props.hourFormat
</script>

<style>
.datepicker-dropdown {
  border-radius: var(--rounded-lg);
  box-shadow: var(--shadow-lg);
  background-color: var(--bg-primary);
  background-clip: padding-box;
  border: 1px solid var(--border-secondary);
  user-select: none;
  max-width: max-content;
  z-index: 9;
}

.datepicker-dropdown__content {
  display: flex;
}

.datepicker-preset_item {
  cursor: pointer;
  padding: var(--s-2) var(--s-4);
  border-radius: var(--rounded-md);
  font-size: var(--text-sm);
}
.datepicker-preset_item:hover {
  background-color: var(--bg-primary_hover);
}

.calendar {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.calendar-header {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  margin-bottom: 16px;
  font-weight: 600;
}

.month-chevron {
  cursor: pointer;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--text-quinary);
}

.month-chevron:hover {
  color: var(--fg-brand);
  background-color: var(--bg-secondary);
}

.calendar-month-year {
  display: flex;
  width: 120px;
  justify-content: center;
}

.calendar-month-year_button {
  cursor: pointer;
  padding: var(--s-1) var(--s-2);
  border-radius: var(--rounded-md);
  font-size: var(--text-md);
}
.calendar-month-year_button:hover {
  background-color: var(--bg-primary_hover);
}

.calendar-grid_header {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  text-align: center;
  font-size: var(--text-sm);
  text-align: center;
  margin-bottom: 8px;
  color: var(--text-secondary);
  font-weight: 500;
}

.calendar-grid_rows {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.calendar-week {
  display: flex;
}

.grid-day {
  height: var(--s-9);
  width: var(--s-9);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-size: var(--text-md);
  color: var(--text-secondary);
  position: relative;
  transition: all 0.2s;
  cursor: pointer;
}

.grid-day_container:hover > .grid-day {
  background-color: var(--bg-primary_hover);
}

.grid-day_container.is-disabled > .grid-day {
  cursor: not-allowed;
  color: var(--text-disabled);
}

.grid-day_container.not-current-month .grid-day {
  color: var(--text-disabled);
}

.grid-day_container.is-selected > .grid-day {
  background-color: var(--fg-brand);
  color: var(--text-white);
  font-weight: 600;
}

.grid-day_container.is-in-range {
  background-color: var(--bg-brand);
  color: var(--text-brand);
}
.grid-day_container.is-range-start {
  border-top-left-radius: 50%;
  border-bottom-left-radius: 50%;
}

.grid-day_container.is-range-end {
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
}

.grid-day_container.is-today > .grid-day::after {
  content: '';
  height: 4px;
  width: 4px;
  position: absolute;
  bottom: 5px;
  left: calc(50% - 2px);
  background-color: var(--text-secondary);
  border-radius: 50%;
}

/* Month and Year Picker Styles */
.month-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: var(--s-2);
  width: 100%;
}

.month-item {
  cursor: pointer;
  padding: var(--s-2);
  text-align: center;
  border-radius: var(--rounded-md);
  font-size: var(--text-md);
}
.month-item:hover {
  background-color: var(--bg-primary_hover);
}

.year-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: var(--s-2);
  width: 100%;
}

.year-item {
  cursor: pointer;
  padding: var(--s-2);
  text-align: center;
  border-radius: var(--rounded-md);
  font-size: var(--text-md);
}
.year-item:hover {
  background-color: var(--bg-primary_hover);
}
.year-item.is-current-year {
  font-weight: bold;
  color: var(--fg-brand);
}

.time-picker {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: var(--s-2);
}

.time-picker > div {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.time-picker > div > div {
  margin: 4px 0;
  font-size: var(--text-md);
}
</style>
