<template>
  <div
    class="tabs"
    :class="[type, type + '-' + size, 'tabs-' + direction]"
    role="tablist"
    :aria-orientation="direction === 'vertical' ? 'vertical' : 'horizontal'"
  >
    <div
      v-for="tab in tabs"
      :key="tab.id"
      role="tab"
      :tabindex="tab.isHidden ? -1 : 0"
      :aria-selected="tab.id === modelValue"
      v-tippy="{ content: showTooltip ? tab.label : null }"
      class="tab"
      :class="{ active: tab.id === modelValue, '!hidden': tab.isHidden }"
      @click="makeActive(tab)"
      @keydown.enter.prevent="makeActive(tab)"
      @keydown.space.prevent="makeActive(tab)"
    >
      <Icon v-if="tab.icon" :type="tab.icon" />
      <span v-if="showLabel" class="tab-label">{{ tab.label }}</span>
      <Badge rounded class="ml-2" size="sm" :content="tab.counter > 0 ? tab.counter : ''" inline></Badge>
    </div>
  </div>
</template>

<script>
import Icon from '@/components/icon/Icon.vue'
import Badge from '@/components/badge/Badge.vue'

export default {
  components: {
    Icon,
    Badge
  },
  props: {
    modelValue: { type: String, default: null },
    type: { type: String, default: 'underlined-small' },
    size: { type: String, default: 'md' },
    direction: { type: String, default: 'horizontal' },
    tabs: { type: Array, default: () => [] },
    initialTab: { type: String, default: null },
    showLabel: { type: Boolean, default: true },
    showTooltip: { type: Boolean, default: false }
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    function makeActive(tab) {
      emit('update:modelValue', tab.id)
    }

    return { makeActive }
  }
}
</script>

<style>
.tabs {
  user-select: none;
}

.tabs.tabs-vertical.pills-rail,
.tabs.tabs-vertical.pills-rail-block {
  flex-direction: column;
}

.tabs.pills-rail,
.tabs.pills-rail-block {
  background-color: var(--bg-secondary_alt);
  border-radius: var(--rounded-lg);
  display: inline-flex;
  border: 1px solid var(--border-secondary);
  /* box-shadow: var(--shadow-md); */
}

.tabs.pills-rail > .tab,
.tabs.pills-rail-block > .tab {
  color: var(--text-tertiary);
  font-weight: var(--font-medium);
  padding: var(--s-2) var(--s-4);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 3px;
  border-radius: calc(var(--rounded-md) - 0.1rem);
}
.tabs.pills-rail-xs > .tab,
.tabs.pills-rail-block-xs > .tab {
  padding: var(--s-1) var(--s-2);
  font-size: var(--text-sm);
}
.tabs.pills-rail-sm > .tab,
.tabs.pills-rail-block-sm > .tab {
  padding: var(--s-2) var(--s-3);
  font-size: var(--text-sm);
}
.tabs.tabs-vertical.pills-rail > .tab,
.tabs.tabs-vertical.pills-rail-block > .tab {
  justify-content: left;
}
.tabs.pills-rail-block {
  justify-content: space-between;
  display: flex;
}
.tabs.pills-rail-block > .tab {
  width: calc(100% - 6px);
}

.tabs.pills-rail > .tab > .tab-label,
.tabs.pills-rail-block > .tab > .tab-label {
  margin-left: 5px;
}

.tabs.pills-rail > .tab.active,
.tabs.pills-rail-block > .tab.active {
  background: var(--bg-primary_alt);
  box-shadow: var(--shadow-sm);
  color: var(--text-secondary);
}

.tabs.pills-rail > .tab:focus,
.tabs.pills-rail-block > .tab:focus {
  box-shadow: var(--focus-ring-brand);
}

/* underlined-small */
.tabs.underlined-small {
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 0px;
}

.tabs.underlined-small > .tab {
  padding: 10px 10px;
  border-radius: 5px;
}

/* Remove left padding from the first tab item */
.tabs.underlined-small > .tab:first-child {
  padding-left: 0;
}

/* Remove right padding from the last tab item */
.tabs.underlined-small > .tab:last-child {
  padding-right: 0;
}

.tabs.underlined-small > .tab > .icon {
  margin-right: 5px;
}

.tabs.underlined-small > .tab.active {
  color: var(--text-brand);
}

.tabs.underlined-small > .tab.active::after {
  content: '';
  display: block;
  width: 100%;
  height: 2px;
  background-color: var(--bg-brand);
  /* Adjust underline color here */
  border-radius: 10px;
  position: relative;
  bottom: 0;
}

.tab:hover {
  cursor: pointer;
  color: var(--fg-brand);
}

/* .tabs.horizontal.pills {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.tabs.pills>.tab {
  padding: 10px 20px;
}

.tabs.pills>.tab>.icon {
  margin-right: 5px;
}

.tabs.pills>.tab.active {
  background-color: var(--fg-brand);
  color: white;
  border-radius: var(--rounded-md);
}

.tab:hover {
  cursor: pointer;
  background-color: var(--extra-light);
} */
</style>
