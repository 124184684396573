<!-- TODO: Add loading skeleton -->
<template>
  <PageHeader
    :title="$t('ui.entities.departments.call-center')"
    breadcrumb
    :items="[
      { label: $t('ui.entities.setting', 2), to: '/settings' },
      { label: $t('ui.entities.departments.call-center', 2), to: '/settings/call-centers' },
      { label: callCenter.basicDetails.name ? callCenter.basicDetails.name : 'Unknown Call Center' }
    ]"
  >
    <template #right></template>
  </PageHeader>

  <div>
    <Tabs v-model="currentTab" :tabs="tabs" type="pills-rail" class="mb-8 item" />
  </div>
  <div>
    <div class="md:col-span-3">
      <div v-if="currentTab == 'basic'">
        <Card>
          <CardBody>
            <CardTitle title="Basic Info" :has-margin="true" />

            <div class="grid md:grid-cols-2 gap-x-8">
              <FormGroup :label="$t('ui.common.name')" :is-required="v$.basicDetails.name.required">
                <Input v-model="callCenter.basicDetails.name" :has-error="v$.basicDetails.name.$error" />
                <InputError :has-error="v$.basicDetails.name.$error">{{ v$.basicDetails.name.$errors[0]?.$message }}</InputError>
              </FormGroup>
            </div>
            <Button
              label="Save Changes"
              type="primary"
              class="mt-4"
              :is-loading="formState.basicDetailsSubmitting"
              @click="submitBasicForm"
            />
          </CardBody>
        </Card>
      </div>

      <div v-if="currentTab == 'dealerships'">
        <Card>
          <CardBody>
            <CardTitle title="Dealerships" :has-margin="false" />
          </CardBody>
          <Separator no-margin />
          <TableBasic>
            <thead>
              <tr>
                <th>Dealership</th>
                <th>Phone Number</th>
                <th>SMS Number</th>
                <th>Email Address</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="dealership in callCenter.dealerships" :key="dealership.id">
                <td class="flex items-center gap-3">
                  <Avatar :url="dealership.dealership.manufacturerLogoUrl" is-logo size="md" />
                  <div>
                    <router-link :to="'/settings/dealerships/' + dealership.dealership.id" class="font-medium link-hover">
                      {{ dealership.dealership.name }}
                    </router-link>
                  </div>
                </td>
                <td><Icon type="phone" /> {{ dealership.phoneNumber }}</td>
                <td><Icon type="sms" /> {{ dealership.smsNumber }}</td>
                <td><Icon type="at" /> {{ dealership.emailAddress }}</td>
              </tr>
            </tbody>
          </TableBasic>
        </Card>
      </div>

      <div v-if="currentTab == 'users'">
        <Card>
          <CardBody>
            <CardTitle title="Users" :has-margin="false" />
          </CardBody>
          <Separator no-margin />
          <TableBasic>
            <thead>
              <tr>
                <th>Name</th>
                <th>Role</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="user in callCenter.users" :key="user.id">
                <td class="flex items-center gap-3">
                  <Avatar :url="user.profilePicUrl" size="md" :text="user.initials" />
                  <div>
                    <router-link :to="'/settings/users/' + user.id" class="font-medium link-hover">
                      {{ user.name }}
                    </router-link>
                    <div class="text-sm">{{ user.jobTitle }}</div>
                  </div>
                </td>
                <td>
                  <Select
                    v-model="user.role"
                    :options="[
                      { value: 'agent', label: 'Agent' },
                      { value: 'manager', label: 'Manager' }
                    ]"
                    class="max-w-52"
                    @select="val => updateUserRole(val, user.id)"
                  />
                </td>
              </tr>
            </tbody>
          </TableBasic>
          <Separator />
          <CardBody>
            <Button
              label="Save Changes"
              type="primary"
              class="mt-4"
              :is-loading="formState.usersDetailsSubmitting"
              @click="submitUsersForm"
            />
          </CardBody>
        </Card>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import useApiRequest from '@/composables/useApiRequest'
import { alertToast } from '@/utilities/notification'

import PageHeader from '@/components/page/PageHeader.vue'
import Card from '@/components/card/Card.vue'
import CardBody from '@/components/card/CardBody.vue'
import CardTitle from '@/components/card/CardTitle.vue'
import FormGroup from '@/components/forms/FormGroup.vue'
import Tabs from '@/components/tabs/Tabs.vue'
import Input from '@/components/forms/Input.vue'
import InputError from '@/components/forms/InputError.vue'
import Avatar from '@/components/avatar/Avatar.vue'
import Button from '@/components/button/Button.vue'
import TableBasic from '@/components/tables/TableBasic.vue'
import Icon from '@/components/icon/Icon.vue'
import Select from '@/components/forms/Select.vue'
import Separator from '@/components/separator/Separator.vue'

const { t } = useI18n()
const route = useRoute()
const router = useRouter()

const callCenterRequest = useApiRequest()
const callCenterSubmitFormRequest = useApiRequest()

const callCenter = ref({
  basicDetails: {
    id: null,
    name: null
  },
  dealerships: [],
  users: [],
  phoneNumbers: []
})

try {
  callCenterRequest.send({ endpoint: '/v1/call-centers/' + route.params.id, method: 'GET' }).then(response => {
    console.log(response)
    console.log(route.params.id)

    if (response.success) {
      callCenter.value.basicDetails = {
        id: response.data.id,
        name: response.data.name
      }
      callCenter.value.dealerships = response.data.dealerships
      callCenter.value.users = response.data.users
      callCenter.value.phoneNumbers = response.data.phoneNumbers
    } else {
      alertToast('Failed to fetch data', response.message, 'error')
    }
  })
} catch (err) {
  alertToast('Failed to fetch data', err.message, 'error')
}

const currentTab = ref(route.params.tab)
const tabs = ref([
  {
    id: 'basic',
    label: t('ui.common.basic-info'),
    // icon: 'phone-office',
    to: '/settings/call-centers/' + route.params.id
  },
  {
    id: 'dealerships',
    label: t('ui.entities.dealership', 2),
    // icon: 'car-building',
    to: '/settings/call-centers/' + route.params.id + '/dealerships'
  },
  {
    id: 'users',
    label: t('ui.common.user', 2),
    // icon: 'users',
    to: '/settings/call-centers/' + route.params.id + '/users'
  }
])

// Set tab to 'Basic' if no tab is set
if (route.params.tab === '') {
  currentTab.value = 'basic'
} else {
  currentTab.value = route.params.tab
}

// If the user changes the tab, update the route
watch(
  () => currentTab.value,
  newTab => {
    currentTab.value = newTab
    //fetch tab route
    let tab = tabs.value.find(tab => tab.id === newTab)
    if (tab) {
      // console.log('Tab Change: ', tab)
      router.replace(tab.to)
    }
  }
)

const inputRules = {
  basicDetails: {
    id: { required },
    name: { required }
  },
  dealerships: {},
  users: {},
  phoneNumbers: {}
}

const v$ = useVuelidate(inputRules, callCenter)

const formState = ref({
  basicDetailsSubmitting: false,
  dealershipsDetailsSubmitting: false,
  usersDetailsSubmitting: false,
  phoneNumbersDetailsSubmitting: false
})

async function submitBasicForm() {
  const isFormCorrect = await v$.value.$validate()
  console.log('isFormCorrect: ', isFormCorrect)

  if (isFormCorrect) {
    formState.value.basicDetailsSubmitting = true

    let dataToPost = {
      name: callCenter.value.basicDetails.name,
      dealerships: callCenter.value.dealerships,
      users: callCenter.value.users,
      phoneNumbers: callCenter.value.phoneNumbers
    }

    callCenterSubmitFormRequest
      .send({
        endpoint: '/v1/call-centers/' + route.params.id,
        method: 'PATCH',
        data: JSON.stringify(dataToPost)
      })
      .then(response => {
        console.log('response', response)

        if (response.success) {
          alertToast('Updated', null, 'success')
        } else {
          alertToast('Failed to update basic details', response.message, 'error')
        }
      })
    formState.value.basicDetailsSubmitting = false
  }
}

async function submitUsersForm() {
  const isFormCorrect = await v$.value.$validate()
  console.log('isFormCorrect: ', isFormCorrect)

  if (isFormCorrect) {
    formState.value.basicDetailsSubmitting = true

    let dataToPost = {
      name: callCenter.value.basicDetails.name,
      // dealerships: callCenter.value.dealerships,
      users: callCenter.value.users
    }

    callCenterSubmitFormRequest
      .send({
        endpoint: '/v1/call-centers/' + route.params.id,
        method: 'PATCH',
        data: JSON.stringify(dataToPost)
      })
      .then(response => {
        console.log('response', response)

        if (response.success) {
          alertToast('Updated', null, 'success')
        } else {
          alertToast('Failed to update user details', response.message, 'error')
        }
      })
    formState.value.usersDetailsSubmitting = false
  }
}
</script>

<style></style>
