<template>
  <PageHeader :title="$t('ui.entities.valuation', 2)" />

  <Card>
    <DataTable
      ref="dt"
      v-model:filters="filters"
      export-filename="Valuations"
      :value="valuations"
      :loading="false"
      removable-sort
      sort-field="id"
      :sort-order="-1"
      :rows="20"
      data-key="id"
      column-resize-mode="fit"
      :paginator="true"
      responsive-layout="scroll"
      filter-display="menu"
      :rows-per-page-options="[10, 20, 50]"
      state-key="dt-vehicles-valuations"
      state-storage="session"
      current-page-report-template="Showing {startRecord}-{endRecord} out of {totalRecords}"
      :global-filter-fields="['id', 'customerName', 'vehicle']"
    >
      <template #empty> No records found </template>
      <template #header>
        <div class="flex justify-between">
          <span class="flex justify-between gap-4">
            <span>
              <Input v-model="filters['global'].value" :placeholder="$t('ui.common.search', 1)" icon="search" />
            </span>
            <Button
              :label="$t('ui.actions.clear', 1)"
              severity="secondary"
              type="button"
              outlined
              icon="filter-slash"
              @click="clearFilter()"
            />
          </span>
        </div>
      </template>

      <Column field="id" header="ID" :sortable="true">
        <template #body="{ data }">
          {{ data.id }}
        </template>
      </Column>

      <Column field="vehicle" header="Vehicle" :sortable="true">
        <template #body="{ data }">
          <div class="flex items-center link" @click="$router.push('/vehicles/valuations/' + data.id)">
            {{ data.vehicle.make }} {{ data.vehicle.model }}
          </div>
        </template>
      </Column>

      <Column field="customerName" header="Customer" :sortable="true">
        <template #body="{ data }">
          {{ data.customerName }}
        </template>
      </Column>

      <Column field="createdAt" header="Requested" :sortable="true">
        <template #body="{ data }">
          <div v-tippy="{ content: toReadableDateTime(data.createdAt, 'date') }">
            {{ dayjs(data.createdAt).fromNow() }}
          </div>
        </template>
      </Column>

      <Column field="canBeValued" header="Image Status" :sortable="true" :show-filter-match-modes="false" :show-filter-operator="false">
        <template #body="{ data }">
          <div v-if="data.canBeValued"><Icon type="circle-check" class="text-success" /> Uploaded</div>
          <div v-else><Icon type="circle-xmark" class="text-danger" /> Awaiting</div>
        </template>
        <template #filter="{ filterModel }">
          <MultiSelect
            v-model="filterModel.value"
            :options="canBeValuedOptions"
            option-value="value"
            option-label="label"
            placeholder="Any"
            class="p-column-filter"
          >
            <template #option="slotProps">
              <div class="p-multiselect-representative-option">
                <span class="image-text">{{ slotProps.option.label }}</span>
              </div>
            </template>
          </MultiSelect>
        </template>
      </Column>

      <Column field="isValued" header="Status" :sortable="true" :show-filter-match-modes="false" :show-filter-operator="false">
        <template #body="{ data }">
          <div v-if="data.isDeclined"><Icon type="circle-xmark" class="text-danger" /> Declined</div>
          <div v-else-if="data.isValued"><Icon type="circle-check" class="text-success" /> Valued</div>
          <div v-else><Icon type="hourglass" class="text-warning" /> Ready to Value</div>
        </template>
        <template #filter="{ filterModel }">
          <MultiSelect
            v-model="filterModel.value"
            :options="hasBeenValuedOptions"
            option-value="value"
            option-label="label"
            placeholder="Any"
            class="p-column-filter"
          >
            <template #option="slotProps">
              <div class="p-multiselect-representative-option">
                <span class="image-text">{{ slotProps.option.label }}</span>
              </div>
            </template>
          </MultiSelect>
        </template>
      </Column>
    </DataTable>
  </Card>
</template>

<script>
import { ref } from 'vue'

import PageHeader from '@/components/page/PageHeader.vue'
import Card from '@/components/card/Card.vue'
import Button from '@/components/button/Button.vue'
import Input from '@/components/forms/Input.vue'
import Icon from '@/components/icon/Icon.vue'

import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import { FilterMatchMode } from 'primevue/api'
import MultiSelect from 'primevue/multiselect'

import useApiRequest from '@/composables/useApiRequest'

import { toReadableDateTime } from '@/utilities/timeFormatterUtil'

export default {
  components: {
    PageHeader,
    Card,
    Button,
    DataTable,
    Column,
    Input,
    Icon,
    MultiSelect
  },
  setup() {
    const valuations = ref()
    const valuationsRequest = useApiRequest()

    valuationsRequest
      .send({
        endpoint: '/v1/valuations',
        params: { dealershipIds: JSON.parse(localStorage.getItem('accessSelected')).dealerships.join(), types: 'part-exchange' }
      })
      .then(() => {
        valuations.value = valuationsRequest.data.value
      })

    const filters = ref({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      canBeValued: { value: [true], matchMode: FilterMatchMode.IN },
      isValued: { value: [false], matchMode: FilterMatchMode.IN }
    })

    const clearFilter = () => {
      filters.value = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        canBeValued: { value: null, matchMode: FilterMatchMode.IN },
        isValued: { value: null, matchMode: FilterMatchMode.IN }
      }
    }

    const canBeValuedOptions = [
      { label: 'Uploaded', value: true },
      { label: 'Awaiting', value: false }
    ]

    const hasBeenValuedOptions = [
      { label: 'Valued', value: true },
      { label: 'Ready to Value', value: false }
    ]

    return { valuations, filters, clearFilter, canBeValuedOptions, hasBeenValuedOptions, toReadableDateTime }
  }
}
</script>

<style lang="scss" scoped></style>
