<template>
  <PageHeader
    title="External Authenticator Codes"
    breadcrumb
    :items="[{ label: $t('ui.entities.setting', 2), to: '/settings' }, { label: 'Security' }, { label: 'External Authenticator Codes' }]"
  >
    <template #right>
      <Button
        v-if="$can('manage_external_authenticator_codes')"
        :label="$t('ui.crud.add', { item: $t('ui.common.account') })"
        severity="primary"
        icon="plus"
        @click="accountFormModalIsOpen = true"
      />
    </template>
  </PageHeader>

  <Card>
    <DataTable
      ref="dt"
      v-model:filters="filters"
      :value="totpRecords"
      removable-sort
      sort-field="id"
      :rows="20"
      data-key="id"
      column-resize-mode="fit"
      :paginator="true"
      responsive-layout="scroll"
      filter-display="menu"
      :rows-per-page-options="[10, 20, 50]"
      state-key="dt-settings-security-external-authenticator-codes"
      state-storage="session"
      current-page-report-template="Showing {startRecord}-{endRecord} out of {totalRecords}"
      :global-filter-fields="['id', 'label', 'username']"
      :loading="totpRecordsApiRequest.isLoading.value"
    >
      <template #empty>No records found</template>

      <!-- Header -->
      <template #header>
        <div class="flex justify-between">
          <!-- Left side: search + clear -->
          <span class="flex justify-between gap-4">
            <span>
              <Input v-model="filters['global'].value" :placeholder="$t('ui.common.search', 1)" icon="search" />
            </span>
            <Button
              :label="$t('ui.actions.clear', 1)"
              severity="secondary"
              type="button"
              outlined
              icon="filter-slash"
              @click="clearFilters()"
            />
          </span>

          <!-- Right side: show countdown if valid, else show Refresh -->
          <template v-if="!codesExpired">
            <Button :label="timeLeft + 's left'" severity="info" outlined />
          </template>
          <template v-else>
            <Button label="Refresh Codes" severity="primary" outlined icon="refresh" @click="fetchData" />
          </template>
        </div>
      </template>
      <!-- End Header -->

      <!-- Columns -->
      <Column field="id" header="ID" :sortable="true">
        <template #body="{ data }">{{ data.id }}</template>
      </Column>

      <Column field="label" header="Name" :sortable="true">
        <template #body="{ data }">{{ data.label }}</template>
      </Column>

      <Column field="username" header="Username" :sortable="true">
        <template #body="{ data }">{{ data.username }}</template>
      </Column>

      <!-- Show TOTP code if not expired, otherwise "Expired" -->
      <Column field="totpCode" header="Code" :sortable="true">
        <template #body="{ data }">
          <CopyText v-if="!codesExpired" class="inline-block font-bold">{{ data.totpCode }}</CopyText>
          <span v-else>Expired</span>
        </template>
      </Column>
    </DataTable>
  </Card>

  <Modal
    :is-open="accountFormModalIsOpen"
    title="New Account"
    description="Add a new account by copying the secret key rather than scanning a QR code."
    icon="key"
    :buttons="['ok', 'close']"
    :has-unsaved-changes="accountFormHasUnsavedChanges"
    close-text="Cancel"
    ok-text="Submit"
    :show-loader-on-confirm="true"
    :is-confirming="false"
    min-width="600"
    overflow-behaviour="visible"
    @close-modal="((accountFormModalIsOpen = false), (accountFormHasUnsavedChanges = false))"
    @ok-modal="submitForm()"
  >
    <div class="grid grid-cols-2 gap-x-6">
      <FormGroup label="Label" :is-required="v$.label.required?.$invalid">
        <Input v-model="accountFormState.label" :has-error="v$.label.$error" />
        <InputError :has-error="v$.label.$error">{{ v$.label.$errors[0]?.$message }}</InputError>
      </FormGroup>

      <FormGroup label="Username" :is-required="v$.username.required?.$invalid">
        <Input v-model="accountFormState.username" :has-error="v$.username.$error" />
        <InputError :has-error="v$.username.$error">{{ v$.username.$errors[0]?.$message }}</InputError>
      </FormGroup>
    </div>
    <div class="grid grid-cols-1 gap-x-6">
      <FormGroup label="Secret Key" :is-required="v$.secret.required?.$invalid">
        <Input v-model="accountFormState.secret" :has-error="v$.secret.$error" />
        <InputError :has-error="v$.secret.$error">{{ v$.secret.$errors[0]?.$message }}</InputError>
      </FormGroup>
    </div>
  </Modal>
</template>

<script setup>
import { ref, onMounted, onUnmounted, computed } from 'vue'

import PageHeader from '@/components/page/PageHeader.vue'
import Card from '@/components/card/Card.vue'
import Button from '@/components/button/Button.vue'
import FormGroup from '@/components/forms/FormGroup.vue'
import Input from '@/components/forms/Input.vue'
import Modal from '@/components/modal/Modal.vue'

import useApiRequest from '@/composables/useApiRequest'
import CopyText from '@/components/CopyText.vue'

import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import { FilterMatchMode } from 'primevue/api'
import { alertToast } from '@/utilities/notification'

import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'

const accountFormStateDefault = ref({
  label: null,
  username: null,
  secret: null
})

const accountFormState = ref({ ...accountFormStateDefault.value })

const accountFormModalIsOpen = ref(false)
const accountFormHasUnsavedChanges = ref(false)

// Table data
const totpRecords = ref([])
const totpRecordsApiRequest = useApiRequest()

// Filters
const filters = ref(null)
function initFilters() {
  filters.value = {
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    id: { value: null, matchMode: FilterMatchMode.IN }
  }
}
initFilters()

const clearFilters = () => {
  initFilters()
}

// We'll store the next TOTP boundary (e.g. top/half of the minute)
const expiryTimestamp = ref(0)

// We'll also update 'nowSeconds' every second
// so we can compute how long until we hit expiryTimestamp
const nowSeconds = ref(0)
let timer = null

onMounted(() => {
  fetchData()
  nowSeconds.value = Math.floor(Date.now() / 1000)
  timer = setInterval(() => {
    nowSeconds.value = Math.floor(Date.now() / 1000)
  }, 1000)
})

onUnmounted(() => {
  clearInterval(timer)
})

// Fetch codes from the API
function fetchData() {
  totpRecordsApiRequest.send({ endpoint: '/v1/totp/external' }).then(response => {
    totpRecords.value = response.data

    // Set an expiry time at the *next* TOTP boundary
    const fetchTime = Math.floor(Date.now() / 1000)
    const remainder = fetchTime % 30
    // e.g. if remainder=15, next boundary is fetchTime + (30 - 15)
    expiryTimestamp.value = fetchTime + (30 - remainder)

    // If remainder is 0, it means we're exactly at a boundary, so let's just set expiry 30s from now
    // but the same formula handles that as well (30 - 0 = 30).
  })
}

// timeLeft: how many seconds until the next TOTP boundary from the moment we fetched
const timeLeft = computed(() => {
  const diff = expiryTimestamp.value - nowSeconds.value
  return diff > 0 ? diff : 0
})

// codesExpired: true if we've passed the boundary
const codesExpired = computed(() => timeLeft.value <= 0)

const inputRules = {
  label: { required },
  username: { required },
  secret: { required }
}

const v$ = useVuelidate(inputRules, accountFormState)

const newAccountApiRequest = useApiRequest()

async function submitForm() {
  const isFormCorrect = await v$.value.$validate()
  if (!isFormCorrect) {
    return
  }

  // Determine if is update or new
  if (accountFormState.id) {
    // TODO: Update
  } else {
    // New
    let dataToPost = { ...accountFormState.value }
    try {
      newAccountApiRequest
        .send({
          method: 'POST',
          endpoint: '/v1/totp/external',
          data: JSON.stringify(dataToPost)
        })
        .then(response => {
          if (!response.success) {
            alertToast('Error', 'Failed to add account', 'error')
          } else {
            alertToast('Success!', 'Account added', 'success')
            accountFormModalIsOpen.value = false
            accountFormHasUnsavedChanges.value = false
            accountFormState.value = accountFormStateDefault.value
            fetchData()
          }
        })
    } catch (err) {
      alertToast('Error', err.message, 'error')
    }
    v$.value.$reset()
  }
}
</script>
