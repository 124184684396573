<template>
  <PageHeader
    :title="$t('ui.entities.comms.email', 2)"
    breadcrumb
    :items="[
      { label: $t('ui.entities.report.label', 2), to: '/reports' },
      { label: 'Engagement' },
      { label: $t('ui.entities.comms.email', 2), to: '/reports/engagement/emails' }
    ]"
  >
    <template #right>
      <div class="flex items-center gap-2">
        <Select
          v-model="callCenterSelected"
          :options="callCentersAllowed"
          icon="headset"
          value-key="id"
          label-key="name"
          :placeholder="`Select a ${$t('ui.entities.departments.call-centre')}`"
          class="max-w-[250px]"
          :readonly="callCentersAllowed.length == 1"
          @select="updateCallCenterSelected"
        >
        </Select>
        <DatePicker
          v-model="dateRange"
          selection-mode="range"
          max-range="31"
          position="bottom-right"
          :max-date="new Date()"
          :number-of-months="useBreakPoints({ xs: 1, sm: 2 })"
          @change-applied="onDateRangeChange"
        />
        <div>
          <Button label="Update" :is-loading="isLoading" @click="onFetchData" />
        </div>
      </div>
    </template>
  </PageHeader>
  <Card>
    <DataTable
      ref="dt"
      v-model:filters="filters"
      export-filename="Emails"
      :value="emailsData"
      removable-sort
      sort-field="timestamp"
      :sort-order="-1"
      data-key="id"
      column-resize-mode="fit"
      :paginator="true"
      responsive-layout="scroll"
      filter-display="menu"
      :global-filter-fields="['dealershipId', 'dealershipName', 'user.name', 'customer.name', 'from', 'to']"
      :rows="20"
      :rows-per-page-options="[10, 20, 50]"
      state-key="dt-report-engagement-emails"
      state-storage="session"
      :loading="isLoading"
      current-page-report-template="Showing {startRecord}-{endRecord} out of {totalRecords}"
    >
      <template #empty>
        <Empty v-if="!isLoading" :title="callCenterSelected ? 'No data' : `Select a ${$t('ui.entities.departments.call-centre')}`" />
      </template>

      <template #header>
        <div class="flex justify-between">
          <span class="flex justify-between gap-4">
            <span>
              <Input v-model="filters['global'].value" :placeholder="$t('ui.common.search', 1)" icon="search" />
            </span>
            <Button
              :label="$t('ui.actions.clear', 1)"
              severity="secondary"
              type="button"
              outlined
              icon="filter-slash"
              @click="clearFilters()"
            />
          </span>
        </div>
        <div class="flex justify-between mt-4">
          <span>
            <Button label="CSV" type="button" severity="secondary" outlined icon="file-excel" @click="exportCSV($event)" />
          </span>
        </div>
      </template>

      <Column field="dealershipName" header="Dealership" hidden> </Column>
      <Column field="dealershipId" header="Dealership" :sortable="true" :show-filter-match-modes="false" :show-filter-operator="false">
        <template #body="{ data }">
          <div class="flex items-center gap-3">
            <Avatar v-if="data.dealershipId" :url="$cdnBaseUrl + '/img/logos/manufacturers/' + data.manufacturerLogo" is-logo />
            <Avatar v-else icon="dash" />
            <div>
              <div class="font-semibold">
                {{ data.dealershipName || '-' }}
              </div>
              <div class="text-sm text-quaternary">{{ data.direction == 'outbound' ? data.from : data.to }}</div>
            </div>
          </div>
        </template>
        <template #filter="{ filterModel }">
          <MultiSelect
            v-model="filterModel.value"
            :options="dealershipsList"
            filter
            display="chip"
            option-value="value"
            option-label="label"
            placeholder="Any"
            class="max-w-[300px]"
          >
            <template #option="slotProps">
              {{ slotProps.option.label }}
            </template>
          </MultiSelect>
        </template>
      </Column>
      <Column field="direction">
        <template #body="{ data }">
          <Icon v-if="data.direction == 'inbound'" type="arrow-left" />
          <Icon v-if="data.direction == 'outbound'" type="arrow-right" />
        </template>
      </Column>
      <Column field="customer.name" header="Customer" :sortable="true">
        <template #body="{ data }">
          <router-link :to="data.customer.agreementId ? '/agreement/' + data.customer.agreementId : ''" class="link">
            <div class="flex items-center gap-3">
              <Avatar :icon="data.customer.agreementId ? 'user' : 'dash'" />
              <div>
                <div v-if="data.customer.agreementId" class="font-semibold whitespace-nowrap">
                  {{ data.customer.name || '-' }}
                </div>
                <div v-else class="mb-2">
                  <Dropdown>
                    <template #triggerContent>
                      <Button label="Link to customer" size="xs" outlined />
                    </template>
                    <Select
                      v-model="selectedAgreement"
                      placeholder="Search for customer"
                      :options="agreementsList"
                      value-key="agreementId"
                      label-key="name"
                      searchable
                      :handle-search="false"
                      v-model:search-term="searchTerm"
                      on-select-return-as-object
                      class="px-2 min-w-64"
                      @select="linkToCustomer(data, $event)"
                    >
                      <template #option="{ option }">
                        <div class="flex flex-col w-full text-wrap">
                          <div>{{ option.name }}</div>
                          <div class="text-sm text-tertiary">[{{ option.vrm }}] {{ option.vehicleMake }} {{ option.vehicleModel }}</div>
                        </div>
                      </template>
                    </Select>
                  </Dropdown>
                </div>

                <div class="text-sm text-quaternary">{{ data.direction == 'outbound' ? data.to : data.from }}</div>
              </div>
            </div>
          </router-link>
        </template>
      </Column>

      <Column field="from" header="From" hidden> </Column>
      <Column field="to" header="To" hidden> </Column>

      <Column field="subject" header="Message">
        <template #body="{ data }">
          <div class="text-quaternary font-medium text-xs">{{ data.subject }}</div>
          <div v-tippy="{ content: data.body, allowHTML: true }" class="text-secondary text-sm">
            {{ _truncate(data.body, { length: 50 }) }}
          </div>
        </template>
      </Column>

      <Column field="body" header="Body" hidden> </Column>
      <Column field="user.name" header="Linked User" hidden> </Column>

      <Column field="user.id" header="User" :sortable="true" :show-filter-match-modes="false" :show-filter-operator="false">
        <template #body="{ data }">
          <Avatar
            v-if="data.user"
            v-tippy="{ content: data.user.name }"
            :url="$cdnBaseUrl + '/img/profile-pics/' + data.user.profilePic"
            :text="data.user.initials"
          />
          <Avatar v-else-if="data.direction == 'outbound'" v-tippy="{ content: 'Automated' }" icon="message-bot" />
        </template>
        <template #filter="{ filterModel }">
          <MultiSelect
            v-model="filterModel.value"
            :options="usersList"
            filter
            display="chip"
            option-value="value"
            option-label="label"
            placeholder="Any"
            class="max-w-[300px]"
          >
            <template #option="slotProps">
              {{ slotProps.option.label }}
            </template>
          </MultiSelect>
        </template>
      </Column>
      <Column field="timestamp" header="Sent" :sortable="true">
        <template #body="{ data }">
          <div v-tippy="dayjs(data.timestamp).format('h:mma Do MMM YYYY')" class="whitespace-nowrap">
            {{ dayjs().to(dayjs(data.timestamp)) }}
          </div>
        </template>
      </Column>

      <Column v-if="$can('manage_conversations')">
        <template #body="{ data }">
          <Dropdown>
            <template #triggerContent>
              <Button icon="ellipsis-vertical" plain severity="secondary"></Button>
            </template>
            <DropdownItem @item-clicked="deleteEmail(data.id)">
              <Icon type="trash" class="text-error" />
              <span class="w-full">{{ $t('ui.actions.delete') }}</span>
            </DropdownItem>
          </Dropdown>
        </template>
      </Column>
    </DataTable>
  </Card>
</template>

<script setup>
import { ref, computed, watch, onMounted } from 'vue'

import Card from '@/components/card/Card.vue'
import PageHeader from '@/components/page/PageHeader.vue'
import DatePicker from '@/components/date-picker/DatePicker.vue'
import Input from '@/components/forms/Input.vue'
import Button from '@/components/button/Button.vue'
import Avatar from '@/components/avatar/Avatar.vue'
import Icon from '@/components/icon/Icon.vue'
import Dropdown from '@/components/dropdown/Dropdown.vue'
import DropdownItem from '@/components/dropdown/DropdownItem.vue'
import Select from '@/components/forms/Select.vue'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import Empty from '@/components/empty/Empty.vue'

import { FilterMatchMode } from 'primevue/api'
import Swal from 'sweetalert2'
import MultiSelect from 'primevue/multiselect'
import dayjs from 'dayjs'
import { MeiliSearch } from 'meilisearch'
import _truncate from 'lodash/truncate'

import useApiRequest from '@/composables/useApiRequest'
import { useBreakPoints } from '@/composables/useWindowSize'
import { useUserStore } from '@/stores/UserStore'
import { alertToast } from '@/utilities/notification'

const dt = ref()
const userStore = useUserStore()
const dealershipsAllowed = ref(userStore.accessAllowed.dealerships)
const dateRange = ref([dayjs().startOf('week').toDate(), dayjs().toDate()])
const emailsApiRequest = useApiRequest()
const deleteEmailApiRequest = useApiRequest()
const linkAgreementApiRequest = useApiRequest()
const isLoading = emailsApiRequest.isLoading

const emailsData = ref([])
const users = ref([])
const filters = ref([])

const dealershipIDs = userStore.accessAllowed.dealerships.map(dealership => dealership.id)
const dealershipIDsString = dealershipIDs.join(',')
const searchTerm = ref('')
const meiliSearchResults = ref([])
const meilisearchClient = ref()
const selectedAgreement = ref(null)

const callCentersAllowed = userStore.callCenters
const callCenterSelected = ref(null)

onMounted(() => {
  meilisearchClient.value = new MeiliSearch({
    host: import.meta.env.VITE_MEILISEARCH_URL,
    apiKey: import.meta.env.VITE_MEILISEARCH_API_KEY
  })
})

const search = async query => {
  if (query) {
    meiliSearchResults.value = await meilisearchClient.value.index('agreements').search(query, {
      filter: 'dealershipId IN [' + dealershipIDsString + ']'
    })
  } else {
    meiliSearchResults.value = []
  }
}

// Create a computed property to transform the data
const agreementsList = computed(() => {
  if (meiliSearchResults.value.hits != undefined) {
    return meiliSearchResults.value.hits
  }
  return false
})

watch(searchTerm, newSearchTerm => {
  search(newSearchTerm)
})

function initFilters() {
  filters.value = {
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    dealershipId: { value: null, matchMode: FilterMatchMode.IN },
    'user.id': { value: null, matchMode: FilterMatchMode.IN }
  }
}
initFilters()

function clearFilters() {
  initFilters()
}

function fetchEmailsData() {
  emailsApiRequest
    .send({
      endpoint: 'v1/conversations/emails?',
      params: {
        callCenterId: callCenterSelected.value,
        start: dayjs(dateRange.value[0]).format('YYYY-MM-DD'),
        end: dayjs(dateRange.value[1]).format('YYYY-MM-DD')
      }
    })
    .then(response => {
      if (response.success) {
        emailsData.value = response.data
      }
    })
}

if (!localStorage.getItem('report-filter-call-center-selected')) {
  callCenterSelected.value = callCentersAllowed[0].id
} else {
  callCenterSelected.value = parseInt(localStorage.getItem('report-filter-call-center-selected'))
}

fetchEmailsData()

function onFetchData() {
  if (dateRange.value[0] && dateRange.value[1] && callCenterSelected.value) {
    fetchEmailsData()
  }
}

function updateCallCenterSelected() {
  localStorage.setItem('report-filter-call-center-selected', callCenterSelected.value)
}

// Users List
const usersApiRequest = useApiRequest()
usersApiRequest.send({ endpoint: '/v1/users?userType=retain' }).then(response => {
  if (response.http.status == 200) {
    users.value = response.data
  }
})

function deleteEmail(id) {
  Swal.fire({
    title: 'Are you sure?',
    text: 'This action cannot be undone - not even by an admin!',
    icon: 'warning',
    reverseButtons: true,
    showCloseButton: true,
    showCancelButton: true,
    confirmButtonText: 'Yes, delete this email',
    confirmButtonColor: 'var(--bg-error-solid)',
    cancelButtonText: 'Cancel'
  }).then(function (swalResponse) {
    if (swalResponse.value.isConfirmed && id) {
      try {
        deleteEmailApiRequest.send({ endpoint: `/v1/conversations/emails/${id}`, method: 'DELETE' }).then(response => {
          if (response.success) {
            alertToast('Email deleted', null, 'success')

            // Remove email from table data
            let index = emailsData.value.indexOf(email => email.id == id)
            if (index) {
              emailsData.value.splice(index, 1)
            }
          }
        })
      } catch (err) {
        alertToast('Failed to delete email', err.message, 'error')
      }
    }
  })
}

function linkToCustomer(comm, $event) {
  if (comm?.id && selectedAgreement.value) {
    try {
      linkAgreementApiRequest
        .send({
          endpoint: '/v1/conversations/emails/' + comm.id,
          data: {
            agreementId: $event.agreementId
          },
          method: 'PATCH'
        })
        .then(response => {
          if (response.success) {
            comm.customer.agreementId = $event.agreementId
            comm.customer.name = $event.name
            alertToast('Customer linked', null, 'success')
          } else {
            alertToast('Failed to link customer', response.message, 'error')
          }
          selectedAgreement.value = null
        })
    } catch (err) {
      alertToast('Failed to link customer', err.message, 'error')
    }
  } else {
    alertToast('No agreement selected', 'Please select an agreement to link to this email', 'warning')
  }
}

const usersList = computed(() => {
  // if no users, return empty array
  if (!users.value) return []

  return users.value.map(u => {
    return {
      value: u.id,
      label: u.fullName,
      profilePicUrl: u.profilePicURL,
      initials: u.initials
    }
  })
})

const dealershipsList = computed(() => {
  return dealershipsAllowed.value.map(d => {
    return {
      value: d.id,
      label: d.name
    }
  })
})

const exportCSV = () => {
  dt.value.exportCSV()
}
</script>

<style></style>
