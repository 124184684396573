<template>
  <Card v-if="pageErrors.length > 0">
    <CardBody>
      <div v-for="(err, index) in pageErrors" :key="index">{{ err.message }}</div>
    </CardBody>
  </Card>
  <div v-else-if="agreementIsLoading || agreementData == null">
    <!-- Skeleton debug -->
    <!-- <div v-else-if="1"> -->
    <Skeleton width="100%" height="6rem" class="mb-4" />
    <div class="grid lg:grid-cols-4 gap-4">
      <Skeleton width="100%" height="50vh" />
      <div class="col-span-3">
        <Skeleton width="100%" height="4rem" />
        <div class="flex">
          <Skeleton width="50%" height="60vh" class="mt-6" />
          <Skeleton width="50%" height="60vh" class="ml-6 mt-6" />
        </div>
      </div>
    </div>
  </div>
  <div v-else-if="agreementData != null">
    <div>
      <Card class="!mb-6">
        <div class="p-2 pl-6 flex justify-between items-center">
          <div class="flex items-center whitespace-nowrap">
            <Icon v-if="agreementData.markers.archived" v-tippy="{ content: 'Archived' }" type="archive" class="mr-3" />
            <Dropdown
              position="bottom-left"
              :is-disabled="!$can('manage_agreement') || agreementData.status == 'Ended' || agreementData.markers.archived ? true : false"
            >
              <template #triggerContent>
                <div class="flex items-center cursor-pointer mr-4">
                  <h3 v-tippy="{ content: 'Status' }" class="font-medium">
                    {{ $t('ui.entities.agreement.status.' + agreementData.status.toLowerCase()) }}
                  </h3>
                  <Icon
                    v-if="$can('manage_agreement') && !agreementData.markers.archived"
                    type="chevron-down"
                    class="ml-2 text-md text-secondary"
                    :class="agreementData.status == 'Ended' || agreementData.status == 'Archived' ? '!hidden' : ''"
                  />
                </div>
              </template>
              <!-- <DropdownItem @item-clicked="statusChanged('Idle')">Idle</DropdownItem> -->
              <DropdownItem @item-clicked="statusChanged('Unresponsive')">{{
                $t('ui.entities.agreement.status.unresponsive')
              }}</DropdownItem>
              <DropdownItem @item-clicked="statusChanged('Uncontactable')">{{
                $t('ui.entities.agreement.status.uncontactable')
              }}</DropdownItem>
              <DropdownItem @item-clicked="statusChanged('Contacted')">{{ $t('ui.entities.agreement.status.contacted') }}</DropdownItem>
              <DropdownItem @item-clicked="statusChanged('Appointed')">{{ $t('ui.entities.agreement.status.appointed') }}</DropdownItem>
              <!-- <DropdownItem @item-clicked="statusChanged('Complete')">Complete</DropdownItem> -->
              <DropdownItem has-sub-menu>
                {{ $t('ui.entities.agreement.status.complete') }}
                <template #submenu>
                  <DropdownItem @item-clicked="statusChanged('Dealt')">{{ $t('ui.entities.agreement.status.dealt') }}</DropdownItem>
                  <DropdownItem @item-clicked="statusChanged('Refinanced')">{{
                    $t('ui.entities.agreement.status.refinanced')
                  }}</DropdownItem>
                  <DropdownItem @item-clicked="statusChanged('Settled')">{{ $t('ui.entities.agreement.status.settled') }}</DropdownItem>
                  <DropdownItem @item-clicked="statusChanged('Handed Back')">{{
                    $t('ui.entities.agreement.status.handed back')
                  }}</DropdownItem>
                </template>
              </DropdownItem>
            </Dropdown>
            <div class="w-full mr-4 flex flex-row items-center">
              <Chip
                v-for="tag in tagsToDisplay"
                :key="tag"
                :can-remove="!agreementData.markers.archived"
                size="sm"
                :severity="tag.displayStyle"
                class="mr-1"
                @remove-chip="removeTag(tag.id)"
                >{{ tag.title }}</Chip
              >
              <Chip
                v-if="!agreementData.markers.archived"
                size="sm"
                class="!text-secondary mr-1 cursor-pointer"
                icon="plus"
                @click="tagsModalIsOpen = true"
                >{{ $t('ui.crud.new', { item: $t('ui.common.tag') }) }}</Chip
              >
            </div>
          </div>
          <div class="flex">
            <Badge
              v-if="$can('view_tasks')"
              :content="remindersCounter.total"
              :variant="remindersCounter.overdue > 0 ? 'danger' : 'primary'"
              size="md"
              rounded
            >
              <Button v-tippy="{ content: 'Reminders' }" severity="secondary" plain icon="check-double" @click="remindersIsOpen = true" />
            </Badge>
            <Button
              v-if="$can('edit_agreement_details')"
              v-tippy="{ content: 'Edit' }"
              severity="secondary"
              plain
              icon="pencil"
              :router-link="'/agreement-edit/' + agreementID"
              :disabled="agreementData.markers.archived"
            />
            <Dropdown>
              <template #triggerContent>
                <Button v-tippy="{ content: 'More Actions' }" icon="ellipsis-h" severity="secondary" plain />
              </template>
              <!-- <DropdownItem>
                <Icon type="clock-rotate-left" />
                <span class="w-full">{{ $t('ui.entities.history') }}</span>
              </DropdownItem>
              <DropdownSeparator /> -->
              <DropdownItem
                @click="
                  (updateAgreementData({ isArchived: !agreementData.markers.archived }),
                  (agreementData.markers.archived = !agreementData.markers.archived))
                "
              >
                <Icon type="archive" />
                <span class="w-full">{{ agreementData.markers.archived ? $t('ui.actions.unarchive') : $t('ui.actions.archive') }}</span>
              </DropdownItem>
              <DropdownItem v-if="$can('merge_records')" @click="mergeModalIsOpen = true">
                <Icon type="merge" />
                <span class="w-full">{{ $t('ui.actions.merge') }}</span>
              </DropdownItem>
              <DropdownItem v-if="$can('transfer_agreements')" @click="transferModalIsOpen = true">
                <Icon type="arrow-right-from-arc" />
                <span class="w-full">Transfer</span>
              </DropdownItem>
              <DropdownItem v-if="$can('delete_records')" class="text-error" @click="submitDeleteAgreement">
                <Icon type="trash" />
                <span class="w-full">{{ $t('ui.actions.delete') }}</span>
              </DropdownItem>
            </Dropdown>
          </div>
        </div>
      </Card>
    </div>

    <div class="grid grid-cols-1 lg:grid-cols-4 gap-4">
      <div class="flex flex-col gap-4">
        <Card no-margin>
          <CardBody>
            <div class="text-center font-medium">
              <div>
                <div class="text-lg inline-block">
                  <CopyText v-if="agreementData.customer?.isCompany" :explicit-text-to-copy="agreementData.customer.companyName">
                    {{ agreementData.customer.companyName + ' ' + agreementData.customer.forename + ' ' + agreementData.customer.surname }}
                  </CopyText>
                  <CopyText v-else :explicit-text-to-copy="agreementData.customer.forename + ' ' + agreementData.customer.surname">
                    {{ agreementData.customer.title }}
                    {{ agreementData.customer.forename }}
                    {{ agreementData.customer.surname }}
                  </CopyText>
                </div>
              </div>
              <div class="flex items-center justify-center mt-1 text-secondary">
                <!-- <Avatar size="sm" :url="agreementData.dealership.manufacturerLogo" class="p-1"></Avatar> -->
                <img :src="agreementData.dealership.manufacturerLogo" class="" width="18" />
                <h6 v-tippy="agreementData.dealership.nameShortNoManufacturer" class="ml-2 text-left">
                  {{ agreementData.dealership.displayName }}
                </h6>
              </div>
            </div>
            <div class="mt-2 gap-2 flex justify-center">
              <Chip v-if="customerTimeZone" v-tippy="'Customer\'s Timezone: ' + customerTimeZone" size="sm" icon="user">
                {{ customerTimeZone ? dayjs().tz(customerTimeZone).format('h:mma') : '-' }}
              </Chip>
              <Chip
                v-if="agreementData?.dealership?.timeZone"
                v-tippy="'Dealership\'s Timezone: ' + agreementData.dealership.timeZone"
                size="sm"
                icon="car-building"
              >
                {{ agreementData.dealership.timeZone ? dayjs().tz(agreementData.dealership.timeZone).format('h:mma') : '-' }}
              </Chip>
            </div>
          </CardBody>
        </Card>
        <Card>
          <CardBody>
            <div class="flex gap-2 justify-center">
              <div
                v-tippy="agreementData.contactPermissions.canCall ? 'Can Call' : 'Can\'t Call'"
                class="rounded-full p-1 flex items-center justify-center w-[30px] h-[30px] bg-tertiary"
              >
                <span class="fa-stack fa-2x text-sm">
                  <i class="fa-solid fa-phone fa-stack-1x align-middle text-secondary"></i>
                  <i
                    v-if="!agreementData.contactPermissions.canCall"
                    class="fa-solid fa-slash-forward fa-stack-2x text-[16px] mt-[0.3rem] text-danger"
                  ></i>
                </span>
              </div>
              <div
                v-tippy="agreementData.contactPermissions.canEmail ? 'Can Email' : 'Can\'t Email'"
                class="rounded-full p-1 flex items-center justify-center w-[30px] h-[30px] bg-tertiary"
              >
                <span class="fa-stack fa-2x text-sm">
                  <i class="fa-solid fa-envelope fa-stack-1x align-middle text-secondary"></i>
                  <i
                    v-if="!agreementData.contactPermissions.canEmail"
                    class="fa-solid fa-slash-forward fa-stack-2x text-[16px] mt-[0.3rem] text-danger"
                  ></i>
                </span>
              </div>
              <div
                v-tippy="agreementData.contactPermissions.canSms ? 'Can Send SMS' : 'Can\'t Send SMS'"
                class="rounded-full p-1 flex items-center justify-center w-[30px] h-[30px] bg-tertiary"
              >
                <span class="fa-stack fa-2x text-sm">
                  <i class="fa-solid fa-sms fa-stack-1x align-middle text-secondary"></i>
                  <i
                    v-if="!agreementData.contactPermissions.canSms"
                    class="fa-solid fa-slash-forward fa-stack-2x text-[16px] mt-[0.3rem] text-danger"
                  ></i>
                </span>
              </div>
            </div>

            <div class="text-sm flex justify-center mt-1">
              <span class="text-secondary hover:text-brand-500 cursor-pointer" @click="contactPreferencesIsOpen = true">
                <Icon type="arrows-rotate" />
                {{ $t('ui.crud.update', { item: $t('ui.common.preference', 2) }) }}
              </span>
            </div>

            <div class="mt-4">
              <div v-if="agreementData.customer.contact.telephone1 > 0" class="mb-2 flex justify-between items-center">
                <div>
                  <Label no-margin>{{ $t('ui.entities.comms.telephone') }}</Label>
                  <div class="flex">
                    <CopyText class="text-md">
                      {{ agreementData.customer.contact.telephone1 }}
                    </CopyText>
                    <Icon
                      v-if="!phoneNumberValidations.landline.validatedData.value?.possible"
                      v-tippy="phoneNumberValidations.landline.validatedData.value?.possibility"
                      type="warning"
                      class="text-warning text-md ml-1"
                    />
                  </div>
                </div>
                <div
                  v-tippy="{
                    content: selectedCallCenterCommsConfig?.phoneNumber != null ? 'Call Landline' : 'Dealership has no assigned number'
                  }"
                >
                  <Button
                    v-if="$can('make_calls')"
                    :disabled="
                      selectedCallCenterCommsConfig?.phoneNumber == null ||
                      agreementData.markers.archived ||
                      agreementData.contactPermissions.canCall == false
                    "
                    size="sm"
                    outlined
                    icon="phone"
                    @click="newConversationTrigger('call', agreementData.customer.contact.telephone1)"
                  />
                </div>
              </div>
              <div v-if="agreementData.customer.contact.mobile > 0" class="mb-2 flex justify-between items-center">
                <div>
                  <Label no-margin>{{ $t('ui.entities.comms.mobile') }}</Label>
                  <div class="flex">
                    <CopyText class="text-md">
                      {{ agreementData.customer.contact.mobile }}
                    </CopyText>
                    <Icon
                      v-if="!phoneNumberValidations.mobile.validatedData.value?.possible"
                      v-tippy="phoneNumberValidations.mobile.validatedData.value?.possibility"
                      type="warning"
                      class="text-warning text-md ml-1"
                    />
                  </div>
                </div>
                <div class="flex">
                  <div v-tippy="{ content: 'Send SMS' }">
                    <Button
                      v-if="$can('send_sms')"
                      :disabled="
                        selectedCallCenterCommsConfig?.smsNumber == null ||
                        agreementData.markers.archived ||
                        agreementData.contactPermissions.canSms == false
                      "
                      icon="message"
                      size="sm"
                      outlined
                      @click="newConversationTrigger('sms', agreementData.customer.contact.mobile)"
                    />
                  </div>
                  <div
                    v-tippy="{
                      content: selectedCallCenterCommsConfig?.phoneNumber != null ? 'Call Mobile' : 'Dealership has no assigned number'
                    }"
                  >
                    <Button
                      v-if="$can('make_calls')"
                      :disabled="
                        selectedCallCenterCommsConfig?.phoneNumber == null ||
                        agreementData.markers.archived ||
                        agreementData.contactPermissions.canCall == false
                      "
                      icon="phone"
                      size="sm"
                      outlined
                      class="ml-2"
                      @click="newConversationTrigger('call', agreementData.customer.contact.mobile)"
                    />
                  </div>
                </div>
              </div>
              <div v-if="agreementData.customer.contact.email" class="mb-2 flex justify-between items-center">
                <div>
                  <Label no-margin>{{ $t('ui.entities.comms.email') }}</Label>
                  <CopyText class="text-md">
                    {{ agreementData.customer.contact.email }}
                    <CopyText :to-copy="agreementData.customer.contact.email" />
                  </CopyText>
                </div>
                <div v-tippy="{ content: 'Email' }">
                  <Button
                    v-if="$can('send_emails')"
                    :disabled="
                      selectedCallCenterCommsConfig?.emailAddress == null ||
                      agreementData.markers.archived ||
                      agreementData.contactPermissions.canEmail == false
                    "
                    icon="at"
                    size="sm"
                    outlined
                    @click="newConversationTrigger('email', agreementData.customer.contact.email)"
                  />
                </div>
              </div>
              <div v-if="agreementData.customer.address.postcode" class="mb-2">
                <Label no-margin>{{ $t('ui.entities.address.label') }}</Label>
                <CopyText class="text-md leading-5 inline-block" :explicit-text-to-copy="agreementData.customer.address.postcode">
                  <div v-if="agreementData.customer.address.line1">
                    {{ agreementData.customer.address.line1 }}
                  </div>
                  <div v-if="agreementData.customer.address.line2">
                    {{ agreementData.customer.address.line2 }}
                  </div>
                  <div v-if="agreementData.customer.address.town">
                    {{ agreementData.customer.address.town
                    }}<span v-if="agreementData.customer.address.county">, {{ agreementData.customer.address.county }}</span>
                  </div>
                  <div v-if="agreementData.customer.address.postcode">
                    {{ agreementData.customer.address.postcode }}
                  </div>
                </CopyText>

                <DirectionsMap
                  v-if="agreementData?.customer?.address?.postcode"
                  v-model:coordinates="routeCoordinates"
                  class="mt-4"
                  :from="agreementData.customer.address.postcode"
                  :to="agreementData.dealership.address.postcode"
                  style="height: 200px"
                />
              </div>
            </div>
          </CardBody>
        </Card>
      </div>
      <div class="lg:col-span-3">
        <!-- Menu -->
        <div class="mb-7">
          <Tabs
            v-model="currentTab"
            :tabs="tabs"
            :direction="useBreakPoints({ xs: 'vertical', sm: 'horizontal' })"
            type="pills-rail-block"
          ></Tabs>
        </div>

        <div v-if="currentTab === 'agreement'">
          <div class="grid sm:grid-cols-2 gap-2 sm:gap-4 items-start">
            <Card style="min-height: 82px">
              <CardBody>
                <div class="relative">
                  <div class="left-0 top-0 right-0 flex justify-between flex-col lg:flex-row items-center">
                    <div class="flex items-start flex-grow">
                      <img :src="agreementData.vehicle.manufacturerLogoUrl" class="mt-1" width="30" />
                      <div class="ml-3">
                        <div class="font-medium text-secondary flex items-center gap-2">
                          {{ agreementData.vehicle.model }}
                          <Icon v-if="agreementData.vehicle.fuelType == 'electric'" v-tippy="'Electric'" type="bolt" />
                          <Icon v-if="agreementData.vehicle.fuelType == 'hybrid'" v-tippy="'Hybrid'" type="leaf" />
                          <Icon v-if="agreementData.vehicle.fuelType == 'petrol'" v-tippy="'Petrol'" type="gas-pump" />
                          <Icon v-if="agreementData.vehicle.fuelType == 'diesel'" v-tippy="'Diesel'" type="gas-pump" />
                          <Chip v-if="agreementData.newUsed" size="sm">{{ agreementData.newUsed }}</Chip>
                        </div>
                        <div class="text-sm text-tertiary pr-4">
                          {{ agreementData.vehicle.derivative }}
                        </div>
                      </div>
                    </div>
                    <div class="flex items-center mt-5 lg:mt-0">
                      <CopyText :explicit-text-to-copy="agreementData.vehicle.vrm">
                        <LicensePlate
                          :plate="agreementData.vehicle.vrm"
                          country="uk"
                          :design="agreementData.vehicle.fuelType == 'electric' ? 'ev' : ''"
                          greyscale
                        />
                      </CopyText>
                    </div>
                  </div>

                  <div>
                    <img v-show="isVehicleImageLoaded" :src="agreementData.vehicle.imageUrl" @load="isVehicleImageLoaded = true" />
                    <Skeleton v-if="!isVehicleImageLoaded" width="100%" height="15rem" class="mb-2" />
                  </div>

                  <MileageTracker
                    :odometer-data="agreementData.odometer"
                    :agreement-id="agreementData.id"
                    @mileage-updated="updateAgreementData({ odometerCurrent: agreementData.odometer.current || null })"
                  ></MileageTracker>
                </div>
              </CardBody>
            </Card>
            <div>
              <Card>
                <CardBody>
                  <TermTracker :start-date="agreementData.startDate" :end-date="agreementData.endDate"></TermTracker>
                </CardBody>
              </Card>

              <Card>
                <CardBody>
                  <div>
                    <div class="grid grid-cols-3 gap-2">
                      <!-- <div class="details-grid-box">
                        <div class="details-grid-box-title">True Equity</div>
                        <div class="details-grid-box-data" :class="agreementData.equity > 0 ? 'text-success' : 'text-danger'">
                          {{ numberFormatter(agreementData.trueEquity, 'currency', 2, agreementData.currency) }}
                        </div>
                      </div> -->
                      <div class="details-grid-box">
                        <div class="details-grid-box-title">{{ $t('ui.entities.finance-agreement.finance-product') }}</div>
                        <div class="details-grid-box-data">
                          {{ $t('ui.entities.financeProducts.' + agreementData.productType) }}
                        </div>
                      </div>
                      <div class="details-grid-box">
                        <div class="details-grid-box-title">{{ $t('ui.entities.finance-agreement.monthly-payment') }}</div>
                        <div class="details-grid-box-data">
                          {{ numberFormatter(agreementData.monthlyRepayment, 'currency', 2, agreementData.currency) }}
                        </div>
                      </div>
                      <div class="details-grid-box">
                        <div class="details-grid-box-title">{{ $t('ui.entities.finance-agreement.final-repayment') }}</div>
                        <div class="details-grid-box-data">
                          {{ numberFormatter(agreementData.finalRepayment, 'currency', 2, agreementData.currency) }}
                        </div>
                      </div>
                      <!-- <div class="details-grid-box">
                        <div class="details-grid-box-title">Settlement</div>
                        <div class="details-grid-box-data">
                          {{ numberFormatter(agreementData.settlement, 'currency', 2, agreementData.currency) }}
                        </div>
                      </div> -->
                      <div class="details-grid-box">
                        <div class="details-grid-box-title">{{ $t('ui.entities.finance-agreement.rate') }}</div>
                        <div class="details-grid-box-data">{{ agreementData.rate }}% {{ agreementData.rateType }}</div>
                      </div>
                      <!-- <div class="details-grid-box">
                        <div class="details-grid-box-title">{{ $t('ui.entities.finance-agreement.equity') }}</div>
                        <div class="details-grid-box-data" :class="agreementData.equity > 0 ? 'text-success' : 'text-danger'">
                          {{ numberFormatter(agreementData.equity, 'currency', 2, agreementData.currency) }}-
                        </div>
                      </div> -->
                      <div class="details-grid-box">
                        <div class="details-grid-box-title">{{ $t('ui.entities.finance-agreement.term') }}</div>
                        <div class="details-grid-box-data">{{ agreementData.term }} months</div>
                      </div>
                      <!-- <div class="details-grid-box">
                        <div class="details-grid-box-title">{{ $t('ui.entities.finance-agreement.months-remaining') }}</div>
                        <div class="details-grid-box-data">
                          {{ agreementData.periodRemaining }}
                        </div>
                      </div>
                      <div class="details-grid-box">
                        <div class="details-grid-box-title">{{ $t('ui.entities.finance-agreement.payments-remaining') }}</div>
                        <div class="details-grid-box-data">
                          {{ agreementData.paymentsRemaining }}
                        </div>
                      </div> -->
                      <div class="details-grid-box">
                        <div class="details-grid-box-title">{{ $t('ui.entities.finance-agreement.deposit') }}</div>
                        <div class="details-grid-box-data">
                          {{ numberFormatter(agreementData.deposit, 'currency', 0, agreementData.currency) }}
                        </div>
                      </div>
                      <!-- <div class="details-grid-box">
                        <div class="details-grid-box-title">Financed</div>
                        <div class="details-grid-box-data">{{ numberFormatter(agreementData.amountBorrowed, 'currency', 0, agreementData.currency) }}</div>
                      </div>
                      <div class="details-grid-box">
                        <div class="details-grid-box-title">Purchase Price</div>
                        <div class="details-grid-box-data">{{ numberFormatter(agreementData.purchasePrice, 'currency', 0, agreementData.currency) }}</div>
                      </div>
                      <div class="details-grid-box">
                        <div class="details-grid-box-title">Balloon</div>
                        <div class="details-grid-box-data">{{ numberFormatter(agreementData.finalRepayment, 'currency', 0, agreementData.currency) }}</div>
                      </div> -->
                    </div>
                    <div>
                      <div
                        class="w-full text-center mt-4 text-secondary text-md cursor-pointer hover:text-brand-500"
                        @click="agreementFullDetailsIsOpen = !agreementFullDetailsIsOpen"
                      >
                        {{ agreementFullDetailsIsOpen ? $t('ui.common.hide') : $t('ui.common.more') }} {{ $t('ui.common.details') }}
                        <Icon :type="agreementFullDetailsIsOpen ? 'chevron-up' : 'chevron-down'" />
                      </div>
                      <div v-if="agreementFullDetailsIsOpen">
                        <TableBasic size="sm" class="mt-2">
                          <tbody>
                            <tr>
                              <td class="text-quaternary">{{ $t('ui.entities.finance-agreement.purchase-price') }}</td>
                              <td>{{ numberFormatter(agreementData.purchasePrice, 'currency', 0, agreementData.currency) }}</td>
                            </tr>
                            <tr>
                              <td class="text-quaternary">{{ $t('ui.entities.finance-agreement.amount-financed') }}</td>
                              <td>{{ numberFormatter(agreementData.amountBorrowed, 'currency', 0, agreementData.currency) }}</td>
                            </tr>
                            <tr v-if="agreementData.odometer.atStart">
                              <td class="text-quaternary">{{ $t('ui.entities.finance-agreement.odometer-at-start') }}</td>
                              <td>{{ numberFormatter(agreementData.odometer.atStart, 'normal', 0) }}</td>
                            </tr>
                            <tr>
                              <td class="text-quaternary">{{ $t('ui.entities.finance-agreement.distance-allowed') }}</td>
                              <td>
                                {{ numberFormatter(agreementData.odometer.allowanceYearly, 'normal', 0) }}
                                <span class="text-secondary text-md">/{{ $t('ui.common.date-time.year') }}</span>
                                | {{ numberFormatter(agreementData.odometer.allowanceTotal, 'normal', 0) }}
                                <span class="text-secondary text-md lowercase">/{{ $t('ui.common.total') }}</span>
                              </td>
                            </tr>
                            <tr>
                              <td class="text-quaternary">{{ $t('ui.entities.vehicle.reg-date') }}</td>
                              <td>{{ agreementData.vehicle.regDate ? dayjs(agreementData.vehicle.regDate).format('DD/MM/YYYY') : '' }}</td>
                            </tr>
                            <tr>
                              <td class="text-quaternary">{{ $t('ui.entities.vehicle.vin') }}</td>
                              <td>{{ agreementData.vehicle.vin }}</td>
                            </tr>
                          </tbody>
                        </TableBasic>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </div>
          </div>
          <Card>
            <CardBody>
              <!-- <CardTitle :title="$t('ui.entities.notes-and-timeline')"></CardTitle> -->
              <!-- New Note Input -->
              <div v-if="!agreementData.markers.archived" class="mb-8 flex items-start">
                <Avatar :url="loggedInUserProfilePicUrl" :text="userStore.details.initials" />
                <form ref="form" class="flex items-baseline ml-5 w-full" @submit.prevent="submitNewNote">
                  <div class="w-full">
                    <TextArea
                      v-model="newNote.content"
                      rows="1"
                      auto-resize
                      class="w-full"
                      style="max-height: 150px !important"
                      placeholder="Type a new note..."
                      has-speech-to-text
                      :has-error="newNoteV$.content.$error"
                    />
                    <InputError :has-error="newNoteV$.content.$error">{{ newNoteV$.content.$errors[0].$message }}</InputError>
                  </div>
                  <Button type="submit" class="ml-6" icon="check" :is-loading="newNote.isLoading" />
                </form>
              </div>
              <Timeline v-if="timelineData">
                <TimelineItem
                  v-for="item in timelineData"
                  :key="item"
                  :title="item.creator?.name"
                  :timestamp="item.timestamp"
                  :avatar-url="item.creator?.profilePicUrl"
                  :avatar-icon="(item.creator?.id == 0 && 'robot') || (item.creator?.type == 'customer' && 'user')"
                  :avatar-text="item.creator?.initials"
                >
                  <template #content>
                    <div class="flex gap-1 items-start">
                      <Icon :type="item.timelineType.icon" class="mr-1" />
                      <div>
                        <span>{{ item.timelineType.descriptor?.prepend }}</span>
                        <router-link v-if="item.subjectLink" class="link" :to="item.subjectLink">{{
                          item.timelineType.descriptor?.subject
                        }}</router-link>
                        <span v-else>{{ item.timelineType.descriptor?.subject }}</span>
                        <span>{{ item.timelineType.descriptor?.append }}</span>
                        <span v-if="item.type === 'contact_email' && item.text" class="ml-1 text-quaternary">({{ item.text }})</span>
                        <span v-if="item.type === 'contact_call' && item.text" class="ml-1 text-quaternary">({{ item.text }})</span>
                        <span v-if="item.type === 'appointment_booked' && item.text" class="text-quaternary"> for {{ item.text }}</span>
                      </div>
                    </div>
                    <div class="flex gap-2 items-center">
                      <MessageBubble v-if="item.timelineType.inMessageBubble" class="inline-block">
                        <CopyText v-if="item.type == 'note'">
                          {{ item.text }}
                        </CopyText>
                        <div v-else>{{ item.text }}</div>
                      </MessageBubble>
                      <div
                        v-if="
                          item.type == 'note' &&
                          dayjs().diff(dayjs(item.timestamp), 'minutes', true) < 5 &&
                          item.creator?.id == userStore.details.id
                        "
                        class="mt-2"
                      >
                        <Button icon="pencil" plain severity="secondary" @click="editNote(item)" />
                      </div>
                    </div>
                  </template>
                </TimelineItem>
              </Timeline>
            </CardBody>
          </Card>
        </div>

        <div v-if="currentTab === 'conversations'">
          <Card style="height: calc(100vh - 290px)" class="!my-0">
            <Conversations
              style="height: 100%"
              :agreement="agreementData"
              :conversations="conversationsData"
              @new-conversation-submitted="newConversationSubmitted"
            />
          </Card>
        </div>

        <div v-if="currentTab === 'appointments'" class="relative">
          <Card v-if="route.params.id === ''">
            <CardBody>
              <div class="w-full flex gap-2">
                <Button :label="$t('ui.actions.new')" icon="plus" @click="handleNewAppointmentClick" />
                <Button label="Send Invite" icon="paper-plane" severity="secondary" outlined @click="handleAppointmentInviteClick" />
              </div>
              <div v-if="appointmentsData" class="mt-5">
                <Appointments :appointments-list="appointmentsData" />
              </div>
              <div v-else class="mt-5">
                <Skeleton height="1rem" width="15rem" class="mb-2" />
                <Skeleton height="4rem" class="mb-2" />
                <Skeleton height="4rem" class="mb-4" />
                <Skeleton height="1rem" width="15rem" class="mb-2" />
                <Skeleton height="4rem" class="mb-2" />
                <Skeleton height="4rem" class="mb-2" />
              </div>
            </CardBody>
          </Card>
          <div v-if="route.params.tab === 'appointments' && route.params.id">
            <Appointment :agreement-data="agreementData" @appointment-updated="onAppointmentUpdated"></Appointment>
          </div>
        </div>

        <Card v-if="currentTab === 'value'" class="relative">
          <CardBody>
            <Valuations :agreement-data="agreementData" @on-submit="onNewValuationSubmit" />
          </CardBody>
        </Card>

        <div v-if="currentTab === 'switch'">
          <Card>
            <CardBody>
              <div class="flex justify-between items-start">
                <CardTitle :title="$t('ui.entities.proposal.label', 2)" />
                <Button :label="$t('ui.actions.new')" icon="plus" @click="proposalBuilderIsOpen = true" />
              </div>
              <span class="text-secondary text-md">No proposals made</span>
            </CardBody>
          </Card>
          <Card>
            <CardBody>
              <CardTitle title="Explore New" />
              <Carousel v-model:vehicles="switchExplore.vehicles.new" v-model:favourites="switchExplore.favourites" />
            </CardBody>
            <CardBody>
              <CardTitle title="Explore Used" />
              <Carousel v-model:vehicles="switchExplore.vehicles.used" v-model:favourites="switchExplore.favourites" />
            </CardBody>
          </Card>
        </div>
      </div>
    </div>
  </div>
  <teleport to="#modals-container">
    <Modal
      :is-open="contactPreferencesIsOpen"
      icon="ballot-check"
      :title="$t('ui.common.contact-preference', 2)"
      description="Update the contact preferences for this customer."
      :buttons="['ok', 'close']"
      close-text="Cancel"
      ok-text="Save"
      min-width="400"
      max-width="400"
      @close-modal="contactPreferencesIsOpen = false"
      @ok-modal="submitContactPreferences"
    >
      <div>
        <ListGroup>
          <ListGroupItem>
            <Checkbox
              v-model="agreementData.contactPermissions.canCall"
              no-margin
              label="Can Call"
              description="Outbound calls"
              size="sm"
            />
          </ListGroupItem>
          <ListGroupItem>
            <Checkbox v-model="agreementData.contactPermissions.canEmail" no-margin label="Can Email" description="Manual and automatic" />
          </ListGroupItem>
          <ListGroupItem>
            <Checkbox v-model="agreementData.contactPermissions.canSms" no-margin label="Can SMS" description="Manual and automatic" />
          </ListGroupItem>
        </ListGroup>
      </div>
      <div class="mt-6 text-sm text-secondary">
        By saving these changes, I confirm that I have the customer's consent to contact them using the selected methods.
      </div>
    </Modal>

    <TaskHandler
      :key="randomKey"
      :is-open="taskHandlerIsOpen"
      :task="taskHandlerData"
      class="!z-[121]"
      @close-task-handler="taskHandlerIsOpen = false"
      @new-task-submit="newReminderSubmit"
      @update-task-submit="updateReminderSubmit"
    />
    <Modal
      :is-open="remindersIsOpen"
      :title="$t('ui.entities.task.reminder', 2)"
      description="Add reminders to keep track of important tasks."
      :buttons="[]"
      close-text="Close"
      min-width="400"
      icon="bell"
      @close-modal="remindersIsOpen = false"
    >
      <div>
        <TaskListItem
          v-for="task in computedReminders"
          :key="task.id"
          :task="task"
          :chips="[]"
          :description-length="70"
          class="!px-0"
          @update-task="onTaskListItemChange"
          @task-clicked="handleReminderClick(task)"
        />
      </div>
      <Button
        :label="$t('ui.crud.new', { item: $t('ui.entities.task.reminder') })"
        outlined
        icon="plus"
        class="mt-4"
        @click="handleNewReminderClick()"
      />
    </Modal>

    <Modal
      :is-open="tagsModalIsOpen"
      icon="tags"
      :title="$t('ui.common.tag', 2)"
      description="Tags can help understand the customer in an efficient way."
      :buttons="[]"
      close-text="Close"
      min-width="500"
      overflow-behaviour="visible"
      @close-modal="tagsModalIsOpen = false"
    >
      <Multiselect
        v-model="agreementData.tagIDs"
        :options="allTagsList"
        mode="tags"
        value-prop="id"
        label="title"
        :close-on-select="false"
        :searchable="true"
        :hide-selected="true"
        @select="addTag"
        @deselect="removeTag"
    /></Modal>

    <Modal
      :is-open="mergeModalIsOpen"
      icon="merge"
      :title="$t('ui.actions.merge')"
      description="Merge all activity from this agreement into another."
      :buttons="['close', 'ok']"
      close-text="Cancel"
      ok-text="Merge"
      :ok-is-disabled="mergeAgreementData.to === null || mergeAgreementData.from.agreementID === mergeAgreementData.to"
      min-width="500"
      max-width="500"
      overflow-behaviour="visible"
      @close-modal="((mergeModalIsOpen = false), (mergeAgreementData.to = null))"
      @ok-modal="submitMergeAgreement"
    >
      <Alert
        severity="error"
        title="Merging agreements cannot be reversed! Triple check before merging."
        :show-dismiss-button="false"
        :show-close-button="false"
        class="mb-4"
      />
      <Label>Merge into</Label>
      <Select
        v-model="mergeAgreementData.to"
        :options="agreementsList"
        value-key="agreementId"
        label-key="name"
        searchable
        :handle-search="false"
        v-model:search-term="searchTerm"
      >
        <template #option="slotProps">
          <div class="w-full max-w-[400px]">
            <div class="font-semibold flex gap-4 items-center justify-between">
              <div>{{ slotProps.option.name }}</div>
              <div class="text-sm text-quaternary"><Icon type="hashtag" /> {{ slotProps.option.agreementId }}</div>
            </div>
            <div class="flex w-full">
              <div class="text-sm text-quaternary w-full">
                <Icon type="car" /> {{ slotProps.option.vehicleModel }} [{{ slotProps.option.vrm }}]
              </div>
              <div class="text-sm text-quaternary"><Icon type="clock" /> {{ dayjs(slotProps.option.endDate).format('DD/MM/YYYY') }}</div>
            </div>
          </div>
        </template>
      </Select>

      <div v-if="mergeAgreementData.from.agreementID === mergeAgreementData.to" class="text-danger mt-2">
        Cannot merge into itself. Select a different record
      </div>

      <div v-else-if="mergeAgreementData.to != null" class="mt-4">
        #{{ mergeAgreementData.from.agreementID }} -> #{{ mergeAgreementData.to }}
      </div>
    </Modal>

    <Modal
      :is-open="transferModalIsOpen"
      icon="arrow-right-from-arc"
      title="Transfer"
      description="Transfer this agreement into another dealership."
      :buttons="['close', 'ok']"
      close-text="Cancel"
      ok-text="Transfer"
      min-width="500"
      max-width="500"
      @close-modal="((transferModalIsOpen = false), (transferAgreementData.to = null))"
      @ok-modal="submitTransferAgreement"
    >
      <Alert
        severity="error"
        title="The dealership will have access to customers contact details! Triple check before transferring."
        :show-dismiss-button="false"
        :show-close-button="false"
        class="mb-4"
      />
      <FormGroup label="Transfer into">
        <Select
          v-model="transferAgreementData.to"
          :options="dealershipsList"
          value-key="value"
          label-key="label"
          searchable
          :has-error="transferV$.to.$error"
          :handle-search="true"
        >
          <template #option="slotProps">
            <div class="flex gap-2">
              <Image :src="slotProps.option.manufacturerLogo" class="w-5" />
              <div>{{ slotProps.option.label }}</div>
            </div>
          </template>
        </Select>
        <InputError :has-error="transferV$.to.$error">{{ transferV$.to.$errors[0].$message }}</InputError>
      </FormGroup>

      <FormGroup :label="$t('ui.common.note')">
        <TextArea
          v-model="transferAgreementData.note"
          rows="3"
          auto-resize
          class="w-full"
          style="max-height: 150px !important"
          placeholder="Add a reason..."
          has-speech-to-text
          :has-error="transferV$.note.$error"
        />
        <InputError :has-error="transferV$.note.$error">{{ transferV$.note.$errors[0].$message }}</InputError>
      </FormGroup>

      <FormGroup label="Transferred by">
        <Chip :avatar="loggedInUserProfilePicUrl" :avatar-text="userStore.details.initials">{{ userStore.details.name }}</Chip>
      </FormGroup>
      <!-- <div v-if="transferAgreementData.to != null">#{{ transferAgreementData.from.agreementID }} -> #{{ transferAgreementData.to }}</div> -->
    </Modal>

    <Modal
      :is-open="editNoteModalIsOpen"
      icon="pencil"
      :title="$t('ui.crud.update', { item: $t('ui.common.note') })"
      description="You have 5 minutes to edit the note from the time it was created"
      :buttons="['close', 'ok']"
      close-text="Cancel"
      ok-text="Save"
      min-width="500"
      max-width="500"
      :has-unsaved-changes="editNoteHasUnsavedChanges"
      :is-loading="editNoteApiRequest?.isLoading.value"
      @close-modal="editNoteModalIsOpen = false"
      @ok-modal="submitEditNote"
    >
      <div class="w-full">
        <TextArea
          v-model="editNoteState.content"
          rows="6"
          auto-resize
          class="w-full"
          style="max-height: 150px !important"
          placeholder="Edit note..."
          has-speech-to-text
          :has-error="editNoteV$.content.$error"
        />
        <InputError :has-error="editNoteV$.content.$error">{{ editNoteV$.content.$errors[0].$message }}</InputError>
      </div>
    </Modal>

    <AppointmentHandler
      v-if="appointmentHandlerData"
      :key="randomKey"
      v-model:is-open="appointmentHandlerIsOpen"
      :agreement-contact-details="agreementData.customer.contact"
      :appointment="appointmentHandlerData"
      @close-appointment-handler="appointmentHandlerIsOpen = false"
      @new-appointment-submit="newAppointmentSubmitted"
    ></AppointmentHandler>

    <ProposalBuilder
      :key="randomKey"
      :proposal="proposalData"
      :is-open="proposalBuilderIsOpen"
      @close-handler="proposalBuilderIsOpen = false"
    ></ProposalBuilder>
  </teleport>
</template>

<script>
import { ref, computed, watch, inject, onMounted, nextTick } from 'vue'
import { useRoute, useRouter } from 'vue-router'

import { useI18n } from 'vue-i18n'
import { useUserStore } from '@/stores/UserStore'

// import axios from 'axios'

import useVuelidate from '@vuelidate/core'
import { required, helpers } from '@vuelidate/validators'

import { useAbility } from '@casl/vue'

import { MeiliSearch } from 'meilisearch'

import Tabs from '@/components/tabs/Tabs.vue'

import Card from '@/components/card/Card.vue'
import CardTitle from '@/components/card/CardTitle.vue'
import CardBody from '@/components/card/CardBody.vue'

import TaskListItem from '@/components/to-do/TaskListItem.vue'
import TaskHandler from '@/components/to-do/TaskHandler.vue'

import ListGroup from '@/components/list/ListGroup.vue'
import ListGroupItem from '@/components/list/ListGroupItem.vue'

import Modal from '@/components/modal/Modal.vue'

import Avatar from '@/components/avatar/Avatar.vue'
import Icon from '@/components/icon/Icon.vue'
import Badge from '@/components/badge/Badge.vue'
import Chip from '@/components/chip/Chip.vue'
import Button from '@/components/button/Button.vue'
import Label from '@/components/forms/Label.vue'
import TextArea from '@/components/forms/TextArea.vue'
import InputError from '@/components/forms/InputError.vue'
import Multiselect from '@vueform/multiselect'
import Select from '@/components/forms/Select.vue'
import Dropdown from '@/components/dropdown/Dropdown.vue'
import DropdownItem from '@/components/dropdown/DropdownItem.vue'
import DropdownSeparator from '@/components/dropdown/DropdownSeparator.vue'
import Checkbox from '@/components/forms/Checkbox.vue'
import TableBasic from '@/components/tables/TableBasic.vue'
import Image from '@/components/image/Image.vue'

import Timeline from '@/components/timeline/Timeline.vue'
import TimelineItem from '@/components/timeline/TimelineItem.vue'
import MessageBubble from '@/components/message/MessageBubble.vue'
import MileageTracker from '@/components/unique/trackers/MileageTracker.vue'
import TermTracker from '@/components/unique/trackers/TermTracker.vue'
import AppointmentHandler from '@/components/unique/appointments/AppointmentHandler.vue'
import ProposalBuilder from '@/components/unique/switch/ProposalBuilder.vue'

import Appointments from '@/views/agreement/Appointments.vue'
import Appointment from '@/views/agreement/Appointment.vue'
import Conversations from '@/views/agreement/Conversations.vue'
import Valuations from '@/views/agreement/Valuations.vue'

import DirectionsMap from '@/components/unique/DirectionsMap.vue'

import LicensePlate from '@/components/license-plate/LicensePlate.vue'

import dayjs from 'dayjs'
import { alertToast } from '@/utilities/notification'
import CopyText from '@/components/CopyText.vue'

import Alert from '@/components/alert/Alert.vue'

import Skeleton from 'primevue/skeleton'

import Swal from 'sweetalert2'

import useApiRequest from '@/composables/useApiRequest'
import usePhoneValidator from '@/composables/usePhoneValidator'

import agreementTimelineTypes from '@/utilities/agreementTimelineTypes'

import Carousel from '@/components/unique/switch/Carousel.vue'
import { StockService } from '@/components/unique/switch/TriumphStock'
import { NewStockService } from '@/components/unique/switch/TriumphStockNew'

import { useBreakPoints } from '@/composables/useWindowSize'
import FormGroup from '@/components/forms/FormGroup.vue'

export default {
  components: {
    Skeleton,
    Tabs,
    Chip,
    Badge,
    Button,
    Dropdown,
    DropdownItem,
    DropdownSeparator,
    Label,
    InputError,
    TextArea,
    Checkbox,
    Card,
    CardTitle,
    CardBody,
    FormGroup,
    TableBasic,
    Avatar,
    Icon,
    CopyText,
    Timeline,
    TimelineItem,
    MessageBubble,
    MileageTracker,
    TermTracker,
    Appointments,
    Appointment,
    Conversations,
    Valuations,
    Modal,
    Image,
    TaskListItem,
    TaskHandler,
    Multiselect,
    Select,
    AppointmentHandler,
    ProposalBuilder,
    DirectionsMap,
    Carousel,
    LicensePlate,
    Alert,
    ListGroup,
    ListGroupItem
  },
  setup() {
    const route = useRoute()
    const router = useRouter()
    const emitter = inject('emitter')
    const { can } = useAbility()
    const { t } = useI18n()
    const userStore = useUserStore()

    const agreementData = ref(null)

    const timezoneApiRequest = useApiRequest()
    const routeCoordinates = ref({})
    const customerTimeZone = ref(null)

    // Listen to changes in the routeCoordinates
    watch(routeCoordinates, () => {
      // console.log(customerTimeZone.value)
      if (customerTimeZone.value == null) {
        // console.log('Route Coordinates Changed', routeCoordinates.value)

        let lat = routeCoordinates.value.start.lat
        let lng = routeCoordinates.value.start.lng

        // Check that the lat and lng are valid
        if (lat == null || lng == null) {
          console.log('Lat or Lng is missing')
          return
        }

        timezoneApiRequest.send({ endpoint: '/v1/utilities/timezone?lat=' + lat + '&lng=' + lng }).then(response => {
          if (response.success) {
            customerTimeZone.value = response.data.timeZone

            // console.log('Customer Timezone:', customerTimeZone.value)

            // update timeZone in database
            updateAgreementData({ timeZone: customerTimeZone.value })
          }
        })
      }
    })

    // watch for changes in customerTimeZone, and update appointmentHandlerData
    watch(customerTimeZone, () => {
      if (appointmentHandlerData.value) {
        appointmentHandlerData.value.customerTimeZone = customerTimeZone.value
      }
    })

    const appointmentHandlerIsOpen = ref(false)
    const appointmentHandlerData = ref(null)

    const randomKey = ref(Math.random())

    const pageErrors = ref([])

    let loggedInUserProfilePicUrl = userStore.details.profilePicUrl

    const agreementFullDetailsIsOpen = ref(false)

    const contactPreferencesIsOpen = ref(false)
    const remindersIsOpen = ref(false)
    const taskHandlerIsOpen = ref(false)
    const taskHandlerData = ref()

    const timelineData = ref()

    const tagsModalIsOpen = ref(false)
    const mergeModalIsOpen = ref(false)
    const transferModalIsOpen = ref(false)

    const customerID = ref(route.params.cuid)
    const agreementID = ref(route.params.agid)
    const newNote = ref({
      userID: userStore.details.id,
      content: '',
      hasError: false,
      isLoading: false
    })

    const currentTab = ref(route.params.tab)

    const phoneNumberValidations = {
      mobile: usePhoneValidator(),
      landline: usePhoneValidator()
    }

    const switchExplore = ref({
      vehicles: {
        new: [],
        used: []
      },
      favourites: [2]
    })

    //Load Data
    const { isLoading: agreementIsLoading, hasError: agreementHasError, send: agreementFetchData } = useApiRequest()

    const timelineApiRequest = useApiRequest()

    const {
      isLoading: appointmentsIsLoading,
      hasError: appointmentsHasError,
      data: appointmentsData,
      send: appointmentsFetchData
    } = useApiRequest()

    const {
      isLoading: conversationsIsLoading,
      hasError: conversationsHasError,
      data: conversationsData,
      send: conversationsFetchData
    } = useApiRequest()
    const reminders = useApiRequest()

    const allTags = useApiRequest()

    const updateAgreement = useApiRequest()

    const { isLoading: newNoteIsLoading, hasError: newNoteHasError, send: newNoteSendRequest } = useApiRequest()

    const mergeAgreement = useApiRequest()

    const transferAgreement = useApiRequest()

    const deleteAgreement = useApiRequest()

    const newNoteApiRequest = useApiRequest()

    const dealershipIDs = ref()

    function fetchAllData() {
      agreementFetchData({ endpoint: '/v1/agreements/' + agreementID.value }).then(response => {
        //Check for errors
        if (!response.success) {
          return
        }
        agreementData.value = response.data
        // Check if timeZone was returned in the response. If so, set the timezone so that the customerTimeZone is not fetched again
        if (agreementData.value.timeZone) {
          // console.log('Timezone already set')
          customerTimeZone.value = agreementData.value.timeZone
        }

        //Check if the user has access to this agreement by dealershipid
        const viewAllowed = userStore.accessAllowed
        dealershipIDs.value = viewAllowed.dealerships.map(dealership => dealership.id)
        let groupsAllowed = viewAllowed.groups.map(group => group.id)
        let manufacturersAllowed = viewAllowed.manufacturers.map(manufacturer => manufacturer.id)

        appointmentHandlerData.value = {
          agreementID: agreementID.value,
          dealershipID: agreementData.value.dealership.id,
          timeZone: agreementData.value.dealership.timeZone,
          customerTimeZone: customerTimeZone.value
        }

        let hasDirectDealershipAccess = dealershipIDs.value.includes(parseInt(agreementData.value.dealership.id))
        let hasIndirectDealershipAccess = false

        // if agreementData.value.dealership.groupId IN viewAllowed.groups AND agreementData.value.manufacturerId IN viewAllowed.manufacturers, then allow access
        if (
          groupsAllowed.includes(agreementData.value.dealership.groupId) &&
          manufacturersAllowed.includes(agreementData.value.dealership.manufacturerId)
        ) {
          hasIndirectDealershipAccess = true
        }

        if (hasDirectDealershipAccess || hasIndirectDealershipAccess) {
          let customerName = agreementData.value.customer.isCompany
            ? agreementData.value.customer.companyName
            : agreementData.value.customer.name
          document.title = document.title + ' | ' + customerName

          //Validate mobile and landline
          phoneNumberValidations.mobile.validatePhoneNumber(agreementData.value.customer.contact.mobile)
          phoneNumberValidations.landline.validatePhoneNumber(agreementData.value.customer.contact.telephone1)

          timelineApiRequest.send({ endpoint: '/v1/agreements/' + agreementID.value + '/timeline' }).then(response => {
            //loop through each timeline item and use agreementTimelineTypes (array of objects) to fetch the timeline type, and append the matched object to the item
            response.data.forEach(item => {
              let itemType = agreementTimelineTypes[item.type]

              if (itemType) {
                item.timelineType = itemType

                if (item.type == 'appointment_booked') {
                  item.subjectLink = `/agreement/${agreementID.value}/appointments/${item.resourceId}`
                }
              } else {
                item.timelineType = { icon: 'far fa-question-circle', inMessageBubble: false }
              }
            })
            timelineData.value = response.data
          })
          appointmentsFetchData({ endpoint: '/v1/appointments?agreementId=' + agreementID.value })
          conversationsFetchData({ endpoint: '/v1/agreements/' + agreementID.value + '/conversations' })
          reminders.send({ endpoint: '/v1/todos?agreementID=' + agreementID.value })
          allTags.send({ endpoint: '/v1/tags' })
          StockService.getStock().then(data => (switchExplore.value.vehicles.used = data))
          NewStockService.getModels().then(data => (switchExplore.value.vehicles.new = data))
        } else {
          pageErrors.value.push({
            message: "You don't have access to view this agreement."
          })
        }
      })
    }

    const dealershipsRequest = useApiRequest()
    const dealershipsList = ref(null)

    let dealershipIds = userStore.accessAllowed.dealerships.map(dealership => dealership.id)

    try {
      dealershipsRequest
        .send({
          method: 'GET',
          endpoint: '/v1/dealerships',
          params: {
            ids: dealershipIds.join(',')
          }
        })
        .then(response => {
          if (response.success) {
            dealershipsList.value = response.data.map(dealership => {
              return { value: dealership.id, label: dealership.name, manufacturerLogo: dealership.manufacturerLogo }
            })
          } else {
            alertToast('Failed to fetch dealerships', response.message, 'error')
          }
        })
    } catch (err) {
      alertToast('Failed to fetch dealerships', err.message, 'error')
    }

    //Check that the agreementID is set and is valid
    onMounted(() => {
      if (agreementID.value == null || agreementID.value == 0) {
        console.log('AgreementID is malformed or missing')
        pageErrors.value.push({ message: 'AgreementID missing or Malformed' })
      } else {
        fetchAllData()
      }
    })

    function submitContactPreferences() {
      let jsonData = JSON.stringify({
        canCall: agreementData.value.contactPermissions.canCall,
        canEmail: agreementData.value.contactPermissions.canEmail,
        canSms: agreementData.value.contactPermissions.canSms,
        canMail: agreementData.value.contactPermissions.canMail
      })
      updateAgreementData(jsonData)
        .then(() => {
          contactPreferencesIsOpen.value = false
          alertToast('Contact Preferences Updated', null, 'success')
        })
        .catch(err => {
          alertToast('Failed to update contact preferences', err.message, 'danger')
          // handle error
          console.error(err)
        })
    }

    const appointmentsCounter = computed(() => {
      let counter = 0

      if (appointmentsData.value != null && appointmentsData.value.length > 0) {
        appointmentsData.value.forEach(app => {
          if (dayjs(app.start).isAfter(dayjs())) {
            counter++
          }
        })
      }

      return counter
    })

    const remindersCounter = computed(() => {
      let countTotal = 0
      let countOverdue = 0

      if (computedReminders.value && computedReminders.value.length > 0) {
        // Remove completed and deleted reminders
        countTotal = reminders.data.value.filter(rem => !rem.isCompleted && !rem.isDeleted).length
        countOverdue = reminders.data.value.filter(rem => dayjs(rem.dueDate).isBefore(dayjs()) && !rem.isCompleted && !rem.isDeleted).length
      }

      return { total: countTotal, overdue: countOverdue }
    })

    const computedReminders = computed(() => {
      // Remove deleted reminders
      if (reminders.data && reminders.data.value) {
        return reminders.data.value
        // return reminders.data.value.filter(rem => !rem.isDeleted)
      }
      return []
    })

    const tabs = computed(() => [
      {
        id: 'agreement',
        label: t('ui.entities.agreement.label'),
        icon: 'far fa-file-lines',
        to: '/agreement/' + agreementID.value,
        isActive: true
      },
      {
        id: 'conversations',
        label: t('ui.entities.conversation', 2),
        icon: 'far fa-phone',
        to: '/agreement/' + agreementID.value + '/conversations'
      },
      {
        id: 'appointments',
        label: t('ui.entities.appointment.label', 2),
        icon: 'far fa-calendar',
        to: '/agreement/' + agreementID.value + '/appointments',
        counter: appointmentsCounter.value
      },
      {
        id: 'value',
        label: t('ui.common.value'),
        icon: 'far fa-hand-holding-dollar',
        to: '/agreement/' + agreementID.value + '/value',
        isHidden: !can('view_valuations')
      },
      {
        id: 'switch',
        label: t('ui.entities.switch'),
        icon: 'far fa-arrows-repeat',
        to: '/agreement/' + agreementID.value + '/switch',
        isHidden: !can('view_switch')
      }
    ])

    const selectedCallCenterCommsConfig = computed(() => {
      // CommsConfig is now an array of objects. return the object where callCenterId matches localStore.callCenterId
      return agreementData.value?.dealership.commsConfig.find(config => config.callCenterId == userStore.selectedCallCenter)
    })

    function loadPhone(numberTo) {
      if (selectedCallCenterCommsConfig.value == null) {
        alertToast('Failed to load phone', 'No call center selected', 'danger')
        return
      }

      emitter.emit('phone-set-numbers', {
        from: { number: selectedCallCenterCommsConfig.value.phoneNumber },
        to: { number: numberTo }
      })
    }

    function newConversationTrigger(commType = null, contactTo) {
      if (commType == null) {
        return
      }

      if (commType == 'call') {
        loadPhone(contactTo)
      }
      if (commType == 'sms') {
        router.replace('/agreement/' + agreementID.value + '/conversations').then(() => {
          // console.log('Opening SMS Modal')
          nextTick(() => {
            emitter.emit('agreement-new-conversation', 'sms')
          })
        })
      }
      if (commType == 'email') {
        router.replace('/agreement/' + agreementID.value + '/conversations').then(() => {
          // console.log('Opening Email Modal')
          nextTick(() => {
            emitter.emit('agreement-new-conversation', 'email')
          })
        })
      }
    }

    function newConversationSubmitted() {
      // console.log('RECEIVED: new-conversation-submitted')
      conversationsFetchData({ endpoint: '/v1/agreements/' + agreementID.value + '/conversations' })
    }

    const newNoteRules = {
      userID: { required },
      content: { required: helpers.withMessage('Enter a note', required) },
      hasError: { required },
      isSubmitting: false
    }

    const newNoteV$ = useVuelidate(newNoteRules, newNote, { $stopPropagation: true })

    async function submitNewNote() {
      const isFormCorrect = await newNoteV$.value.$validate()

      // console.log(isFormCorrect)
      // console.log(newNote.value)

      if (isFormCorrect) {
        // console.log('submitting')

        newNote.value.isLoading = newNoteIsLoading

        //Disconnect main call to customer via the API
        let jsonData = JSON.stringify({
          content: newNote.value.content,
          userID: userStore.details.id
        })

        newNoteApiRequest
          .send({ endpoint: '/v1/agreements/' + agreementID.value + '/notes', method: 'POST', data: jsonData })
          .then(response => {
            if (!response.success) {
              alertToast('Failed to add note', response.data?.message, 'danger')
              newNote.value.isLoading = newNoteIsLoading
              return
            }

            let itemType = agreementTimelineTypes['note']

            timelineData.value.unshift({
              type: 'note',
              resourceId: response.data[0].id,
              timestamp: dayjs().toISOString(),
              text: newNote.value.content,
              creator: {
                id: userStore.details.id,
                user_type: userStore.details.type,
                name: userStore.details.name,
                profilePicUrl: userStore.details.profilePicUrl,
                initials: userStore.details.initials
              },
              timelineType: itemType
            })
            newNote.value.content = ''
            newNote.value.isLoading = newNoteIsLoading

            alertToast('Added', null, 'success')

            newNoteV$.value.$reset()
          })
      }
    }

    const editNoteApiRequest = useApiRequest()
    const editNoteHasUnsavedChanges = ref(false)

    const editNoteRules = {
      content: { required: helpers.withMessage("Notes can't be empty", required) }
    }

    const editNoteInitState = ref({
      id: null,
      content: null
    })

    const editNoteState = ref({
      id: null,
      content: null
    })

    const editNoteV$ = useVuelidate(editNoteRules, editNoteState.value)

    const editNoteModalIsOpen = ref(false)

    function onEditNoteClick(note) {
      console.log(note)
      editNoteModalIsOpen.value = true

      editNoteInitState.value.content = note.text

      editNoteState.value.id = note.resourceId
      editNoteState.value.content = note.text
    }

    async function submitEditNote() {
      const isFormCorrect = await editNoteV$.value.$validate()

      if (!isFormCorrect) {
        return
      }

      editNoteApiRequest
        .send({
          endpoint: '/v1/agreements/' + agreementID.value + '/notes/' + editNoteState.value.id,
          method: 'PATCH',
          data: JSON.stringify({
            content: editNoteState.value.content
          })
        })
        .then(response => {
          if (!response.success) {
            alertToast('Failed to update note', response.data?.message, 'error')
            return
          }

          let noteToUpdate = timelineData.value.find(item => item.resourceId == editNoteState.value.id)
          noteToUpdate.text = editNoteState.value.content

          editNoteState.value.content = null
          editNoteInitState.value.content = null

          editNoteModalIsOpen.value = false

          alertToast('Note Updated', null, 'success')
        })
    }

    watch(
      () => editNoteState.value.content,
      () => {
        if (editNoteState.value.content != editNoteInitState.value.content) {
          editNoteHasUnsavedChanges.value = true
        } else {
          editNoteHasUnsavedChanges.value = false
        }
      },
      { deep: true }
    )

    watch(
      () => route.params.tab,
      (currentRoute, newRoute) => {
        if (currentRoute != newRoute) {
          if (route.params.tab === '') {
            currentTab.value = 'agreement'
            // timelineFetchData() //Update timeline when going back to this tabloadAgreement()
            //TODO remove once below done
            //TODO refactor this as it is not the best implementation of tab checking!
          } else {
            currentTab.value = route.params.tab
          }
        }
      },
      {
        immediate: true
      }
    )

    watch(
      () => currentTab.value,
      newTab => {
        currentTab.value = newTab
        //fetch tab route
        let tab = tabs.value.find(tab => tab.id === newTab)
        // console.log('Tab Change: ', tab)
        if (tab) {
          router.replace(tab.to)
        }
      }
    )

    watch(
      () => route.params.agid,
      (newAGID, oldAGID) => {
        //If agreement IDs are set, and are different
        if (oldAGID != undefined && newAGID != undefined && newAGID !== oldAGID) {
          // console.log('New: ' + newAGID, 'Old: ' + oldAGID)
          agreementID.value = newAGID

          pageErrors.value = []

          //Reset phone from and to
          emitter.emit('phone-clear-numbers')

          fetchAllData()

          // Replace 'currentRouteName' with the name of the route for your view
          // router.replace({ name: '/agreement', params: { agid: newAGID } })
          // router.replace('/home')
        }
        if (newAGID === undefined) {
          //Reset phone from and to
          emitter.emit('phone-clear-numbers')
        }
      },
      {
        immediate: true
      }
    )

    const isVehicleImageLoaded = ref(false)

    async function updateAgreementData(data) {
      console.log('sata', data)

      if (!data) {
        return
      }
      return new Promise((resolve, reject) => {
        updateAgreement
          .send({ endpoint: '/v1/agreements/' + agreementID.value, method: 'PATCH', data: data })
          .then(response => {
            resolve(response)
            console.log(response)
            if (response.success) {
              alertToast('Details updated', null, 'success')
            }
          })
          .catch(err => {
            reject(err)
            alertToast('Failed to update details', err.message, 'error')
          })
      })
    }

    function statusChanged(status) {
      if (status) {
        updateAgreementData({ status: status })
          .then(() => {
            agreementData.value.status = status
          })
          .catch(error => {
            // handle error
            console.log(error)
          })
      }
    }

    function handleReminderClick(task) {
      // console.log('Opening TaskHandler')
      randomKey.value = Math.random()
      taskHandlerData.value = task
      taskHandlerIsOpen.value = true
      // console.log(task)
    }

    function handleNewReminderClick() {
      randomKey.value = Math.random()
      taskHandlerData.value = {
        linkType: 'agreement',
        linkTitle: agreementData.value.customer.name,
        linkID: agreementID.value,
        isReminder: true
      }
      taskHandlerIsOpen.value = true
    }

    function newReminderSubmit(data) {
      // console.log('New Task to be added!', data)
      reminders.data.value.push(data)
      taskHandlerIsOpen.value = false
    }
    function updateReminderSubmit(data) {
      // Update task in dom with new data
      const taskIndex = reminders.data.value.findIndex(x => x.id === data.id)
      reminders.data.value[taskIndex] = data
      taskHandlerIsOpen.value = false
    }

    function onTaskListItemChange(task) {
      reminders.data.value.forEach((item, index) => {
        if (item.id === task.id) {
          reminders.data.value[index] = task
        }
      })
    }

    function handleNewAppointmentClick() {
      let futureAppointmentsCount = null
      if (appointmentsData.value) {
        futureAppointmentsCount = appointmentsData.value.filter(appt => dayjs().isBefore(dayjs(appt.time)) && !appt.isCancelled).length
      }

      if (futureAppointmentsCount) {
        Swal.fire({
          title: 'Are you sure?',
          text: 'The customer already has an upcoming appointment',
          icon: 'warning',
          reverseButtons: true,
          showCloseButton: true,
          showCancelButton: true,
          confirmButtonText: 'Continue',
          confirmButtonColor: 'var(--bg-brand-solid)',
          cancelButtonText: 'Cancel',
          cancelButtonColor: 'var(--bg-secondary)'
        }).then(function (response) {
          if (response.isConfirmed) {
            openAppointmentHandler()
          }
        })
      } else {
        openAppointmentHandler()
      }
    }

    function handleAppointmentInviteClick() {
      let futureAppointmentsCount = null
      if (appointmentsData.value) {
        futureAppointmentsCount = appointmentsData.value.filter(appt => dayjs().isBefore(dayjs(appt.time)) && !appt.isCancelled).length
      }

      if (futureAppointmentsCount) {
        Swal.fire({
          title: 'Are you sure?',
          text: 'The customer already has an upcoming appointment',
          icon: 'warning',
          reverseButtons: true,
          showCloseButton: true,
          showCancelButton: true,
          confirmButtonText: 'Continue',
          confirmButtonColor: 'var(--bg-brand-solid)',
          cancelButtonText: 'Cancel',
          cancelButtonColor: 'var(--bg-secondary)'
        }).then(function (response) {
          if (response.isConfirmed) {
            openInviteConfirmation()
          }
        })
      } else {
        openInviteConfirmation()
      }
    }

    function openInviteConfirmation() {
      Swal.fire({
        title: 'Send Appointment Invite',
        text:
          'The customer will receive an email to: ' +
          agreementData.value.customer.contact.email +
          ', with a link to book their own appointment',
        icon: 'question',
        reverseButtons: true,
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonText: 'Send Invite',
        confirmButtonColor: 'var(--bg-brand-solid)',
        cancelButtonText: 'Cancel',
        cancelButtonColor: 'var(--bg-secondary)',
        preConfirm: () => {
          Swal.showLoading() // Show the loading spinner
          return useApiRequest()
            .send({ endpoint: '/v1/agreements/' + agreementID.value + '/appointment-invite', method: 'POST' })
            .then(response => {
              if (!response.success) {
                Swal.showValidationMessage(`Failed to send invite: ${response.message || 'Unknown error'}`)
                throw new Error('Validation failed')
              }
              alertToast('Appointment Invite Sent', null, 'success')
              return true // Resolves the promise successfully
            })
            .catch(error => {
              console.error(error)
            })
        },
        allowOutsideClick: () => !Swal.isLoading() // Prevent closing while loading
      })
    }

    function openAppointmentHandler() {
      randomKey.value = Math.random()
      appointmentHandlerIsOpen.value = true
    }

    function onAppointmentUpdated(appointmentData) {
      let appointment = appointmentsData.value.find(appointment => appointment.id == appointmentData.id)
      let index = appointmentsData.value.indexOf(appointment)

      if (index) {
        appointmentsData.value.splice(index, 1)
      }
    }

    const tagsToDisplay = computed(() => {
      // Create an array to store the tag data that matches the IDs
      let matchedTags = []

      // Loop through each agreement
      agreementData.value.tagIDs.forEach(tagID => {
        // For each tag ID, find the corresponding tag data
        // but only if tagsData is not null
        if (allTags.data.value) {
          let tagData = allTags.data.value.find(tag => tag.id === tagID)

          // If a matching tag data is found, add it to the matchedTags array
          if (tagData) {
            matchedTags.push(tagData)
          }
        }
      })

      // Return the array of matched tags
      return matchedTags
    })

    const allTagsList = computed(() => {
      return allTags.data.value.filter(tag => tag.isActive === 1)
    })

    const tagApiHandler = useApiRequest()

    function removeTag(tagID) {
      // console.log('Removing Tag: ' + tagID)

      //Update agreement tags
      tagApiHandler
        .send({
          endpoint: '/v1/agreements/' + agreementID.value + '/tags',
          method: 'DELETE',
          data: JSON.stringify({ tagID: tagID })
        })
        .then(response => {
          // console.log(response)
          if (response.success) {
            //Remove from tags array
            const tagIndex = agreementData.value.tagIDs.findIndex(item => item === tagID)
            if (tagIndex > -1) {
              agreementData.value.tagIDs.splice(tagIndex, 1)
            }
          } else {
            alertToast('Failed to remove tag', response.data.response?.message, 'error')
          }
        })
    }

    function addTag(tagID) {
      //Update agreement tags
      tagApiHandler
        .send({ endpoint: '/v1/agreements/' + agreementID.value + '/tags', method: 'POST', data: JSON.stringify({ tagID: tagID }) })
        .then(response => {
          if (!response.success) {
            alertToast('Failed to add tag', response.data.response?.message, 'error')
          }
        })
    }

    function newAppointmentSubmitted() {
      //TODO: make this more efficient instead of fetching all the appointments again.
      appointmentsFetchData({ endpoint: '/v1/appointments?agreementId=' + agreementID.value })
      appointmentHandlerIsOpen.value = false
    }

    const proposalBuilderIsOpen = ref(false)

    let mergeAgreementData = ref({
      from: {
        agreementID: parseInt(agreementID.value)
      },
      to: null
    })
    let transferAgreementData = ref({
      from: {
        agreementID: parseInt(agreementID.value)
      },
      to: null,
      note: null
    })

    const transferRules = {
      from: {
        agreementID: { required }
      },
      to: { required },
      note: { required }
    }
    const transferV$ = useVuelidate(transferRules, transferAgreementData.value)

    const searchTerm = ref('')
    const meiliSearchResults = ref([])
    const meilisearchClient = ref()

    onMounted(() => {
      meilisearchClient.value = new MeiliSearch({
        host: import.meta.env.VITE_MEILISEARCH_URL,
        apiKey: import.meta.env.VITE_MEILISEARCH_API_KEY
      })
    })

    const agreementsList = ref([])
    const search = async query => {
      if (query) {
        meiliSearchResults.value = await meilisearchClient.value.index('agreements').search(query, {
          filter: 'dealershipId IN [' + dealershipIDs.value.join(',') + ']'
          // attributesToHighlight: ['*'],
          // highlightPreTag: '<span class="search-results-highlight">',
          // highlightPostTag: '</span>'
        })
        // console.log(searchResults.value)
        if (meiliSearchResults.value.hits != undefined) {
          agreementsList.value = meiliSearchResults.value.hits
        }
      } else {
        meiliSearchResults.value = []
      }
    }

    watch(searchTerm, newSearchTerm => {
      // console.log(newSearchTerm)
      //Carry out meilisearch
      search(newSearchTerm)
    })

    function submitMergeAgreement() {
      Swal.fire({
        title: 'Are you sure?',
        text: 'This cannot be reversed. Please triple check before merging.',
        icon: 'warning',
        reverseButtons: true,
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonText: 'Confirm Merge',
        confirmButtonColor: 'var(--bg-brand-solid)',
        cancelButtonText: 'Cancel',
        cancelButtonColor: 'var(--bg-secondary)'
      }).then(function (response) {
        if (response.isConfirmed) {
          let jsonData = JSON.stringify({
            to: mergeAgreementData.value.to
          })
          // console.log(jsonData)
          mergeAgreement
            .send({ endpoint: '/v1/agreements/' + agreementID.value + '/merge', method: 'PATCH', data: jsonData })
            .then(response => {
              if (!updateAgreement.hasError) {
                alertToast(
                  'Agreement Merged',
                  'Agreement #' + agreementID.value + ' has been merged into #' + mergeAgreementData.value.to,
                  'success'
                )
                router.replace('/agreement/' + mergeAgreementData.value.to)
              } else {
                alertToast('Failed to merge agreement #' + agreementID.value, response.data?.message, 'error')
              }
            })

          //close modal
          mergeModalIsOpen.value = false
        }
      })
    }

    async function submitTransferAgreement() {
      const isFormCorrect = await transferV$.value.$validate()
      if (!isFormCorrect) {
        return
      }
      Swal.fire({
        title: 'Are you sure?',
        text: 'Please triple check before transferring.',
        icon: 'warning',
        reverseButtons: true,
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonText: 'Confirm Transfer',
        confirmButtonColor: 'var(--bg-error-solid)',
        cancelButtonText: 'Cancel',
        cancelButtonColor: 'var(--bg-secondary)'
      }).then(function (response) {
        if (response.isConfirmed) {
          let dataToPost = {
            to: transferAgreementData.value.to,
            note: transferAgreementData.value.note
          }

          try {
            transferAgreement
              .send({ method: 'POST', endpoint: `/v1/agreements/${agreementData.value.id}/transfer`, data: JSON.stringify(dataToPost) })
              .then(response => {
                if (response.success) {
                  alertToast('Customer transferred', null, 'success')
                  updateDealershipData(transferAgreementData.value.to)
                } else {
                  alertToast('Failed to transfer customer', response.message, 'error')
                }
              })
          } catch (err) {
            alertToast('Failed to transfer customer', err.message, 'error')
          }

          //close modal
          transferModalIsOpen.value = false
        }
      })
    }
    const dealershipRequest = useApiRequest()
    function updateDealershipData(id) {
      try {
        dealershipRequest.send({ method: 'GET', endpoint: `/v1/dealerships/${id}`, params: { dataReturnLevel: 'all' } }).then(response => {
          if (response.success) {
            agreementData.value.dealership = response.data
          } else {
            alertToast('Failed to fetch dealership data', response.message, 'error')
          }
        })
      } catch (err) {
        alertToast('Failed to fetch dealership data', err.message, 'error')
      }
    }

    function submitDeleteAgreement() {
      Swal.fire({
        title: 'Are you sure?',
        text: 'This cannot be reversed. Please triple check before deleting.',
        icon: 'warning',
        reverseButtons: true,
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonText: 'Confirm Deletion',
        confirmButtonColor: 'var(--bg-error-solid)',
        cancelButtonText: 'Cancel',
        cancelButtonColor: 'var(--bg-secondary)'
      }).then(function (response) {
        if (response.isConfirmed) {
          deleteAgreement.send({ endpoint: '/v1/agreements/' + agreementID.value, method: 'DELETE' }).then(() => {
            if (!deleteAgreement.hasError) {
              alertToast('Deleted', 'Agreement #' + agreementID.value + ' was deleted', 'success')
              router.replace('/home')
            } else {
              alertToast('Failed to delete agreement #' + agreementID.value, response.data?.message, 'error')
            }
          })

          //close modal
          mergeModalIsOpen.value = false
        }
      })
    }

    function onNewValuationSubmit(data) {
      agreementData.value.odometer.current = data.latestMileage
    }

    return {
      userStore,
      pageErrors,
      route,
      customerTimeZone,
      customerID,
      agreementID,
      agreementIsLoading,
      agreementHasError,
      agreementData,
      phoneNumberValidations,
      tabs,
      currentTab,
      timelineData,
      agreementFullDetailsIsOpen,
      newNote,
      appointmentsData,
      appointmentsCounter,
      appointmentsIsLoading,
      appointmentsHasError,
      loadPhone,
      loggedInUserProfilePicUrl,
      newNoteV$,
      submitNewNote,
      editNote: onEditNoteClick,
      editNoteModalIsOpen,
      editNoteV$,
      editNoteState,
      submitEditNote,
      editNoteHasUnsavedChanges,
      conversationsIsLoading,
      conversationsHasError,
      conversationsData,
      newConversationTrigger,
      newConversationSubmitted,
      isVehicleImageLoaded,
      contactPreferencesIsOpen,
      submitContactPreferences,
      remindersIsOpen,
      computedReminders,
      randomKey,
      taskHandlerData,
      taskHandlerIsOpen,
      handleReminderClick,
      handleNewReminderClick,
      newReminderSubmit,
      updateReminderSubmit,
      onTaskListItemChange,
      remindersCounter,
      updateAgreementData,
      statusChanged,
      allTagsList,
      tagsToDisplay,
      addTag,
      removeTag,
      tagsModalIsOpen,
      mergeModalIsOpen,
      transferModalIsOpen,
      appointmentHandlerIsOpen,
      appointmentHandlerData,
      handleNewAppointmentClick,
      handleAppointmentInviteClick,
      newAppointmentSubmitted,
      onAppointmentUpdated,
      switchExplore,
      proposalBuilderIsOpen,
      mergeAgreementData,
      submitTransferAgreement,
      transferAgreementData,
      transferV$,
      dealershipsList,
      agreementsList,
      searchTerm,
      search,
      submitMergeAgreement,
      submitDeleteAgreement,
      routeCoordinates,
      useBreakPoints,
      onNewValuationSubmit,
      selectedCallCenterCommsConfig
    }
  }
}
</script>

<style>
.details-grid-box {
  border: 1px solid var(--border-secondary);
  border-radius: var(--rounded-md);
  padding: var(--s-3);
  text-align: center;
}

.dark .details-grid-box {
  border: 1px solid var(--darkmode-3);
}

.details-grid-box-title {
  font-size: var(--text-sm);
  color: var(--text-secondary);
}

.details-grid-box-data {
  font-weight: 500;
}
</style>
