<template>
  <PageHeader
    title="Invite Summary"
    breadcrumb
    :items="[{ label: $t('ui.entities.report.label', 2), to: '/reports' }, { label: 'Engagement' }, { label: 'Invite Summary' }]"
  >
    <template #right>
      <div class="flex items-center gap-2">
        <DatePicker
          v-model="dateRange"
          selection-mode="range"
          max-range="31"
          position="bottom-right"
          :max-date="new Date()"
          :number-of-months="useBreakPoints({ xs: 1, sm: 2 })"
          @change-applied="onDateRangeChange"
        />
      </div>
    </template>
  </PageHeader>

  <Alert
    severity="warning"
    title="Data Issue Notice"
    message="Due to a bug in the software, the data for Appointments Booked was not being collected prior to 16/01/2025. The column's data prior to this date will not be accurate."
    :show-close-button="false"
    :show-dismiss-button="false"
    class="mb-4"
  />

  <Card>
    <DataTable
      ref="dt"
      v-model:filters="filters"
      export-filename="Invite Summary"
      :value="inviteData"
      removable-sort
      sort-field="timestamp"
      :sort-order="-1"
      data-key="id"
      column-resize-mode="fit"
      :paginator="true"
      responsive-layout="scroll"
      filter-display="menu"
      :global-filter-fields="['dealershipId', 'dealershipName']"
      :rows="20"
      :rows-per-page-options="[10, 20, 50]"
      state-key="dt-report-invite-summary"
      state-storage="session"
      current-page-report-template="Showing {startRecord}-{endRecord} out of {totalRecords}"
      :loading="inviteApiRequest.isLoading.value"
    >
      <template #empty> No records found </template>

      <template #header>
        <div class="flex justify-between">
          <span class="flex justify-between gap-4">
            <span>
              <Input v-model="filters['global'].value" :placeholder="$t('ui.common.search', 1)" icon="search" />
            </span>
            <Button
              :label="$t('ui.actions.clear', 1)"
              severity="secondary"
              type="button"
              outlined
              icon="filter-slash"
              @click="clearFilters()"
            />
          </span>
        </div>
        <div class="flex justify-between mt-4">
          <span>
            <Button label="CSV" type="button" severity="secondary" outlined icon="file-excel" @click="exportCSV($event)" />
          </span>
        </div>
      </template>

      <Column field="dealershipName" header="Dealership Name" hidden />

      <Column field="dealershipId" header="Dealership" :sortable="true" :show-filter-match-modes="false" :show-filter-operator="false">
        <template #body="{ data }">
          {{ data.dealershipName }}
        </template>
        <template #filter="{ filterModel }">
          <MultiSelect
            v-model="filterModel.value"
            :options="dealershipsList"
            filter
            display="chip"
            option-value="value"
            option-label="label"
            placeholder="Any"
            class="max-w-[300px]"
          >
            <template #option="slotProps">
              {{ slotProps.option.label }}
            </template>
          </MultiSelect>
        </template>
      </Column>

      <Column field="sentCount" header="Sent" :sortable="true">
        <template #body="{ data }">
          {{ data.sentCount }}
        </template>
      </Column>
      <Column field="openedCount" header="Opened" :sortable="true">
        <template #body="{ data }">
          {{ data.openedCount }}
        </template>
      </Column>
      <Column field="clickedCount" header="Link Clicked" :sortable="true">
        <template #body="{ data }">
          {{ data.clickedCount }}
        </template>
      </Column>
      <Column field="appointmentsBookedCount" header="Appointments Booked" :sortable="true">
        <template #body="{ data }">
          {{ data.appointmentsBookedCount }}
        </template>
      </Column>
    </DataTable>
  </Card>
</template>

<script setup>
import { ref, computed } from 'vue'

import Card from '@/components/card/Card.vue'
import PageHeader from '@/components/page/PageHeader.vue'
import DatePicker from '@/components/date-picker/DatePicker.vue'
import Input from '@/components/forms/Input.vue'
import Button from '@/components/button/Button.vue'
import Alert from '@/components/alert/Alert.vue'

import useApiRequest from '@/composables/useApiRequest'
import { useBreakPoints } from '@/composables/useWindowSize'
import { useUserStore } from '@/stores/UserStore'
import { alertToast } from '@/utilities/notification'

import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import { FilterMatchMode } from 'primevue/api'
import MultiSelect from 'primevue/multiselect'
import dayjs from 'dayjs'

const dt = ref()
const userStore = useUserStore()
const dealershipsAllowed = ref(userStore.accessAllowed.dealerships)
const dateRange = ref([dayjs().startOf('month').toDate(), dayjs().toDate()])
const inviteApiRequest = useApiRequest()
const inviteData = ref([])
const filters = ref([])

const dealershipsList = computed(() => {
  return dealershipsAllowed.value.map(d => {
    return {
      value: d.id,
      label: d.name
    }
  })
})

function initFilters() {
  filters.value = {
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    dealershipId: { value: null, matchMode: FilterMatchMode.IN }
  }
}
initFilters()

function clearFilters() {
  initFilters()
}

function fetchInviteData() {
  try {
    inviteApiRequest
      .send({
        endpoint: 'v1/reports/engagement/invites',
        params: {
          start: dayjs(dateRange.value[0]).format('YYYY-MM-DD'),
          end: dayjs(dateRange.value[1]).format('YYYY-MM-DD'),
          dealerships: dealershipsAllowed.value.map(d => d.id).join(',')
        }
      })
      .then(response => {
        if (response.success) {
          inviteData.value = response.data
        } else {
          alertToast('Failed to fetch data', response.message, 'error')
        }
      })
  } catch (err) {
    alertToast('Failed to fetch data', err.message, 'error')
  }
}
fetchInviteData()

function onDateRangeChange() {
  if (dateRange.value[0] && dateRange.value[1]) {
    fetchInviteData()
  }
}

const exportCSV = () => {
  dt.value.exportCSV()
}
</script>

<style></style>
