<template>
  <PageHeader
    :title="$t('ui.entities.finance.billing-account', 2)"
    breadcrumb
    :items="[
      { label: $t('ui.entities.setting', 2), to: '/settings' },
      { label: 'Finance' },
      { label: $t('ui.entities.finance.billing-account', 2) }
    ]"
  >
    <template #right>
      <div class="flex gap-4">
        <Button label="New" severity="primary" icon="plus" @click="newBillingAccount" />
      </div>
    </template>
  </PageHeader>

  <Card>
    <DataTable
      ref="dt"
      v-model:filters="filters"
      export-filename="BillingAccounts"
      :value="billingAccounts"
      :loading="billingAccountsRequest.isLoading.value"
      removable-sort
      sort-field="id"
      :sort-order="-1"
      :rows="20"
      data-key="id"
      column-resize-mode="fit"
      :paginator="true"
      responsive-layout="scroll"
      filter-display="menu"
      :rows-per-page-options="[10, 20, 50]"
      state-key="dt-settings-billing-accounts"
      state-storage="session"
      current-page-report-template="Showing {startRecord}-{endRecord} out of {totalRecords}"
      :global-filter-fields="['id', 'name']"
    >
      <template #empty> No records found </template>
      <template #header>
        <div class="flex justify-between">
          <span class="flex justify-between gap-4">
            <span>
              <Input v-model="filters['global'].value" :placeholder="$t('ui.common.search', 1)" icon="search" />
            </span>
            <Button
              :label="$t('ui.actions.clear', 1)"
              severity="secondary"
              type="button"
              outlined
              icon="filter-slash"
              @click="clearFilters()"
            />
          </span>
        </div>
      </template>

      <Column field="id" header="ID" :sortable="true">
        <template #body="{ data }">
          <div>{{ data.id }}</div>
        </template>
      </Column>

      <Column field="name" header="Name" :sortable="true">
        <template #body="{ data }">
          <div>{{ data.name }}</div>
        </template>
      </Column>

      <Column field="xeroContactId" header="Xero Linked" :sortable="true">
        <template #body="{ data }">
          <Icon
            v-tippy="data.xeroContactId ? data.xeroContactId : 'Not linked'"
            :type="data.xeroContactId ? 'circle-check' : 'circle-x'"
            :severity="data.xeroContactId ? 'success' : 'error'"
          />
        </template>
      </Column>

      <Column field="isActive" header="Active" :sortable="true" :show-filter-match-modes="false" :show-filter-operator="false">
        <template #body="{ data }">
          <Chip v-if="data.isActive" :rounded="false" outlined size="sm" dot severity="success">Active</Chip>
          <Chip v-else :rounded="false" outlined size="sm" dot severity="danger">Disabled</Chip>
        </template>
        <template #filter="{ filterModel }">
          <MultiSelect
            v-model="filterModel.value"
            :options="[
              { value: true, label: 'Active' },
              { value: false, label: 'Disabled' }
            ]"
            filter
            display="chip"
            option-value="value"
            option-label="label"
            placeholder="All"
          >
            <template #option="slotProps">
              {{ slotProps.option.label }}
            </template>
          </MultiSelect>
        </template>
      </Column>

      <Column>
        <template #body="{ data }">
          <Button v-tippy="$t('ui.actions.edit')" icon="pencil" plain severity="secondary" @click="editBillingAccount(data)" />
        </template>
      </Column>
    </DataTable>
  </Card>

  <teleport to="#modals-container">
    <Modal
      :is-open="billingAccountModalIsOpen"
      :title="isNewBillingAccount ? 'New Billing Account' : 'Update Billing Account'"
      icon="money-check-pen"
      :buttons="['ok', 'close']"
      close-text="Cancel"
      :ok-text="isNewBillingAccount ? 'Create' : 'Save'"
      :has-unsaved-changes="billingAccountHasUnsavedChanges"
      :show-loader-on-confirm="true"
      :is-confirming="isNewBillingAccount ? newBillingAccountRequest.isLoading.value : updateBillingAccountRequest.isLoading.value"
      min-width="650"
      @close-modal="((billingAccountModalIsOpen = false), (state = null), (stateInit = null))"
      @ok-modal="submitBillingAccount"
    >
      <div class="grid grid-cols-2 gap-x-4">
        <FormGroup label="Xero Contact ID" :is-required="isNewBillingAccount ? v$.xeroContactId.required : ''">
          <Input v-model="state.xeroContactId" :has-error="v$.xeroContactId.$error" />
          <InputError :has-error="v$.xeroContactId.$error">{{ v$.xeroContactId.$errors[0]?.$message }}</InputError>
        </FormGroup>

        <FormGroup :label="$t('ui.common.name')" :is-required="isNewBillingAccount ? v$.name.required : ''">
          <Input v-model="state.name" :has-error="v$.name.$error" />
          <InputError :has-error="v$.name.$error">{{ v$.name.$errors[0]?.$message }}</InputError>
        </FormGroup>

        <FormGroup :label="$t('ui.entities.address.line-1')" :is-required="isNewBillingAccount ? v$.address.line1.required : ''">
          <Input v-model="state.address.line1" :has-error="v$.address.line1.$error" />
          <InputError :has-error="v$.address.line1.$error">{{ v$.address.line1.$errors[0]?.$message }}</InputError>
        </FormGroup>

        <FormGroup :label="$t('ui.entities.address.line-2')">
          <Input v-model="state.address.line2" :has-error="v$.address.line2.$error" />
          <InputError :has-error="v$.address.line2.$error">{{ v$.address.line2.$errors[0]?.$message }}</InputError>
        </FormGroup>

        <FormGroup label="Line 3">
          <Input v-model="state.address.line3" :has-error="v$.address.line3.$error" />
          <InputError :has-error="v$.address.line3.$error">{{ v$.address.line3.$errors[0]?.$message }}</InputError>
        </FormGroup>

        <FormGroup :label="$t('ui.entities.address.post-code')" :is-required="isNewBillingAccount ? v$.address.postcode.required : ''">
          <Input v-model="state.address.postcode" :has-error="v$.address.postcode.$error" />
          <InputError :has-error="v$.address.postcode.$error">{{ v$.address.postcode.$errors[0]?.$message }}</InputError>
        </FormGroup>

        <FormGroup label="Town" :is-required="isNewBillingAccount ? v$.address.town.required : ''">
          <Input v-model="state.address.town" :has-error="v$.address.town.$error" />
          <InputError :has-error="v$.address.town.$error">{{ v$.address.town.$errors[0]?.$message }}</InputError>
        </FormGroup>

        <FormGroup :label="$t('ui.entities.address.county')" :is-required="isNewBillingAccount ? v$.address.county.required : ''">
          <Input v-model="state.address.county" :has-error="v$.address.county.$error" />
          <InputError :has-error="v$.address.county.$error">{{ v$.address.county.$errors[0]?.$message }}</InputError>
        </FormGroup>

        <FormGroup :label="$t('ui.entities.address.country')" :is-required="isNewBillingAccount ? v$.address.country.required : ''">
          <Select
            v-model="state.address.country"
            :options="countryList"
            value-key="label"
            label-key="label"
            :has-error="v$.address.country.$error"
          >
            <template #option="{ option }">
              <div class="flex gap-2">
                <Image :src="option.image" class="w-5" />
                <div>{{ option.label }}</div>
              </div>
            </template>
          </Select>
          <InputError :has-error="v$.address.country.$error">{{ v$.address.country.$errors[0]?.$message }}</InputError>
        </FormGroup>
      </div>
    </Modal>
  </teleport>
</template>

<script setup>
import { ref, watch } from 'vue'

import PageHeader from '@/components/page/PageHeader.vue'
import Card from '@/components/card/Card.vue'
import Button from '@/components/button/Button.vue'
import Input from '@/components/forms/Input.vue'
import InputError from '@/components/forms/InputError.vue'
import Icon from '@/components/icon/Icon.vue'
import Chip from '@/components/chip/Chip.vue'
import Modal from '@/components/modal/Modal.vue'
import FormGroup from '@/components/forms/FormGroup.vue'
import Select from '@/components/forms/Select.vue'
import Image from '@/components/image/Image.vue'

import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import MultiSelect from 'primevue/multiselect'

import useApiRequest from '@/composables/useApiRequest'
import { alertToast } from '@/utilities/notification'

import { FilterMatchMode } from 'primevue/api'
import _toUpper from 'lodash/toUpper'
import { useI18n } from 'vue-i18n'
import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'

const billingAccounts = ref(null)
const billingAccountsRequest = useApiRequest()
const newBillingAccountRequest = useApiRequest()
const updateBillingAccountRequest = useApiRequest()
const billingAccountModalIsOpen = ref(false)
const isNewBillingAccount = ref(null)
const billingAccountHasUnsavedChanges = ref(false)

const state = ref(null)
const stateInit = ref(null)
const stateDefault = {
  name: null,
  address: {
    country: null,
    county: null,
    line1: null,
    line2: null,
    line3: null,
    postcode: null,
    town: null
  },
  xeroContactId: null
}

const vuelidateRules = {
  name: { required },
  address: {
    country: { required },
    county: { required },
    line1: { required },
    line2: {},
    line3: {},
    postcode: { required },
    town: { required }
  },
  xeroContactId: { required }
}

const v$ = useVuelidate(vuelidateRules, state)

const filters = ref([])

function initFilters() {
  filters.value = {
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    isActive: { value: null, matchMode: FilterMatchMode.IN }
  }
}
initFilters()

function clearFilters() {
  //remove from session storage
  sessionStorage.removeItem('dt-settings-billing-accounts')
  initFilters()
}

try {
  billingAccountsRequest.send({ method: 'GET', endpoint: '/v1/finance/billing-accounts' }).then(response => {
    if (response.success) {
      billingAccounts.value = response.data
    } else {
      alertToast('Failed to fetch billing accounts', response.message, 'error')
    }
  })
} catch (err) {
  alertToast('Failed to fetch billing accounts', err.message, 'error')
}

function newBillingAccount() {
  isNewBillingAccount.value = true
  billingAccountModalIsOpen.value = true
  stateInit.value = JSON.parse(JSON.stringify(stateDefault))
  state.value = JSON.parse(JSON.stringify(stateDefault))
}

function editBillingAccount(account) {
  isNewBillingAccount.value = false
  billingAccountModalIsOpen.value = true
  stateInit.value = {
    id: account.id,
    name: account.name,
    address: {
      country: account.address.country,
      county: account.address.county,
      line1: account.address.line1,
      line2: account.address.line2,
      line3: account.address.line3,
      postcode: account.address.postcode,
      town: account.address.town
    },
    xeroContactId: account.xeroContactId
  }
  state.value = JSON.parse(JSON.stringify(stateInit.value))
}

async function submitBillingAccount() {
  const isFormCorrect = await v$.value.$validate()
  if (!isFormCorrect) {
    return
  }

  let dataToPost = JSON.stringify(state.value)

  if (isNewBillingAccount.value) {
    try {
      newBillingAccountRequest.send({ method: 'POST', endpoint: '/v1/finance/billing-accounts', data: dataToPost }).then(response => {
        if (response.success) {
          alertToast('Billing account added', response.message, 'success')
          // Close the modal
          billingAccountModalIsOpen.value = false
        } else {
          alertToast('Failed to add billing account', response.message, 'error')
        }
      })
    } catch (err) {
      alertToast('Failed to add billing account', err.message, 'error')
    }
  } else {
    try {
      updateBillingAccountRequest
        .send({ method: 'PATCH', endpoint: '/v1/finance/billing-accounts/' + state.value.id, data: dataToPost })
        .then(response => {
          if (response.success) {
            alertToast('Billing account updated', response.message, 'success')
            // Close the modal
            billingAccountModalIsOpen.value = false
          } else {
            alertToast('Failed to update billing account', response.message, 'error')
          }
        })
    } catch (err) {
      alertToast('Failed to update billing account', err.message, 'error')
    }
  }
}

const countryList = [
  {
    label: useI18n().t('meta.countries.gb.label'),
    value: 'gb',
    emoji: '🇬🇧',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GB.svg'
  },
  {
    label: useI18n().t('meta.countries.us.label'),
    value: 'us',
    emoji: '🇺🇸',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/US.svg'
  },
  {
    label: useI18n().t('meta.countries.es.label'),
    value: 'es',
    emoji: '🇪🇸',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ES.svg'
  },
  {
    label: useI18n().t('meta.countries.mx.label'),
    value: 'mx',
    emoji: '🇲🇽',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MX.svg'
  },
  {
    label: useI18n().t('meta.countries.fr.label'),
    value: 'fr',
    emoji: '🇫🇷',
    image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/FR.svg'
  }
]

watch(
  state,
  () => {
    if (JSON.stringify(state.value) === JSON.stringify(stateInit.value)) {
      billingAccountHasUnsavedChanges.value = false
    } else {
      billingAccountHasUnsavedChanges.value = true
    }
  },
  { deep: true }
)
</script>

<style></style>
