<template>
  <div
    v-if="showIcon"
    v-clipboard:copy="toCopy"
    v-clipboard:success="onCopy"
    v-tippy="{ content: copied ? 'Copied!' : 'Copy to clipboard', trigger: 'click' }"
  >
    <slot></slot>
    <Icon type="copy" class="text-light dark:text-secondary ml-2 copy-icon" />
  </div>
  <div
    v-else
    v-clipboard:copy="toCopy"
    v-clipboard:success="onCopy"
    v-tippy="{ content: copied ? 'Copied!' : 'Copy to clipboard', trigger: 'mouseenter click' }"
    class="copy-text"
  >
    <slot></slot>
  </div>
</template>

<script>
import Icon from '@/components/icon/Icon.vue'
import { ref, computed } from 'vue'

export default {
  components: {
    Icon
  },
  props: {
    showIcon: {
      type: Boolean,
      default: false
    },
    explicitTextToCopy: {
      type: String,
      default: null
    }
  },
  setup(props, { slots }) {
    const copied = ref(false)

    const toCopy = computed(() => {
      if (props.explicitTextToCopy) {
        return props.explicitTextToCopy
      }
      const slot = slots.default?.()[0]
      return slot && slot.children ? slot.children : 'Nothing To Paste'
    })

    function onCopy() {
      copied.value = true
      setTimeout(() => {
        copied.value = false
      }, 2000)
    }

    return { ...props, toCopy, onCopy, copied }
  }
}
</script>

<style>
.copy-icon {
  font-size: 1rem;
}
.copy-icon:hover,
.copy-text:hover {
  color: var(--fg-brand);
  cursor: pointer;
}
</style>
