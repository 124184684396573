/**
 * Formats a timezone (e.g. "America/New_York") into a nice-looking string (e.g. "America - New York").
 * Underscores are replaced by spaces, and each word is capitalized.
 */
function formatTimezone(timezone) {
  if (!timezone) return ''

  return timezone
    .split('/')
    .map(part =>
      part
        .split('_')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
        .join(' ')
    )
    .join(' - ')
}

export { formatTimezone }

// Example usage:
// console.log(formatTimezone('America/New_York')) // "America - New York"
// console.log(formatTimezone('Europe/London')) // "Europe - London"
// console.log(formatTimezone('Asia/Tokyo')) // "Asia - Tokyo"
